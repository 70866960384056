/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.applications.history

export default async (filterData, paginationData) => {
  try {
    const params = {
      size: paginationData.size
    }

    params.page = paginationData.currentPage === 0 ? paginationData.currentPage : paginationData.currentPage - 1

    const { data: { content, totalElements } } = await axios({
      method: 'POST',
      url,
      data: filterData,
      params
    })

    return { content, totalElements }
  } catch (error) {
    throw error
  }
}
