/* eslint-disable no-useless-catch */
import fetchCitizen from '@/api/apiCalls/citizen/fetchCitizen'
import saveCitizen from '@/api/apiCalls/citizen/saveCitizen'
import checkAndChangeRouting from '@/api/apiCalls/application/checkAndChangeRouting'
import checkReciever from '@/api/apiCalls/carantines/checkCitizenInCarantine'
// import applicationStatusesConstants from '@/config/applicationStatuses/applicationStatusesConstants.js'
import Inputmask from 'inputmask'
import httpErrors from '@/api/config/httpErrors'

const state = {
  currentReciever: {},
  // TODO: заменить на объект
  defaultReciever: '' // String
}

const mutations = {
  setReciever: (state, value) => {
    state.currentReciever = value
  },
  setDefaultReciever: (state, value) => {
    state.defaultReciever = value
  },
  setRecieverCarantine: (state, { isCarantin, carantinStatusId }) => {
    state.currentReciever.isCarantin = isCarantin
    state.currentReciever.carantinStatusId = carantinStatusId
  }
}

const actions = {
  async fetchReciever ({ commit, dispatch }, citizenRecieverId) {
    const data = await fetchCitizen(citizenRecieverId)
    commit('setReciever', data)
    dispatch('saveDefaultReciever')
  },

  // Вызов рест сервиса на сохранение получателя
  async saveRecieverApiCall ({ commit, dispatch }) {
    const unmaskedPhone = await dispatch('getUnmaskedValue', { value: state.currentReciever.phone, options: { alias: '9', repeat: 11 } })
    const currentReciever = Object.assign({}, state.currentReciever,
      {
        phone: unmaskedPhone,
        name: state.currentReciever.name.trim(),
        surname: state.currentReciever.surname.trim(),
        patronymic: state.currentReciever.patronymic.trim()
      }
    )
    try {
      const { data } = await saveCitizen(currentReciever)
      commit('setReciever', data)
    } catch (error) {
      throw error
    }
  },

  async checkAndChangeRouting ({ dispatch }, { applicationId, applicationStatusId }) {
    // Добавили возможность проверять маршрутизацию в статусе "Черновик"
    // if (applicationStatusId === applicationStatusesConstants.DRAFT) {
    //   dispatch('saveDefaultRecieverAction')
    //   return
    // }

    const {
      areaId,
      districtId,
      isMoscowRegion,
      residenceAreaId,
      residenceDistrictId,
      isCarantin,
      carantinStatusId
    } = JSON.parse(state.defaultReciever)

    try {
      const { result } = await checkAndChangeRouting({
        applicationId,
        areaId,
        districtId,
        isMoscowRegion,
        residenceAreaId,
        residenceDistrictId,
        isCarantin,
        carantinStatusId
      })
      return result
    } catch (error) {
      const status = error.response ? error.response.status : null
      if (status === httpErrors.notAllowed.status) return
      const message = error.response
        ? error.response.data.message
        : 'При передаче заявки по машруту другому исполнителю возникла ошибка'
      return message
    }
  },

  async checkReciever ({ commit }, { hasRecieverEssentialDataChange }) {
    const formattedDate = state.currentReciever.birthday.split('.')
    const deafultReciever = JSON.parse(state.defaultReciever)

    const reciever = {
      birthdate: `${formattedDate[2]}-${formattedDate[1]}-${formattedDate[0]}`,
      name: state.currentReciever.name,
      patronymic: state.currentReciever.patronymic,
      surname: state.currentReciever.surname,
      oldIsCarantin: hasRecieverEssentialDataChange ? null : deafultReciever.isCarantin || null,
      oldStatusId: hasRecieverEssentialDataChange ? null : deafultReciever.carantinStatusId || null
    }

    try {
      const data = await checkReciever(reciever)
      commit('setRecieverCarantine', data)
      return data
    } catch (error) {
      throw error
    }
  },

  clearRecieverState ({ commit }) {
    commit('setReciever', {})
    commit('setDefaultReciever', '')
  },

  saveDefaultReciever ({ commit }) {
    commit('setDefaultReciever', JSON.stringify(state.currentReciever))
  },

  loadDefaultReciever ({ commit }) {
    commit('setReciever', JSON.parse(state.defaultReciever))
  },

  getUnmaskedValue ({ state }, { value, options }) {
    function checkIsValueMasked (value) {
      const symbols = ['(', '-', ')']
      const splittedValue = value.toString().split('')
      return symbols.map(symbol => {
        return splittedValue.includes(symbol)
      }).includes(true)
    }

    const isValueMasked = checkIsValueMasked(value)

    return isValueMasked ? Inputmask.unmask(value, options) : value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
