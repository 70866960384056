<template lang="pug">
  div.reciever-check-block
    div.flex(v-if="isRecieverCheckVisible" style="margin-right: 20px;")
      el-button(
        id="reciever-check-button"
        type='primary' @click="checkReciever"
        :loading="isRecieverCheckLoading"
      ) Проверить

      div.alerts-item(id="carantin-alert-confirmed" v-show="reciever.isCarantin")
        el-alert(
          v-show="reciever.isCarantin"
          title="Найден в реестре"
          :closable="false"
          :type="reciever.carantinStatusId === carantinStatusesConstants.inCarantin ? 'error' : 'info'"
        )

      div.alerts-item(id="carantin-alert-not-confirmed" v-show="!reciever.isCarantin && reciever.isCarantin !== null")
        el-alert(
          title="Не найден в реестре"
          :closable="false"
          type="success"
        )

      div.alerts-item(id="carantin-alert-not-checked" v-show="reciever.isCarantin === null")
        el-alert(
          title="Ожидает проверки"
          type="warning"
          :closable="false"
          show-icon
        )

    div.carantin-alerts
      div.reciever-not-contagious(
        v-show="reciever.carantinStatusId === carantinStatusesConstants.notContagious || reciever.carantinStatusId === carantinStatusesConstants.recovered"
        id="application-carantin-confirmed"
      )
        el-popover(
          placement="top-start"
          trigger="hover"
          :content="reciever.carantinStatusId === carantinStatusesConstants.notContagious ? 'Получатель не заразный' : 'Получатель выздоровел'"
        )
          font-awesome-icon.user-icon(icon="walking" slot="reference")

      div.reciever-carantin(
        id="application-carantin-confirmed"
        v-show="reciever.carantinStatusId === carantinStatusesConstants.inCarantin"
      )
        el-popover(
          placement="top-start"
          trigger="hover"
          content="Получатель на карантине"
        )
          font-awesome-icon.user-icon(icon="procedures" slot="reference")

      div.reciever-passed-away(
        id="application-carantin-confirmed"
        v-show="reciever.carantinStatusId === carantinStatusesConstants.passedAway"
      )
        el-popover(
          placement="top-start"
          trigger="hover"
          content="Получатель умер"
        )
          font-awesome-icon.user-icon(icon="heart-broken" slot="reference")

      div.application-carantin(
        id="application-carantin-confirmed"
        v-show="application.isCarantin"
      )
        el-popover(
          placement="top-start"
          trigger="hover"
          content="Заявка карантинная"
        )
          font-awesome-icon.user-icon(icon="virus" slot="reference")

      div.application-not-carantin(
        id="application-carantin-not-confirmed"
        v-show="!application.isCarantin"
      )
        el-popover(
          placement="top-start"
          trigger="hover"
          content="Заявка не карантинная"
        )
          font-awesome-icon.user-icon(icon="virus-slash" slot="reference")

</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import carantinStatusesConstants from '@/config/carantinStatuses/carantinStatusesConstants'
import applicationStatusesConstants from '@/config/applicationStatuses/applicationStatusesConstants'

export default {
  name: 'RecieverCheck',
  props: {
    application: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isRecieverCheckLoading: false
    }
  },
  computed: {
    ...mapState('reciever', {
      reciever: state => state.currentReciever,
      defaultReciever: state => JSON.parse(state.defaultReciever)
    }),

    ...mapState('auth', {
      userAbility: state => state.userAbility
    }),

    carantinStatusesConstants () {
      return carantinStatusesConstants
    },

    isRecieverCheckVisible () {
      return this.application.applicationStatusId !== applicationStatusesConstants.REJECTED &&
        this.application.applicationStatusId !== applicationStatusesConstants.DONE
    }
  },
  methods: {

    ...mapMutations('reciever', {
      setRecieverCarantine: 'setRecieverCarantine'
    }),

    ...mapActions('reciever', {
      fetchRecieverAction: 'fetchReciever',
      checkRecieverAction: 'checkReciever'
    }),

    checkRecieverEssentialDataChange () {
      return this.reciever.surname !== this.defaultReciever.surname ||
        this.reciever.name !== this.defaultReciever.name ||
        this.reciever.patronymic !== this.defaultReciever.patronymic ||
        this.reciever.birthday !== this.defaultReciever.birthday
    },

    async checkReciever () {
      const hasRecieverEssentialDataChange = this.checkRecieverEssentialDataChange()
      if (hasRecieverEssentialDataChange) {
        this.showWarningMessage('Сохраните получателя!')
        return
      }

      try {
        this.setRecieverCheckLoadingState(true)
        const { isCarantin } = await this.checkRecieverAction({ hasRecieverEssentialDataChange })
        const messageText = isCarantin ? 'Получатель в реестр НАЙДЕН' : 'Получатель в реестре НЕ найден'
        isCarantin ? this.showWarningMessage(messageText) : this.showSuccessMessage(messageText)
        this.$emit('saveReciever')
      } catch {
        const messageText = 'При проверке возникла ошибка'
        this.showErrorMessage(messageText)
      } finally {
        setTimeout(() => {
          this.setRecieverCheckLoadingState(false)
        }, 300)
      }
    },

    setRecieverCheckLoadingState (value) {
      this.isRecieverCheckLoading = value
    },

    showSuccessMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'success'
      })
    },

    showErrorMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'error'
      })
    },

    showWarningMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'warning'
      })
    }
  }
}
</script>
<style lang="sass">
  .reciever-check-block
    display: flex;
    align-items: center;
    button
      margin-right: 10px;
    .el-alert
      width: auto;
      height: 40px;

  .carantin-alerts
    display: flex;
    > div
      margin-right: 10px;
      font-size: 26px;
      &.reciever-not-checked
        display: flex;
        align-items: center;
        font-size: 12px;

    .application-carantin
      color: #F56C6C;

    .application-not-carantin
      color: #67C23A;

    .reciever-not-contagious
      color: #409EFF;

    .reciever-carantin
      color: #F56C6C;

    .reciever-passed-away
      color: #303133;

</style>
