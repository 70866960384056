import fetchSystemParams from '@/api/apiCalls/systemParams/fetchSystemParams'

const state = {
  systemParams: {}
}

const mutations = {
  setSystemParams: (state, value) => {
    state.systemParams = value
  }
}

const actions = {
  async fetchSystemParams ({ commit }) {
    const { data: { content } } = await fetchSystemParams()

    const systemParams = {}

    content.forEach((param) => {
      systemParams[param.systemParamId] = param
    })

    commit('setSystemParams', systemParams)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
