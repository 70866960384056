<template lang='pug'>
el-row.login-form-container(type="flex", justify="center", align="middle")
  el-col.flex.justify-center
    el-card.login-form-card(shadow="never")
      .title(style="margin-bottom: 15px") Вход в систему
      el-form#login-form(
        ,
        size="small",
        ref="loginForm",
        :hide-required-asterisk="true",
        :model="formData",
        :rules="rules"
      )
        el-form-item(label="Логин", prop="login")
          el-input#login-input(
            ,
            v-model="formData.login",
            placeholder="Введите логин"
          )
        el-form-item(label="Пароль", prop="password")
          el-input#password-input(
            ,
            v-model="formData.password",
            placeholder="Введите пароль",
            show-password
          )
        .error-message(v-if="authError.message.length") {{ authError.message }}

        .form-footer
          el-button#login-button(
            ,
            type="primary",
            size="medium",
            :disabled="!isLoginButtonActive",
            :loading="isLoginCallPending",
            @click="submitHandler"
          ) Войти
</template>

<script>
import { mapActions } from 'vuex'
import httpErrors from '@/api/config/httpErrors'

export default {
  name: 'Login',
  data () {
    return {
      mode: 'sign-in',

      supportEmail: 'info.dczn@gmail.com',

      isFormValid: true,
      isLoginCallPending: false,

      authError: {
        message: '',
        status: ''
      },

      formData: {
        login: '',
        password: ''
      },

      rules: {
        login: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле "Логин"',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Логин не может быть менее 3 символов',
            trigger: 'blur'
          },
          { validator: this.cirillicValidate, trigger: ['blur', 'change'] }
        ],
        password: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле "Пароль"',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Пароль не может быть менее 3 символов',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    isLoginButtonActive () {
      const isFormFieldsEmpty = () => {
        return Object.values(this.formData).includes('')
      }

      const isFormFieldsValid = () => {
        return this.isFormValid
      }

      return !(isFormFieldsEmpty() && isFormFieldsValid())
    }
  },
  created () {
    if (this.$route.query.file) {
      this.$message.warning({
        message: 'Для скачивания файла необходимо авторизоваться.',
        duration: 5000
      })
    }
  },
  methods: {
    ...mapActions('auth', {
      authLogin: 'authLogin'
    }),
    cirillicValidate (rule, value, callback) {
      const cirillic = /[А-ЯЁа-яё]+/i.test(value)
      const spaces = /\s/.test(value)

      if (value.length && cirillic) { callback(new Error('Логин не может содержать символы кириллицы')) } else if (value.length && spaces) { callback(new Error('Логин не может содержать пробелы')) } else callback()
    },
    checkIsFormValid () {
      this.$refs.loginForm.validate((valid) => {
        this.isFormValid = valid
      })
      return this.isFormValid
    },
    submitHandler () {
      const login = async () => {
        this.removeErrorMessage()
        const { login, password } = this.formData
        try {
          this.isLoginCallPending = true
          const isLoginSuccessfull = await this.authLogin({ login, password })
          if (isLoginSuccessfull) {
            this.handleAfterLoginRedirect()
          }
        } catch (error) {
          this.setErrorMessage(error)
        } finally {
          setTimeout(() => {
            this.isLoginCallPending = false
          }, 300)
        }
      }

      const isFormValid = this.checkIsFormValid()

      if (isFormValid) login()
    },
    handleAfterLoginRedirect () {
      const link = this.$route.query.file

      let route = { name: 'Registry' }

      if (link) {
        route = { path: `/files/${link}` }
      }

      this.$router.replace(route)
    },
    setErrorMessage (error) {
      const status = error.response ? error.response.status : ''
      const errorMessage =
        status === httpErrors.unauthorized.status
          ? 'Пользователь не найден. Пожалуйста, проверьте правильность логина и пароля'
          : `При авторизации возникла ошибка ${status}`
      this.authError.status = status
      this.authError.message = errorMessage
    },
    removeErrorMessage () {
      this.authError.status = ''
      this.authError.message = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.login-form-container
  height: 100vh

.login-form-card
  width: 320px
  min-width: 320px

.form-footer
  display: flex
  justify-content: flex-end
  align-items: center

.error-message
  font-size: 14px
  text-align: center
  margin-bottom: 12px
  margin-top: 25px
  color: #F56C6C

.support-info
  text-align: center
  margin-top: 30px
  font-size: 14px
  span
    font-size: 13px
  a
    text-decoration: none
</style>
