import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.applications.createWithServices

const createApplication = async (appData, params) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await axios({
      method: 'POST',
      url,
      data: appData,
      params
    })

    return data
  } catch (error) {
    throw error
  }
}

export default createApplication
