/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.applications.list

const fetchReqistryData = async ({ filterOptions, paginationOptions }) => {
  const params = {}
  const rsqlOptions = []

  const extraSearchOptions = [
    'receiver_fio',
    'receiver_phone'
  ]

  const extraEntryOptions = [
    'idAsText'
  ]

  Object.keys(filterOptions).forEach((key) => {
    if (filterOptions[key] && filterOptions[key].toString().length) {
      if (extraSearchOptions.includes(key)) {
        params[key] = filterOptions[key]
      } else if (extraEntryOptions.includes(key)) {
        rsqlOptions.push(`${key}==*${filterOptions[key]}*`)
      } else {
        rsqlOptions.push(`${key}==${filterOptions[key]}`)
      }
    }
  })

  if (rsqlOptions.length) params.rsql = rsqlOptions.join(';')

  params.size = paginationOptions.size
  params.page = paginationOptions.page === 0 ? paginationOptions.page : paginationOptions.page - 1

  try {
    const { data: { content, totalElements } } = await axios({
      method: 'GET',
      url,
      params
    })

    return { content, totalElements }
  } catch (error) {
    throw error
  }
}

export default fetchReqistryData
