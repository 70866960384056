<template lang="pug">
  .registry-page-container
    el-row
      el-breadcrumb.registry-page-breadcrumbs(separator-class="el-icon-arrow-right")
        el-breadcrumb-item Контакт центр "Социальная защита"
        el-breadcrumb-item Список заявок

    div.registry-upload
      registry-upload(v-if="userAbility.can('upload', 'carantinRegistryFile')")

    el-row.flex.align-center.mb-10(style="flex-wrap: wrap;")
      el-col(:span="14")
        div.filter
          registry-table-filter(
            :is="windowWidth >= 1400 ? 'RegistryTableFilter' : false"
            :filterOptions="filterOptions"
            @idAsTextFilterChange="computedIdAsTextFilter = $event"
            @recieverFioFilterChange="computedRecieverFioFilter = $event"
            @recieverPhoneFilterChange="computedRecieverPhoneFilter = $event"
            @recieverIsDeadFilterChange="computedRecieverIsDeadFilter= $event"
            @applicationStatusIdFilterChange="computedApplicationStatusIdFilter = $event"
            @recieverBirthdayFilterChange ="computedRecieverBirthdayFilter = $event"
            @applicationTypeIdFilterChange ="computedApplicationTypeIdFilter = $event"
          )
      el-col(:span="6")(style="margin-left: auto;")
        div.buttons
          el-button.custom-button(
            id="request-create-button"
            v-if="userAbility.can('create', 'application')"
            type="primary"
            title="Создать новую заявку"
            @click="createRequestDialogIsVisible = true"
          )
            font-awesome-icon.icon(icon="file-alt")
            span Создать заявку
          keep-alive
            registry-excel(
              v-if="userAbility.can('download', 'excel')"
              @showCsvQueueNotification="$emit('showCsvQueueNotification', $event)"
              @fetchQueuedCsvFileLinkHandler="$emit('fetchQueuedCsvFileLinkHandler')"
              @downloadCsvFileHandler="$emit('downloadCsvFileHandler', $event)"
              @closeCsvQueueConnection="$emit('closeCsvQueueConnection')"
            )

    el-row
      div.filter
        registry-table-filter(
          :is="windowWidth < 1400 ? 'RegistryTableFilter' : false"
          :filterOptions="filterOptions"
          @idAsTextFilterChange="computedIdAsTextFilter = $event"
          @recieverFioFilterChange="computedRecieverFioFilter = $event"
          @recieverPhoneFilterChange="computedRecieverPhoneFilter = $event"
          @recieverIsDeadFilterChange="computedRecieverIsDeadFilter= $event"
          @applicationStatusIdFilterChange="computedApplicationStatusIdFilter = $event"
          @recieverBirthdayFilterChange ="computedRecieverBirthdayFilter = $event"
          @applicationTypeIdFilterChange ="computedApplicationTypeIdFilter = $event"
        )

    registry-table(
      :isLoading="registryTable.isLoading"
      :data="computedRegistryData"
      :total="registryTable.total"
      :headers="registryTableHeaders"
      :paginationOptions="paginationOptions"
      @pageChange="computedPage = $event"
      @sizeChange="computedSize = $event"
      @openSelectedRequest="openSelectedRequest"
    )

    create-request-dialog(
      :is-visible.sync='createRequestDialogIsVisible'
      @requestWasCreated='fetchRegistryData'
      @close='closeCreateRequestDialog'
    )
</template>
<script>
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
import RegistryTable from '@/components/Registry/RegistryTable.vue'
import RegistryTableFilter from '@/components/Registry/RegistryTableFilter.vue'
import RegistryExcel from '@/components/Registry/RegistryExcel.vue'
import RegistryUpload from '@/components/Registry/RegistryUpload.vue'
import CreateRequestDialog from '@/components/CreateRequestDialog.vue'
import { applicationModelTranslate } from '@/api/model/applicationModel'
import fetchRegistryData from '@/api/apiCalls/registry/fetchRegistryData'
import storageConfig from '@/config/storageConfig'
import applicationTypesConstants from '@/config/applicationTypes/applicationTypesConstants'
import httpErrors from '@/api/config/httpErrors'

export default {
  name: 'RegistryPage',
  components: {
    RegistryTable,
    RegistryTableFilter,
    CreateRequestDialog,
    RegistryExcel,
    RegistryUpload
  },
  data () {
    return {
      registryTable: {
        data: [],
        total: 0,
        isLoading: true
      },
      paginationOptions: JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}registry_pagination_options`)) || {
        size: 10,
        sort: 'id',
        page: 0
      },
      filterOptions: JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}registry-filter-options`)) || {
        idAsText: '',
        applicationStatusId: null,
        applicationTypeId: null,
        receiver_fio: '',
        receiver_phone: '',
        citizenRecieverBirthday: ''
      },
      createRequestDialogIsVisible: false,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapState('references', {
      applicationTypesOptions: state => state.applicationTypes
    }),

    ...mapState('auth', {
      userAbility: state => state.userAbility
    }),

    computedRegistryData () {
      // return this.registryTable.data
      return this.registryTable.data.map((record) => {
        const { citizenRecieverResidenceAreaName } = record
        const { applicationTypeId } = record
        const { citizenRecieverIsMoscowRegion } = record

        if (
          !citizenRecieverIsMoscowRegion &&
          (applicationTypeId === applicationTypesConstants.OSO ||
          applicationTypeId === applicationTypesConstants.L)
        ) {
          return Object.assign(record, { citizenRecieverAreaName: citizenRecieverResidenceAreaName })
        } else {
          return record
        }
      })
    },

    idColWidth () {
      return this.registryTable.data && this.registryTable.data[0] && this.registryTable.data[0].id.toString().length * 14
    },

    registryTableHeaders () {
      const initialHeaders = Object.assign({}, applicationModelTranslate.translate)

      const headers = Object.keys(initialHeaders).reduce((prev, key) => {
        prev[key] = {}
        prev[key].label = initialHeaders[key]
        let width = 'auto'
        if (key === 'id') width = this.idColWidth > 60 ? this.idColWidth : 60
        if (key === 'createdAt') width = 170
        if (key === 'applicationStatusName') width = 170
        if (key === 'applicationTypePrefix') width = 140
        if (key === 'citizenRecieverBirthday') width = 200
        if (key === 'citizenRecieverPhone') width = 200

        prev[key].width = width
        return prev
      }, {})

      return headers
    },

    computedPage: {
      get () {
        return this.paginationOptions.page
      },
      set (value) {
        this.paginationOptions.page = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry_pagination_options`, JSON.stringify(this.paginationOptions))
        this.fetchRegistryData()
      }
    },

    computedSize: {
      get () {
        return this.paginationOptions.size
      },
      set (value) {
        this.paginationOptions.size = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry_pagination_options`, JSON.stringify(this.paginationOptions))
        this.fetchRegistryData()
      }
    },

    computedIdAsTextFilter: {
      get () {
        return this.filterOptions.idAsText
      },
      set (value) {
        this.filterOptions.idAsText = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry-filter-options`, JSON.stringify(this.filterOptions))
        this.resetPages()
        this.fetchRegistryData()
      }
    },

    computedRecieverFioFilter: {
      get () {
        return this.filterOptions.receiver_fio
      },
      set (value) {
        this.filterOptions.receiver_fio = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry-filter-options`, JSON.stringify(this.filterOptions))
        this.resetPages()
        this.fetchRegistryData()
      }
    },

    computedRecieverPhoneFilter: {
      get () {
        return this.filterOptions.receiver_phone
      },
      set (value) {
        this.filterOptions.receiver_phone = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry-filter-options`, JSON.stringify(this.filterOptions))
        this.resetPages()
        this.fetchRegistryData()
      }
    },

    computedRecieverBirthdayFilter: {
      get () {
        return this.filterOptions.citizenRecieverBirthday
      },
      set (value) {
        this.filterOptions.citizenRecieverBirthday = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry-filter-options`, JSON.stringify(this.filterOptions))
        this.resetPages()
        this.fetchRegistryData()
      }
    },

    computedApplicationStatusIdFilter: {
      get () {
        return this.filterOptions.applicationStatusId
      },
      set (value) {
        this.filterOptions.applicationStatusId = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry-filter-options`, JSON.stringify(this.filterOptions))
        this.resetPages()
        this.fetchRegistryData()
      }
    },

    computedApplicationTypeIdFilter: {
      get () {
        return this.filterOptions.applicationTypeId
      },
      set (value) {
        this.filterOptions.applicationTypeId = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry-filter-options`, JSON.stringify(this.filterOptions))
        this.resetPages()
        this.fetchRegistryData()
      }
    },

    computedRecieverIsDeadFilter: {
      get () {
        return this.filterOptions.citizenRecieverIsDead
      },
      set (value) {
        this.filterOptions.citizenRecieverIsDead = value
        sessionStorage.setItem(`${storageConfig.storagePrefix}registry-filter-options`, JSON.stringify(this.filterOptions))
        this.resetPages()
        this.fetchRegistryData()
      }
    }
  },
  created () {
    const applicationCopy = JSON.parse(sessionStorage.getItem('applicationCopy'))

    if (applicationCopy) {
      this.createRequestDialogIsVisible = true
    }

    this.addWindowResizeListener()
    this.fetchRegistryData()
    this.fetchApplicationTypes()
  },
  beforeDestroy () {
    this.removeWindowResizeListener()
  },
  methods: {
    ...mapActions('references', {
      fetchApplicationTypes: 'fetchApplicationTypes'
    }),

    closeCreateRequestDialog () {
      this.createRequestDialogIsVisible = false
    },

    fetchRegistryData: _.debounce(async function () {
      this.registryTable.isLoading = true
      try {
        const { content, totalElements } = await fetchRegistryData({
          filterOptions: this.filterOptions,
          paginationOptions: this.paginationOptions
        })
        this.registryTable.data = content
        this.registryTable.total = totalElements
      } catch (error) {
        const status = error.response ? error.response.status : null
        const message = error.response ? error.response.data.message : ''
        const messageText = status === httpErrors.notAllowed.status ? message : 'При получении списка заявок произошла ошибка. Попробуйте еще раз.'
        this.showErrorMessage(messageText)
      } finally {
        this.registryTable.isLoading = false
      }
    }, 500),

    openSelectedRequest (id) {
      this.$router.push({ name: 'Request', params: { id: id } })
    },

    resetPages () {
      this.paginationOptions.page = 0
      sessionStorage.setItem(`${storageConfig.storagePrefix}registry_pagination_options`, JSON.stringify(this.paginationOptions))
    },

    addWindowResizeListener () {
      window.addEventListener('resize', () => { this.setWindowWidth(window.innerWidth) })
    },

    removeWindowResizeListener () {
      window.removeEventListener('resize', () => { this.setWindowWidth(window.innerWidth) })
    },

    setWindowWidth (width) {
      this.windowWidth = width
    },

    showErrorMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'error'
      })
    }
  }
}
</script>
<style lang="sass">
  .registry-page-container
    padding: 20px
    width: 100%
    box-sizing: border-box

  div.registry-page-breadcrumbs
    margin-bottom: 20px
    font-size: 16px

  .registry-table-actions
    display: flex
    align-items: center
    justify-content: flex-end
    margin-bottom: 10px
    flex-wrap: wrap

  .registry-upload
    display: flex
    justify-content: flex-end
    margin-bottom: 20px

  .buttons
    display: flex
    justify-content: flex-end
    .new-request-button
      margin-right: 10px

  @media(min-width: 1400px)
    .hidden-1400-and-up
      display: none

  @media(max-width: 1399px)
    .hidden-1400-and-down
      display: none
</style>
