const httpErrors = {
  unauthorized: {
    status: 401,
    message: 'Unauthorized'
  },
  notAllowed: {
    status: 405,
    message: 'METHOD_NOT_ALLOWED'
  }
}

export default httpErrors
