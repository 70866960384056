<template lang='pug'>
  box-card.main-info-card
    template(slot="header")
      div.flex.justify-start.align-center(style='width: 100%')
        span Основная информация
        span(style='display: inline-block; font-size: 14px; color: red; margin-left: 20px;') {{ sourceApplicationId ? 'Отказано ДЗМ' : '' }}
        span(style='display: inline-block; font-size: 14px; margin-left: 20px;') {{ sourceApplicationId ? `ID исходной заявки: ${sourceApplicationId}` : '' }}
      el-button(@click='$emit("openHistoryDialog")' size='small' type='success' icon='el-icon-tickets') Посмотреть историю
    template(slot="content")
      div.main-info-container
        div
          div.flex(stype="flex-wrap: wrap")
            div#id-tag.info-item
              div.tag Заявка c id:
              div.value {{ application.id }}
            div#created-at-tag.info-item
              div.tag Дата заявки:
              div.value {{ application.createdAt }}
            div#application-status-name-tag.info-item
              div.tag Статус:
              div.value {{ application.applicationStatusName }}
            div#applicatio-author-name-tag.info-item
              div.tag Автор:
              div.value {{ applicationAuthor.name }} {{ applicationAuthor.surname }}
            div#user-executor-organiztion-tag.info-item
              div.tag Назначено:
              div.value {{ userExecutor.organization }}
            div#application-execution-date-tag.info-item(v-if="isExecutionDatePickerVisible")
              div.tag Дата исполнения:
              div.value {{ application.execDate }}
            div.info-item(v-if="isDevMode")
              div.tag Тип заявки:
              div.value {{ applicationTypeName }}

          //div(style="margin-top: 20px; flex-basis: 100%")
          //  reciever-check(
          //    :application="application"
          //    @saveReciever="$emit('saveReciever')"
          //  )

        div.request-status-change
          request-status-change.mb-10(
            :application="application"
            :userExecutorId="userExecutor.id"
            @updateLocalApplicationStatus="$emit('updateLocalApplicationStatus', $event)"
            @updateLocalApplicationExecutor="updateLocalApplicationExecutor"
          )

          el-button.mb-10(
            v-if='createNewRequestButtonIsVisible'
            type='primary'
            style='width: 100%'
            @click='$emit("createNewRequest")'
          ) Создать новую

</template>

<script>
import fetchUserDataById from '@/api/apiCalls/user/fetchUserDataById'
import BoxCard from '@/components/BoxCard.vue'
import RequestStatusChange from '@/components/Request/RequestStatusChange.vue'
import RecieverCheck from '@/components/Request/RecieverCheck.vue'
import applicationStatusesConstants from '@/config/applicationStatuses/applicationStatusesConstants'
import applicationTypesConstants from '@/config/applicationTypes/applicationTypesConstants'
import userRolesConstants from '@/config/userRoles/userRolesConstants'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'RequestMainInfo',
  components: {
    BoxCard,
    RequestStatusChange,
    RecieverCheck
  },
  props: {
    application: {
      type: Object,
      default: () => {}
    },
    userExecutor: {
      type: Object,
      default: () => {}
    },
    sourceApplicationId: {
      type: Number,
      default: -1
    },
    services: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectIsVisible: false,
      count: 0,
      applicationAuthor: {
        name: '',
        surname: ''
      }
    }
  },
  computed: {
    ...mapState('references', {
      applicationTypes: state => state.applicationTypes
    }),

    ...mapState('auth', {
      userAbility: state => state.userAbility,
      user: state => state.user
    }),

    ...mapGetters('auth', {
      currentUserRole: 'currentUserRole'
    }),

    applicationTypeName () {
      return this.applicationTypes.length && this.applicationTypes.find(type => type.id === this.application.applicationTypeId).prefix
    },

    isDevMode () {
      return process.env.NODE_ENV === 'development'
    },

    isExecutionDatePickerVisible () {
      return this.application.applicationTypeId === applicationTypesConstants.TT
    },

    createNewRequestButtonIsVisible () {
      return (
        this.currentUserRole === userRolesConstants.OSO &&
        applicationTypesConstants.L === this.application.applicationTypeId &&
        Boolean(this.services.find(item => item.serviceTypeId === 14)) &&
        this.application.applicationStatusId === applicationStatusesConstants.DIRECTED_TO_CSO
      )
    }
  },
  created () {
    this.fetchApplicationAuthorDataById()
    this.fetchApplicationTypes()
  },
  methods: {
    ...mapActions('references', {
      fetchApplicationTypes: 'fetchApplicationTypes'
    }),

    async fetchApplicationAuthorDataById () {
      try {
        const data = await fetchUserDataById(this.application.userAuthorId)
        this.applicationAuthor = data
      } catch (error) {
        const status = error.response ? error.response.status : null
        if (status === 404) {
          this.applicationAuthor.name = `Пользователя с id ${this.application.userAuthorId} нет в базе`
        }
      }
    },

    updateLocalApplicationExecutor (nextExecutor) {
      this.$emit('updateLocalApplicationExecutor', nextExecutor)
    },

    showSuccessMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'success'
      })
    },

    showErrorMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'error'
      })
    }
  }
}
</script>
<style lang="sass">

  .main-info-card
    .info-item
      .tag
        text-transform: uppercase
        font-size: 11px
        letter-spacing: 0.08em
        font-weight: 600
        line-height: 1.7
        color: #909399
        margin-bottom: 4px
      .value
        color: #606266

    .main-info-container
      align-items: center
      display: flex
      .info-item
        margin-right: 40px
      .request-status-change
        margin-left: auto
</style>
