/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.applications.checkAndChangeRouting

export default async (options) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: url,
      data: options
    })

    return data
  } catch (error) {
    throw error
  }
}
