export default (dateString) => {
  const today = new Date()
  const dateArray = dateString.split('.')
  const birthDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
  let age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()
  const day = today.getDate() < birthDate.getDate()
  if (month < 0 || (month === 0 && day)) {
    age--
  }
  return age
}
