
<template lang="pug">
  div.status-change-wrapper(v-if="Object.keys(this.reciever).length")
    div.status-change-content
      //- Dropdown для смены статуса
      el-dropdown.status-change-buttons.mb-10(
        v-if="computedNextStatusesOptions.length"
        v-show="applicationNextStatusesOptions.length"
        @command="changeApplicationStatusHandler"
        trigger="click"
      )
        el-button(
          id="status-change-button"
          type='primary'
          :loading="isAppStatusChangeLoading"
        )
          span Сменить статус
        el-dropdown-menu
          el-dropdown-item(
            v-for="nextStatus in computedNextStatusesOptions"
            :key="nextStatus.id"
            :command="nextStatus"
          ) {{ nextStatus.name }}

      //- Dropdown для переназначения
      //el-dropdown(
      //  v-if="isChangeExecutorButtonVisible"
      //  @command="changeApplicationExecutorHandler"
      //  trigger="click"
      //)
      //  el-button(
      //    :key="2"
      //    type="success"
      //    :loading="isAppChangeExecutorLoading || executorsListLoading"
      //  ) Назначить
      //  el-dropdown-menu(v-if="executorsListOptions.length" :key="executorsListOptionsKey")
      //    el-dropdown-item(
      //      v-for="option in executorsListOptions"
      //      :key="option.id"
      //      :command="option"
      //    ) {{ option.organization }}

      //- Кнопка для возврата району
      el-button(
        v-if="userAbility.can('returnTo', 'area') && isReturnToAreaButtonVisible"
        type="warning"
        @click="returnToArea"
      ) Вернуть району

    el-dialog(
      :title="commentModal.title"
      :visible.sync="commentModal.isVisible"
      width="500px"
    )
      el-form.custom-form
        el-form-item
          div
            el-radio(
              v-model="commentModal.nextStatus.description"
              label="Выявлены родственники, которые будут осуществлять уход"
            )
              span Выявлены родственники, которые будут осуществлять уход
          div
            el-radio(
              v-model="commentModal.nextStatus.description"
              label="Отказ от социального обслуживания в период признания нуждающимся"
            )
              span Отказ от социального обслуживания в период признания нуждающимся
          div
            el-radio(
              v-model="commentModal.nextStatus.description"
              label="Отказ от социального обслуживания после признания нуждающимся"
            )
              span Отказ от социального обслуживания после признания нуждающимся
          div
            el-radio(
              v-model="commentModal.nextStatus.description"
              label="Перевод в другое учреждение здравоохранения"
            )
              span Перевод в другое учреждение здравоохранения

          //el-input(
          //  type="textarea"
          //  v-model="commentModal.nextStatus.description"
          //  rows="5"
          //  maxlength="255"
          //  show-word-limit
          //)
      template(slot="footer")
        el-button(@click="hideCommentModal") Отмена
        el-button(type="primary" @click="changeStatusWithCommentHandler") Отправить

</template>
<script>
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import changeApplicationStatus from '@/api/apiCalls/application/changeApplicationStatus'
import changeApplicationExecutor from '@/api/apiCalls/application/changeApplicationExecutor'
import fetchOsoList from '@/api/apiCalls/application/fetchOsoList'
import fetchDjkhList from '@/api/apiCalls/application/fetchDjkhList'
import applicationStatusesConstants from '@/config/applicationStatuses/applicationStatusesConstants'
import applicationTypesConstants from '@/config/applicationTypes/applicationTypesConstants'
import { mapState, mapActions, mapMutations } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'RequestStatusChange',
  props: {
    application: {
      type: Object,
      default: () => {}
    },
    userExecutorId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      selecetedNextStatus: {},
      selectedOsoId: null,
      executorsListOptions: [],
      executorsListLoading: false,
      isAppStatusChangeLoading: false,
      isAppChangeExecutorLoading: false,
      commentModal: {
        title: 'Изменить статус заявки',
        isVisible: false,
        nextStatus: {}
      },
      savedRecieverResidenceAreaId: null,
      executorsListOptionsKey: 10000
    }
  },
  computed: {
    ...mapState('auth', {
      userAbility: state => state.userAbility,
      user: state => state.user
    }),

    ...mapState('reciever', {
      reciever: state => state.currentReciever,
      recieverId: state => state.currentReciever.id,
      recieverAreaId: state => state.currentReciever.areaId,
      recieverResidenceAreaId: state => state.currentReciever.residenceAreaId,
      recieverDistrictId: state => state.currentReciever.districtId,
      recieverResidenceDistrictId: state => state.currentReciever.residenceDistrictId
    }),

    ...mapState('references', {
      applicationStatusesOptions: state => state.applicationStatuses,
      applicationNextStatusesOptions: state => state.applicationNextStatuses
    }),

    applicationStatusesConstants () {
      return applicationStatusesConstants
    },

    applicationTypesConstants () {
      return applicationTypesConstants
    },

    isChangeExecutorButtonVisible () {
      // let isVisible = this.userAbility.can('change', 'Executor')
      //
      // // Тип заявки
      // // Статус заявки
      // // Признак "В реестре"
      // // Полномочие пользователя
      //
      // // Проверяем на наличие в реестре (карантине)
      // const isApplicationInCarantin = () => this.application.isCarantin
      //
      // // console.group('Executor button')
      // // console.log('Application type id', this.application.applicationTypeId)
      // // console.log('isCarantin', isRecieverInCarantin())
      // // console.groupEnd()
      //
      // switch (this.application.applicationTypeId) {
      //   // Если тип заявки "Л"
      //   case this.applicationTypesConstants.L: {
      //     const statusesList = [
      //       this.applicationStatusesConstants.DIRECTED_TO_CSO
      //     ]
      //
      //     // Если статус заявки "Направлена в ЦСО"
      //     isVisible = statusesList.includes(this.application.applicationStatusId)
      //       ? this.userAbility.can('change', 'executor')
      //       : false
      //   } break
      //
      //   // Если тип заявки "ОСО"
      //   case this.applicationTypesConstants.OSO: {
      //     const statusesList = [
      //       this.applicationStatusesConstants.NEW,
      //       this.applicationStatusesConstants.DIRECTED_TO_CSO,
      //       this.applicationStatusesConstants.ASSIGNED_TO_OSO
      //     ]
      //
      //     // console.log(statusesList.includes(this.application.applicationStatusId))
      //
      //     isVisible = !isApplicationInCarantin()
      //       ? statusesList.includes(this.application.applicationStatusId)
      //         ? this.userAbility.can('change', 'executor')
      //         : false
      //         // : this.userAbility.can('alwaysAssign', 'application')
      //       : false
      //   } break
      //
      //   // Если тип заявки "ТТ"
      //   case this.applicationTypesConstants.TT: {
      //     const statusesList = [
      //       this.applicationStatusesConstants.NEW,
      //       this.applicationStatusesConstants.REJECTED,
      //       this.applicationStatusesConstants.DONE,
      //       this.applicationStatusesConstants.IG_PROGRESS
      //     ]
      //
      //     isVisible = statusesList.includes(this.application.applicationStatusId)
      //       ? this.userAbility.can('change', 'ttTypeExecutor')
      //       : false
      //   }
      // }
      //
      // return isVisible

      return this.userAbility.can('change', 'status')
    },

    isChangeExecutorButtonDisabled () {
      // console.log(this.recieverAreaId, this.recieverResidenceAreaId)
      return !this.recieverAreaId && !this.recieverResidenceAreaId
    },

    isReturnToAreaButtonVisible () {
      return this.application.applicationStatusId === applicationStatusesConstants.ASSIGNED_TO_OSO
    },

    computedNextStatusesOptions () {
      let options = []
      options = this.applicationNextStatusesOptions
        .filter(option => {
          return this.userAbility.can('returnTo', 'area') &&
            option.id === this.applicationStatusesConstants.NEW
            ? false : option
        })

      // Отпадает "Назначено ОСО"
      options = options
        .filter(option => {
          return this.userAbility.can('change', 'Executor') &&
            option.id === this.applicationStatusesConstants.ASSIGNED_TO_OSO
            ? false : option
        })

      // Отпадает "Направлена в ЦСО" и другие
      options = options
        .filter(option => {
          return option.id === this.application.applicationStatusId
            ? false : option
        })

      return options
    }
  },
  watch: {
    recieverAreaId () {
      this.fetchExecutorsList()
    },
    recieverResidenceAreaId () {
      this.fetchExecutorsList()
    }
  },
  created () {
    this.fetchApplicationStatusesOptions()
  },
  destroyed () {
    this.clearRecieverState()
  },
  methods: {
    ...mapActions('references', {
      fetchApplicationStatusesOptions: 'fetchApplicationStatuses',
      fetchApplicationNextStatuses: 'fetchApplicationNextStatuses'
    }),

    ...mapActions('reciever', {
      clearRecieverState: 'clearRecieverState',
      saveRecieverApiCall: 'saveRecieverApiCall',
      fetchReciever: 'fetchReciever',
      saveDefaultReciever: 'saveDefaultReciever'
    }),

    ...mapMutations('reciever', {
      setReciever: 'setReciever'
    }),

    isRecieverAddressFilled () {
      return this.recieverAreaId && this.recieverDistrictId
    },

    isRecieverResidenceAddressFilled () {
      return this.recieverResidenceDistrictId && this.recieverResidenceAreaId
    },

    // Изменение назначения (исполнителя)
    changeApplicationExecutorHandler (nextUserExecutor) {
      // TODO Возможно, эту валидация можно убрать. Оставить валидацию только на isChangeExecutorButtonDisabled
      // Зависит от специфичности требований для доступности фукнкции "Назначение"
      const validateChangeExecutor = () => {
        let isFormValid = true

        switch (this.application.applicationStatusId) {
          // Если текущий статус заяки "Новая"
          // TODO Возможно, избыточное условие
          case this.applicationStatusesConstants.NEW:
            switch (this.application.applicationTypeId) {
              // Если текущий тип заявки "ОСО"
              case this.applicationTypesConstants.OSO:
                isFormValid = this.isRecieverAddressFilled()
                break
            }
            break
        }

        return isFormValid ? true : this.showWarningMessage('Укажите округ и район регистрации')
      }

      const getNextStatusObject = () => {
        let nextStatusId
        switch (this.application.applicationStatusId) {
          // Если текущий статус заяки "Новая"
          // TODO Возможно, избыточное условие
          case this.applicationStatusesConstants.NEW:
            switch (this.application.applicationTypeId) {
              // Если текущий тип заявки "ОСО"
              case this.applicationTypesConstants.OSO:
                nextStatusId = this.applicationStatusesConstants.ASSIGNED_TO_OSO
                break

              // Если текущий тип заявки "Л"
              case this.applicationTypesConstants.L:
                nextStatusId = this.applicationStatusesConstants.ASSIGNED_TO_OSO
                break
            }
            break
        }

        if (nextStatusId) return this.applicationStatusesOptions.find(option => option.id === nextStatusId)
      }

      const isFormValid = validateChangeExecutor()
      if (!isFormValid) return

      const nextStatus = getNextStatusObject()
      if (nextStatus) {
        // TODO новый метод на беке
        this.changeBoth({ nextStatus, nextUserExecutor })
      } else {
        this.changeApplicationExecutorCall(nextUserExecutor)
      }

      // console.log('nextStatusObject', getNextStatusObject())
    },

    // HTTP запрос на изменение назначения (исполнителя)
    async changeApplicationExecutorCall (nextUserExecutor) {
      // console.group('Обработка назначения исполнителя', nextUserExecutor)
      // console.log('Новый исполнитель id', nextUserExecutor.id)
      // console.log('Новый исполнитель organization', nextUserExecutor.organization)
      // console.groupEnd()

      try {
        this.isAppChangeExecutorLoading = true

        await changeApplicationExecutor({
          applicationId: this.application.id,
          userExecutorId: nextUserExecutor.id
          // Если null, то исполнитель заявки не изменится
          // Если -1, то исполнитель заявки сбрасывается на null
          // Если другое число, то исполнитель меняется на нового
        })

        await this.fetchReciever(this.recieverId)
        this.showSuccessMessage(`Новый исполнитель назначен: "${nextUserExecutor.organization}"`)
        this.$emit('updateLocalApplicationExecutor', nextUserExecutor)
        this.fetchApplicationNextStatuses(this.application.id)
      } catch (error) {
        const message = error.response ? error.response.data.message : 'Причина не указана'
        this.showErrorMessage(`Не удалось изменить исполнителя заявки. Причина: "${message}"`)
      } finally {
        setTimeout(() => {
          this.isAppChangeExecutorLoading = false
        }, 300)
      }
    },

    // Изменение статуса
    async changeApplicationStatusHandler (nextStatus) {
      // console.log('status change handlder')
      // Если заявка переводится в статус "Новая"

      await this.saveRecieverApiCall()

      if (nextStatus.id === this.applicationStatusesConstants.REJECT_EXECUTOR) {
        this.showCommentModal({ nextStatus, title: 'Изменение статуса заявки на "Отклонена"' })
      } else {
        this.changeApplicationStatusCall(nextStatus)
      }

      // console.log(this.applicationStatusesConstants.NEW)
      // if (nextStatus.id === this.applicationStatusesConstants.NEW) {
      //   if (!this.isRecieverAddressFilled()) {
      //     this.showWarningMessage('Укажите округ и район регистрации')
      //   } else if (
      //     this.userAbility.can('changeTo', 'newStatus')
      //       ? this.reciever.isMoscowRegion
      //         ? false
      //         : !this.isRecieverResidenceAddressFilled()
      //       : false
      //   ) {
      //     this.showWarningMessage('Укажите округ и район проживания')
      //   } else {
      //     const nextUserExecutor = {
      //       userExecutorId: -1,
      //       organization: ''
      //     }
      //
      //     nextStatus.userExecutorId = nextUserExecutor.userExecutorId // Сбрасываем исполнителя
      //     await this.changeApplicationStatusCall(nextStatus)
      //     this.$emit('updateLocalApplicationExecutor', nextUserExecutor)
      //   }
      // // Если заявка переводится в статус "Выполнена" пользователем с правом (OSO)
      // // Убрали требование к роли пока, могут позже вернуть
      // // } else if (nextStatus.id === this.applicationStatusesConstants.DONE && this.userAbility.can('changeTo', 'doneStatus')) {
      // } else if (nextStatus.id === this.applicationStatusesConstants.DONE) {
      //   this.showCommentModal({ nextStatus, title: 'Изменение статуса заявки на "Выполнено"' })
      // // Если заявка переводится в статус "Отклонена" пользователем с правом (OSO)
      // // Убрали требование к роли пока, могут позже вернуть
      // // } else if (nextStatus.id === this.applicationStatusesConstants.REJECTED && this.userAbility.can('changeTo', 'rejectedStatus')) {
      // } else if (nextStatus.id === this.applicationStatusesConstants.REJECTED) {
      //   this.showCommentModal({ nextStatus, title: 'Изменение статуса заявки на "Отклонена"' })
      // // Если заявка переводится в статус "Черновик"
      // } else if (nextStatus.id === this.applicationStatusesConstants.DRAFT) {
      //   const nextUserExecutor = {
      //     userExecutorId: -1,
      //     organization: ''
      //   }
      //
      //   nextStatus.userExecutorId = nextUserExecutor.userExecutorId // Сбрасываем исполнителя
      //
      //   this.clearRecieverAddress()
      //   await this.saveRecieverApiCall()
      //   await this.changeApplicationStatusCall(nextStatus)
      //   if (!this.userAbility.can('read', 'draftApplications')) {
      //     this.$router.replace({ name: 'Registry' })
      //   } else {
      //     this.$emit('updateLocalApplicationExecutor', nextUserExecutor)
      //   }
      // } else if (nextStatus.id === this.applicationStatusesConstants.STATUS_VOLUNTEER) {
      //   nextStatus.userExecutorId = -1
      //   await this.changeApplicationStatusCall(nextStatus)
      // // Если заявки переводится в любой другой статус
      // } else {
      //   this.changeApplicationStatusCall(nextStatus)
      // }
    },

    // HTTP запрос на изменение статуса
    async changeApplicationStatusCall (nextStatus) {
      // console.group('Обработка смены статуса')
      // console.log('Новый статус id', nextStatus.id)
      // console.log('Новый статус name', nextStatus.name)
      // console.groupEnd()

      try {
        this.isAppStatusChangeLoading = true

        const status = {
          applicationId: this.application.id,
          applicationStatusId: nextStatus.id,
          userExecutorId: !this.application.userExecutorId ? this.user.id : null,
          applicationStatusComment: nextStatus.description
        }

        await changeApplicationStatus(status)

        await this.fetchReciever(this.recieverId)

        this.showSuccessMessage(`Статус заявки успешно изменен на "${nextStatus.name}"`)
        this.$emit('updateLocalApplicationStatus', nextStatus)

        if (!this.application.userExecutorId) {
          this.$emit('updateLocalApplicationExecutor', this.user)
        }

        this.fetchApplicationNextStatuses(this.application.id)
      } catch (error) {
        const message = error.response ? error.response.data.message : 'Причина не указана'
        this.showErrorMessage(`Не удалось изменить статус заявки. Причина: "${message}"`)
      } finally {
        setTimeout(() => {
          this.isAppStatusChangeLoading = false
        }, 300)
      }
    },

    // HTTP запрос на изменение статуса И назначения
    async changeBoth ({ nextStatus, nextUserExecutor }) {
      // console.group('Обработка смены статуса и назначения')
      // console.log('Новый статус id', nextStatus.id)
      // console.log('Новый статус name', nextStatus.name)
      // console.log('Новый исполнитель is', nextUserExecutor.id)
      // console.log('Новый исполнитель organization', nextUserExecutor.organization)
      // console.groupEnd()

      try {
        this.isAppChangeExecutorLoading = true
        await this.saveRecieverApiCall()

        await changeApplicationStatus({
          applicationId: this.application.id,
          applicationStatusId: nextStatus.id,
          userExecutorId: nextUserExecutor.id
        })

        await this.fetchReciever(this.recieverId)
        const message = nextUserExecutor.id === -1
          ? 'Заявка возвращена в район'
          : `Статус заявки успешно изменен на "${nextStatus.name}". Назначен исполнитель: "${nextUserExecutor.organization}"`
        this.showSuccessMessage(message)

        this.$emit('updateLocalApplicationStatus', nextStatus)
        this.$emit('updateLocalApplicationExecutor', nextUserExecutor)
        this.fetchApplicationNextStatuses(this.application.id)
      } catch (error) {
        const message = error.response ? error.response.data.message : 'Причина не указана'
        this.showErrorMessage(`Не удалось назначить нового исполнителя. Причина: "${message}"`)
      } finally {
        setTimeout(() => {
          this.isAppChangeExecutorLoading = false
        }, 300)
      }
    },

    // Возврат в район
    returnToArea () {
      this.changeBoth({
        nextStatus: this.applicationStatusesOptions.find(option => option.id === applicationStatusesConstants.NEW),
        nextUserExecutor: { id: -1, organization: '' }
      })
    },

    fetchExecutorsList: debounce(async function () {
      if (this.recieverResidenceAreaId && this.recieverResidenceAreaId === this.savedRecieverResidenceAreaId) return
      const areaId = this.recieverResidenceAreaId || this.recieverAreaId
      if (!areaId) return

      const osoExecutorsTypes = [
        this.applicationTypesConstants.L,
        this.applicationTypesConstants.OSO
      ]

      const ttExecutorsTypes = [
        this.applicationTypesConstants.TT
      ]

      this.executorsListLoading = true

      switch (true) {
        case osoExecutorsTypes.includes(this.application.applicationTypeId):
          await this.fetchOsoList(areaId)
          this.executorsListLoading = false
          this.executorsListOptionsKey++
          break

        case ttExecutorsTypes.includes(this.application.applicationTypeId):
          await this.fetchDjkhList(areaId)
          this.executorsListLoading = false
          this.executorsListOptionsKey++
          break
      }

      this.savedRecieverResidenceAreaId = this.recieverResidenceAreaId
    }, 400),

    async fetchOsoList (areaId) {
      this.executorsListOptions = await fetchOsoList(areaId)
    },

    async fetchDjkhList (areaId) {
      this.executorsListOptions = await fetchDjkhList(areaId)
    },

    async changeStatusWithCommentHandler () {
      await this.changeApplicationStatusCall(this.commentModal.nextStatus)
      this.hideCommentModal()
    },

    showCommentModal ({ nextStatus, title }) {
      this.commentModal.title = title
      this.commentModal.nextStatus = nextStatus
      this.commentModal.isVisible = true
    },

    hideCommentModal () {
      this.commentModal.isVisible = false
    },

    showSuccessMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'success'
      })
    },

    showErrorMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'error'
      })
    },

    showWarningMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'warning'
      })
    },

    clearRecieverAddress () {
      const reciever = JSON.parse(JSON.stringify(this.reciever))
      reciever.areaId = null
      reciever.districtId = null
      reciever.residenceAreaId = null
      reciever.residenceDistrictId = null
      this.setReciever(reciever)
      this.saveDefaultReciever(reciever)
    }
  }
}
</script>
<style lang="sass">
  .status-change-wrapper
    min-width: 160px
    width: 160px

  .status-change-content
    position: relative
    display: flex
    flex-direction: column
    .el-dropdown, .el-button
      width: 100%
  .assign
    display: flex
    align-items: center
  .status-title
    font-size: 15px
    margin-bottom: 7px

  .application-assign-box
    position: absolute
    right: 0
    width: 320px
    z-index: 2
    .el-select
      margin-bottom: 10px
      width: 100%
    &-buttons
      display: flex
      justify-content: flex-end

</style>
