import axios from 'axios'
import endpoints from '@/api/endpoints'
import storageConfig from '@/config/storageConfig'

const questionsStorageName = 'questions-options'
const url = endpoints.references.questions.find

export default async (params) => {
  try {
    const storedQuestionsOptions = JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${questionsStorageName}`))

    if (storedQuestionsOptions && storedQuestionsOptions.length) return storedQuestionsOptions

    const { data: { content } } = await axios({
      method: 'GET',
      url,
      params: {
        rsql: 'userType==2',
        size: 100,
        sort: 'name'
      }
    })

    sessionStorage.setItem(`${storageConfig.storagePrefix}${questionsStorageName}`, JSON.stringify(content))
    return content
  } catch (error) {
    return error
  }
}
