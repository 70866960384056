<template lang="pug">
  box-card
    template(slot="header") Экспресс функциональная диагностика
    template(slot="content")
      el-form.custom-form(disabled)
        el-row
          el-col(:span='17')
            el-form-item(label='')
              el-checkbox.custom-checkbox(id="is-express-fd-checkbox" v-model='application.isExpressFD') Проведена экспресс Ф/Д
          el-col(:span='7')
            el-form-item(label='')
              el-date-picker(
                v-model='application.expressFDDate'
                format='dd.MM.yyyy'
                value-format='dd.MM.yyyy'
                v-mask='"99.99.9999"'
              )
        el-row
          el-form-item(label='')
            el-checkbox.custom-checkbox(id="is-express-fd-rejected-checkbox" v-model='application.isRejectByResultExpressFD' ) Отказано в обслуживании по результатам ФД
        el-row
          el-form-item(label='')
            el-checkbox.custom-checkbox(id="is-express-fd-rejected-checkbox" v-model='application.isRejectFromExpressFD') Отказ от проведения ФД
        ul.custom-list
          li(v-for="uri in application.expressFDFiles")
            el-link(
              type="primary"
              @click="handleDownloadFile(uri)"
              icon="el-icon-download"
              :underline="false"
              ) {{getFileName(uri)}}

</template>

<script>
import BoxCard from '@/components/BoxCard.vue'
import downloadApplicationFile from '@/api/apiCalls/file/downloadApplicationFile'
import systemParamsConstants from '@/config/systemParams/systemParamsConstants'
import { saveAs } from 'file-saver'

import { mapState } from 'vuex'

export default {
  name: 'RequestExpressFD',
  components: {
    BoxCard
  },
  props: {
    application: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('systemParams', {
      systemParams: state => state.systemParams
    })
  },
  methods: {
    getFileName (uri) {
      return uri.substring(uri.lastIndexOf('/') + 1)
    },

    async handleDownloadFile (uri) {
      const concatUri = `${this.systemParams[systemParamsConstants.webdavDownloadAdr].paramValue}${uri}`

      const auth = {
        username: this.systemParams[systemParamsConstants.webdavLogin].paramValue,
        password: this.systemParams[systemParamsConstants.webdavPassword].paramValue
      }

      const { data } = await downloadApplicationFile({
        url: concatUri,
        auth
      })

      if (data) {
        saveAs(data, this.getFileName(uri))
      }
    }
  }
}
</script>

<style lang="sass">
  .custom-list
    margin: 0
    padding: 0
    li
      margin-bottom: 5px
      list-style: none

</style>
