<template lang='pug'>
  box-card
    template(slot="header")
      span Дополнительная информация

      div(v-if="isEditCommentAvailable")
        div(v-show="!editMode")
          el-button.el-button--text_icon(id="extra-data-edit-mode-button" type='text' v-show="!editMode" @click='enableEditingMode')
            font-awesome-icon(icon="pencil-alt")
            span Редактировать

        div(v-show="editMode")
          el-button.el-button--text_icon.cancel-button(id="extra-data-edit-mode-cancel-button" type='text' @click='disableEditingMode')
            font-awesome-icon(icon="times")
            span Отмена
          el-button.el-button--text_icon.accept-button(id="extra-data-save-button" type='text' :loading="isApplicationDataSaving" @click='saveApplication')
            font-awesome-icon(icon="check")
            span Сохранить

    template(slot="content")
      el-form.custom-form.extra-info-form(
        :disabled='!editMode'
        @submit.native.prevent
      )
        el-row
          el-col
            el-form-item(label="Комментарий" style="margin-bottom: 0")
              el-input(
                id="comment-input"
                v-model="extraDataForm.comment"
                type="textarea"
                maxlength="2000"
                show-word-limit
              )

        el-row
          el-col(:span="8")
            el-form-item(
              style="margin-bottom: 0; margin-top: 20px;"
              prop="execDate"
              label='Дата исполнения'
            )
              el-date-picker.execution-date(
                id="exec-date-input"
                popper-class="exec-date-picker"
                v-model="extraDataForm.execDate"
                :picker-options="execDatePickerOptions"
                value-format="dd.MM.yyyy"
                format="dd.MM.yyyy"
                v-mask='"99.99.9999"'
              )

</template>

<script>
import BoxCard from '@/components/BoxCard.vue'
import { mapState } from 'vuex'
// import applicationStatusesConstants from '@/config/applicationStatuses/applicationStatusesConstants.js'
import applicationTypesConstants from '@/config/applicationTypes/applicationTypesConstants.js'

export default {
  name: 'RequestExtraData',
  components: {
    BoxCard
  },
  props: {
    isApplicationDataSaving: {
      type: Boolean,
      default: false
    },
    application: {
      type: Object,
      default: () => {}
    },
    execDate: {
      type: String,
      default: ''
    },
    comment: {
      type: String,
      default: 'Комментарий не указан'
    }
  },
  data () {
    return {
      editMode: false,
      defaultComments: '',
      execDatePickerOptions: {
        disabledDate (date) {
          return date < new Date() - 1000 * 60 * 60 * 24
        }
      },
      extraDataForm: {
        comment: '',
        execDate: ''
      },
      defaultExtraDataForm: null
    }
  },
  watch: {
    'extraDataForm.comment' (value) {
      this.$emit('update:comment', value)
    },
    'extraDataForm.execDate' (value) {
      this.$emit('update:execDate', value)
    }
  },
  created () {
    this.extraDataForm.comment = this.comment
    this.extraDataForm.execDate = this.execDate

    // Сюда кладётся обьект с начальными данными.
    // Для того что бы при сбросе, можно было вернутся в исходное состояние.
    this.defaultExtraDataForm = Object.assign({}, { ...this.extraDataForm })
  },
  computed: {
    ...mapState('auth', {
      userAbility: state => state.userAbility
    }),

    isEditCommentAvailable () {
      // Если тип заявки "ОСО" и признак "Есть в реестре" в положении "Да"
      // return this.application.applicationTypeId === applicationTypesConstants.OSO &&
      //   this.application.isCarantin
      //   ? this.userAbility.can('edit', 'osoTypeComment') &&
      //     this.userAbility.can('edit', 'onCarantinComment')
      //   // Любой другой тип заявки и статус завки "Направлена в ЦСО"
      //   : this.application.applicationStatusId === applicationStatusesConstants.DIRECTED_TO_CSO
      //     ? this.userAbility.can('editOnDirectedToCso', 'comment')
      //     // Любой тип заявки и любой статус заявки
      //     : true
      return this.userAbility.can('edit', 'application')
    },

    isexecDatePickerVisible () {
      return this.application.applicationTypeId === applicationTypesConstants.TT
    },

    isExecDateDisabled () {
      return this.userAbility.cannot('edit', 'execDate')
    }
  },
  methods: {
    changeEditionMode () {
      this.editMode = !this.editMode
    },
    enableEditingMode () {
      this.editMode = true
      this.defaultComments = this.computedCommentary
    },
    disableEditingMode () {
      this.editMode = false
      this.computedCommentary = this.defaultComments

      // Сброс в исходное состояние. Смотри created()
      this.extraDataForm = Object.assign({}, { ...this.defaultExtraDataForm })
    },
    saveApplication () {
      this.$emit('saveApplication', {
        success: 'Заявка успешно сохранена',
        error: 'Не удалось сохранить заявку'
      })

      setTimeout(() => {
        this.changeEditionMode()
        this.defaultExtraDataForm = Object.assign({}, { ...this.extraDataForm })
      }, 300)
    },
    saveDefaultComments () {
      this.defaultComments = JSON.stringify(this.currentComments)
    },
    loadDefaultComments () {
      this.currentComments = JSON.parse(this.defaultComments)
    }
  }
}
</script>
<style lang="sass">
  .extra-info-form.custom-form
    .el-textarea__inner
      height: 150px

</style>
