<template lang="pug">
  div.registry-table-wrapper
    el-table.registry-table(
      v-loading="isLoading"
      :data="data"
      border
      :row-class-name="tableRowClassName"
      @row-click="openSelectedRequest"
    )
      el-table-column(
        :class-name="`${name}-column`"
        :label-class-name="`${name}-header-column`"
        v-for="(value, name) in headers"
        :key='name'
        :prop="name",
        :label="value.label"
        :width="value.width"
      )

    el-pagination.registry-table-pagination(
      background
      :disabled="isLoading"
      layout="slot, sizes, pager"
      :total="total"
      :page-sizes="pageSizes"
      :current-page="paginationOptions.page"
      :page-size="paginationOptions.size"
      @current-change="$emit('pageChange', $event)"
      @size-change="$emit('sizeChange', $event)"
    )
      span.total-text Найдено заявок: {{ total }}
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'RegistryTable',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    headers: {
      type: Object,
      default: () => {}
    },
    paginationOptions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pageSizes: [10, 15, 20, 50]
    }
  },
  computed: {
    ...mapState('auth', {
      userAbility: state => state.userAbility
    })
  },
  methods: {
    openSelectedRequest (row, column, event) {
      this.$emit('openSelectedRequest', row.id)
    },

    tableRowClassName ({ row }) {
      let classList = ''

      if (row.citizenRecieverIsDead) {
        classList = 'reciever-passed-away'
      }

      return classList
    }
  }
}
</script>
<style lang="sass">
  .registry-table-wrapper
    width: 100%

  .registry-table.el-table
    margin-top: 8px
    margin-bottom: 20px
    font-size: 16px
    .cell
      word-break: normal
    .el-table__row
      cursor: pointer
      td
        padding: 7px 0

    .warning-row
      background: #fde2e2

  .el-pagination
    text-align: right
    *
      font-size: 14px !important

    &__sizes
      .el-select
        div.el-input
          width: 135px

    .total-text
      font-weight: normal
      margin-right: 10px

  .reciever-passed-away
    .citizenRecieverFIO-column
      position: relative
      &::after
        content: "\f111"
        position: absolute
        top: 10px
        right: 10px
        font-family: "Font Awesome 5 Free"
        font-weight: 900
        color: #303133
</style>
