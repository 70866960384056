/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.applications.changeExecutor

export default async ({ applicationId, userExecutorId }) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: url,
      data: { applicationId, userExecutorId }
    })

    return data
  } catch (error) {
    throw error
  }
}
