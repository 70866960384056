<template lang='pug'>
  el-form-item(:label='label' :prop='validateProp')
    el-input(
      v-if='searchDisabled'
      v-model='addressInfo.address'
      :placeholder='placeholder'
      :disabled='disabled'
      clearable
    )
    el-select(
      v-else
      v-model='addressInfo.address'
      :placeholder='placeholder'
      filterable
      clearable
      remote
      :disabled='disabled'
      :remote-method='fetchFiasAddress'
      :loading='isLoading'
    )
      el-option(
        v-for='item in fiasAddress'
        :key='item.fiasGuid'
        :label='item.address'
        :value='item.address'
      )
</template>

<script>
import fetchAddress from '@/api/apiCalls/fiasAddress/fetchAddress'
import { debounce } from 'lodash'

export default {
  name: 'FetchFiasAddress',
  props: {
    addressInfo: {
      type: Object,
      default: () => ({
        address: '',
        fiasGuid: '',
        areaId: null,
        districtId: null
      })
    },
    onlyMoscow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchDisabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Адрес'
    },
    placeholder: {
      type: String,
      default: 'Введите адрес'
    },
    validateProp: {
      type: String,
      default: 'addressInfo.address'
    }
  },
  data () {
    return {
      isLoading: false,
      fiasItem: null,
      fiasAddress: []
    }
  },
  computed: {},
  created () {
    // this.fiasAddress = fetchAddress()
  },
  watch: {
    'addressInfo.address' (value) {
      if (value) {
        this.fiasItem = this.fiasAddress.find(item => item.address === value)

        if (this.fiasItem) {
          this.$emit(
            'update:addressInfo',
            Object.assign(this.addressInfo, {
              address: this.fiasItem.address,
              fiasGuid: this.fiasItem.fiasGuid,
              areaId: this.fiasItem.areaId,
              districtId: this.fiasItem.districtId
            })
          )
        }
      } else {
        this.fiasAddress = []

        this.$emit(
          'update:addressInfo',
          Object.assign(this.addressInfo, {
            address: '',
            fiasGuid: '',
            areaId: null,
            districtId: null
          })
        )
      }
    }
  },
  methods: {
    fetchFiasAddress: debounce(async function (query) {
      if (query && query.length >= 3) {
        this.isLoading = true
        this.fiasAddress = await fetchAddress(query, this.onlyMoscow)

        this.isLoading = false
      } else {
        this.fiasAddress = []
      }
    }, 1000)
  }
}
</script>
<style lang="sass">
  .services-box-card
    .custom-checkbox-group
      .el-checkbox.custom-checkbox
        margin-bottom: 15px;
      .el-checkbox.custom-checkbox, .el-checkbox__label
        display: flex;
        flex-wrap: wrap;
      .el-checkbox__input
        margin-top: 3px;
      .el-input
        margin-top: 5px;
        width: 100%;
      .el-checkbox__label
        min-width: 500px;
</style>
