/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'
import storageConfig from '@/config/storageConfig'

const usersRolesStorageName = 'users-roles'
const url = endpoints.roles.find

export default async () => {
  const storeUsersRoles = JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${usersRolesStorageName}`))

  if (storeUsersRoles && storeUsersRoles.length) return storeUsersRoles

  const params = {
    size: 50
  }

  try {
    const { data: { content } } = await axios({
      method: 'GET',
      url,
      params
    })

    sessionStorage.setItem(`${storageConfig.storagePrefix}${usersRolesStorageName}`, JSON.stringify(content))
    return content
  } catch (error) {
    throw error
  }
}
