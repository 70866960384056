<template lang="pug">
  box-card
    template(slot="header") Нуждаемость в обслуживании не подтверждена
    template(slot="content")
      el-form.custom-form(disabled)

        el-row
          div(v-for='el in application.notConfirmed')
              el-col(:span='17')
                el-form-item(label='')
                  el-checkbox.custom-checkbox( v-model='!!el.name') {{el.name }}
              el-col(:span='7')
                el-date-picker(
                  size='small'
                  v-model="el.date"
                  disabled
                )
        ul.custom-list
          li(v-for="uri in application.notConfirmedFiles")
            el-link(
              type="primary"
              @click="handleDownloadFile(uri)"
              icon="el-icon-download"
              :underline="false"
            ) {{getFileName(uri)}}

</template>

<script>
import BoxCard from '@/components/BoxCard.vue'
import { mapState } from 'vuex'
import downloadApplicationFile from '@/api/apiCalls/file/downloadApplicationFile'
import systemParamsConstants from '@/config/systemParams/systemParamsConstants'
import { saveAs } from 'file-saver'

export default {
  name: 'RequestNotConfirmed',
  components: {
    BoxCard
  },
  props: {
    application: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('systemParams', {
      systemParams: state => state.systemParams
    })
  },
  methods: {
    getFileName (uri) {
      return uri.substring(uri.lastIndexOf('/') + 1)
    },

    async handleDownloadFile (uri) {
      const concatUri = `${this.systemParams[systemParamsConstants.webdavDownloadAdr].paramValue}${uri}`

      const auth = {
        username: this.systemParams[systemParamsConstants.webdavLogin].paramValue,
        password: this.systemParams[systemParamsConstants.webdavPassword].paramValue
      }

      const { data } = await downloadApplicationFile({
        url: concatUri,
        auth
      })

      if (data) {
        saveAs(data, this.getFileName(uri))
      }
    }

  }
}
</script>

<style lang="sass">
.custom-list
  margin: 0
  padding: 0
  li
    margin-bottom: 5px
    list-style: none

</style>
