/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.users.profileFull

export default async () => {
  try {
    const { data: { content } } = await axios({
      method: 'GET',
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    return content[0]
  } catch (error) {
    throw error
  }
}
