<template lang="pug">
  .header
    el-header
      span.page-title Социальная служба в больнице
      router-link.back-button(
        v-show="$route.name === 'Request'",
        :to="{ name: 'Registry' }"
      )
        el-button(type="text") Назад
      .header-right-section
        .user-info
          font-awesome-icon.user-icon(icon="user-circle")
          span {{ userName }}
        button#logout-button.logout-button(, @click="logout", title="Выход")
          font-awesome-icon.icon(icon="door-open")
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheHeader',
  data () {
    return {
      supportEmail: 'info.dczn@gmail.com'
    }
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user
    }),
    ...mapGetters('auth', ['currentUserRole']),
    userName () {
      return `${this.user.name} ${this.user.surname}`
    },
    currentPageName () {
      return this.$route.meta.translate
    },
    userGuideLink: (state) => {
      switch (state.currentUserRole) {
        case 'OPERATOR':
          return 'https://drive.google.com/file/d/13yymFkmtIPoDvUsDd1c1ifDG32KguQQe/view?usp=sharing'
        case 'CONTROllER':
          return 'https://drive.google.com/file/d/10q8D51Gp-5jDTKfAIrVwdRjnq8gwNv9G/view?usp=sharing'
        case 'COUNTYDISPATCHER':
          return 'https://drive.google.com/file/d/1yCaNkgx8GJqemh2jky2SUQ8jnc5ZKzMT/view'
        case 'AREAMANAGER':
          return 'https://drive.google.com/file/d/1sj9GcqmcJcHKmRtxrRwSE9Abj85EpLcm/view?usp=sharing'
        case 'OSO':
          return 'https://drive.google.com/file/d/1K3AcYZFZHF3qX62AlZB8FtF-jiipRUAy/view?usp=sharing'
        case 'DTSZN':
          return 'https://drive.google.com/file/d/1hYMvYIWYi-p_oo9czVt_5dtZqTWT1zTG/view?usp=sharing'
        case 'DJKH':
          return 'https://drive.google.com/file/d/195UF91VKROuvKWUHaaR5jWJfOKFWKrl5/view?usp=sharing'
        case 'OSZN':
          return 'https://drive.google.com/file/d/1srs-D5Y_h6_LG54vj7mrjh77WQtInbj5/view?usp=sharing'
        case 'DZMDISPATCHER':
          return 'https://drive.google.com/file/d/1EMlahH0mWrahMKjI9dC3SLiGltPrGjfJ/view?usp=sharing'
        case 'DZM':
          return 'https://drive.google.com/file/d/1EMlahH0mWrahMKjI9dC3SLiGltPrGjfJ/view?usp=sharing'
        case 'INCREASEDSECURITY':
          return 'https://drive.google.com/file/d/150jwr09LO1nv1f15LJQ2itHAtK6-xB04/view?usp=sharing'
        case 'VOLUNTEER_CONTROLLER':
          return 'https://drive.google.com/file/d/1UevxTG1ZJ4o9ey6ZvJkFmC_0T-HEIag_/view'
        case 'VOLUNTEER_DISTRICT':
          return 'https://drive.google.com/file/d/1ivLjEzsdfpV9qWErZmAPQ_nV0NIGjvCD/view'
        default:
          return ''
      }
    },
    userAllGuidesLink: (state) => {
      if (state.currentUserRole === 'DTSZN') {
        return 'https://drive.google.com/drive/folders/1qjwu370EtVjTpU617saQ7vdQugbjDk5x'
      }
      return ''
    }
  },
  methods: {
    ...mapActions('auth', {
      userLogout: 'authLogout',
      authLogoutOnServer: 'authLogoutOnServer'
    }),
    logout () {
      this.$confirm('Выйти из системы?', {
        customClass: 'logout-form',
        cancelButtonClass: 'logout-cancel-button',
        confirmButtonClass: 'logout-confirm-button',
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        center: true
      }).then(async () => {
        await this.authLogoutOnServer()
        await this.userLogout()
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.header
  min-width: 1152px
  width: 100%
.header header
  border-bottom: 1px solid rgb(238, 238, 238)
  display: flex
  align-items: center
  justify-content: space-between
  background: #fff

  .user-guide-link
    font-size: 14px
    text-decoration: none
    margin-right: 30px

.page-title
  font-size: 18px
  font-family: Roboto-Medium
  color: #303133

.user-info
  margin-right: 30px
  display: flex
  align-items: center

  span
    margin-top: 2px

  .user-icon
    font-size: 32px
    margin-right: 10px
    color: #409EFF

.logout-button
  background: none
  border: none
  cursor: pointer
  img
    width: 30px
    transform: rotate(180deg)

.header-right-section
  display: flex
  align-items: center
  margin-left: auto
  .icon
    font-size: 20px
    cursor: pointer
    line-height: 26px
    color: #303133
    &:hover
      color: #409EFF

.back-button
  margin-right: auto
  margin-left: 20px
  button.el-button
    font-size: 16px

.support-info
  margin-right: 30px
  span
    font-size: 14px

@media print
  .header
    header
      border: none
    .page-title,
    .back-button
      display: none
</style>
