<template lang='pug'>
  el-form-item.address-picker(:label='label' :prop='validateProp')

    el-input.manual-input(
      v-if='searchDisabled'
      v-model='computedAddressText'
      :placeholder='placeholder'
      :disabled='disabled'
      clearable
    )

    el-select(
      v-else
      :value='computedAddressText'
      :placeholder='placeholder'
      filterable
      clearable
      remote
      :disabled='disabled'
      :remote-method='fetchFiasAddress'
      :loading='isLoading'
      @change="change"
      @clear="clear"
    )
      el-option(
        v-for='item in fiasAddressOptions'
        :key='item.fiasGuid'
        :label='item.address'
        :value='item'
      )
    el-input(
      v-model='computedAddressText'
      :placeholder='placeholder'
      :disabled='disabled'
      type='textarea'
    )
    div.address-text-label.flex(v-if="computedAddressText.length > 69")
      div(style="min-width: 95px;") Полный адрес:
      div.address-text-label__text(v-html="computedAddressTextLabel")
</template>

<script>
import fetchAddress from '@/api/apiCalls/fiasAddress/fetchAddress'
import { debounce } from 'lodash'

export default {
  name: 'FetchFiasAddress',
  props: {
    addressText: {
      type: String,
      default: ''
    },
    onlyMoscow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchDisabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Адрес'
    },
    placeholder: {
      type: String,
      default: 'Введите адрес'
    },
    validateProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      fiasAddressOptions: []
    }
  },
  computed: {
    computedAddressText: {
      get () {
        return this.addressText && this.addressText
      },
      set (value) {
        this.$emit('update:addressText', value)
      }
    },

    computedAddressTextLabel () {
      const sourceArray = this.computedAddressText.length && this.computedAddressText.split(', ')
      const resutlString = sourceArray
        .map(subString => {
          const string = subString.split(' ').join('&nbsp;')
          return string
        })
        .join(', ')
      return resutlString
    }
  },
  methods: {
    fetchFiasAddress: debounce(async function (query) {
      if (query && query.length >= 3) {
        this.isLoading = true
        this.fiasAddressOptions = await fetchAddress(query, this.onlyMoscow)

        this.isLoading = false
      } else {
        this.fiasAddressOptions = []
      }
    }, 700),

    change (value) {
      if (value.length === 0) return
      this.$emit('selectAddress', value)
      this.fiasAddressOptions = []
    },

    clear (value) {
      this.$emit('selectAddress', { address: '', areaId: null, districtId: null, fiasGuid: '' })
      this.fiasAddressOptions = []
    }
  }
}
</script>
<style lang="sass">
.address-text-label
  line-height: 20px
  font-size: 12px
  padding: 8px 10px 0
  color: #555
  position: relative

.address-picker
  position: relative
  .el-textarea
    display: none

  @media print
    .el-input, .el-select
      display: none
    .el-textarea
      display: block

</style>
