<template lang="pug">
  div
    el-dropdown(
      trigger="click"
      @command="handleSelectDocumentType"
    )
      el-button.custom-button(
        id="excel-upload-button"
        type="primary"
        title="Загрузить реестр"
        plain
      )
        font-awesome-icon.icon(icon="file-excel")
        span Загрузить реестр
      el-dropdown-menu
        el-dropdown-item(
          v-for="option in documentTypes"
          :key="option.name"
          :command="option"
        ) {{ option.dropDownTitle }}

    el-dialog(
      :title="selectedDocumentType.dialogTitle"
      :visible.sync="isUploadDialogVisible"
      width="500px"
    )
      .carantin-registry-upload-result(v-if="uploadState.result || uploadState.isLoading")
        el-alert(
          show-icon
          :closable="false"
          title="Загрузка файла"
          :type="uploadState.isLoading ? 'info' : uploadState.isError ? 'error' : 'success'")
            ul(v-if="uploadState.message.length > 1")
              li(v-for="(message, index) in uploadState.message" :key="index") {{ message }}
            span(v-else-if="uploadState.message.length === 1") {{ uploadState.message[0] }}

      el-upload.carantin-registry-upload(
        v-else
        :class="{ disabled: fileList.length }"
        drag
        :accept="selectedDocumentType.acceptedFileTypes || ''"
        :limit="1"
        :action="''"
        :file-list='fileList'
        :http-request="uploadFile"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :auto-upload="false"
      )
        i.el-icon-upload
        div.el-upload__text Перетащите файл сюда или нажмите
          em  загрузить

      div(slot="footer")
        div
          el-button(id="excel-upload-cancel-button" @click="closeUploadDialog")
            span {{ uploadState.result ? 'Закрыть' : 'Отмена' }}
          el-button(
            id="excel-upload-send-button"
            type="primary"
            :loading='uploadState.isLoading'
            :disabled='!fileList.length'
            @click="uploadFileHandler"
          )
            span {{ uploadState.result ? 'Отправить другой файл' : 'Отправить'}}
</template>
<script>
// import endpoints from '@/api/endpoints'
import uploadFile from '@/api/apiCalls/file/uploadFile'
import importOmsInstitutions from '@/api/apiCalls/file/importOmsInstitutions'

export default {
  data () {
    return {
      documentTypes: [
        {
          name: 'covid',
          dropDownTitle: 'Коронавирус',
          dialogTitle: 'Загрузка файла реестра заболевших',
          acceptedFileTypes: '.csv'
        },
        {
          name: 'oms',
          dropDownTitle: 'Поликлиника по ОМС',
          dialogTitle: 'Загрузка файла реестра поликлиник по ОМС',
          acceptedFileTypes: '.xls, .xlsx'
        }
      ],
      selectedDocumentType: {},
      isUploadDialogVisible: false,
      fileList: [],
      uploadState: {
        isLoading: false,
        result: false,
        message: [],
        isError: false
      }
    }
  },
  // computed: {
  //   uploadUrl () {
  //     if (this.documentTypes.name === 'webDav') {
  //       return endpoints.file.uploadFileWebDav
  //     }
  //     return endpoints.file.importOmsInstitutions
  //   }
  // },
  methods: {
    uploadFileHandler () {
      this.uploadState.result ? this.resetUploadState() : this.uploadFile()
    },

    uploadFile () {
      const file = this.fileList[0].raw

      this.setUploadingResultState({ isLoading: true, result: false, message: 'Идет загрузка файла...' })

      switch (this.selectedDocumentType.name) {
        case 'covid':
          this.uploadCovidRegistry(file)
          break
        case 'oms':
          this.uploadOMSRegistry(file)
          break
      }
    },

    async uploadCovidRegistry (file) {
      try {
        const { data } = await uploadFile(file)
        this.handleSuccess(data)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : 'При загрузке файла произошла ошибка.'
        this.handleError(errorMessage)
      }
    },

    async uploadOMSRegistry (file) {
      try {
        const { data } = await importOmsInstitutions(file)
        if (!data.errorRowNumbers) {
          this.handleSuccess({ result: true, message: `Загружено ${data.successRowCount} строк` })
        } else {
          throw data
        }
      } catch (error) {
        const errorMessage =
          error.errorRowNumbers
            ? JSON.stringify(error.errorRowNumbers, null, '\t')
            : 'При загрузке файла произошла ошибка.'
        this.handleError(errorMessage)
      }
    },

    handleSelectDocumentType (value) {
      this.selectedDocumentType = value
      this.openUploadDialog()
    },

    handleChange (file) {
      this.fileList.push(file)
    },

    handleSuccess ({ result, message }) {
      setTimeout(() => {
        this.isUploadingInProgress = false
        this.setUploadingResultState({ isLoading: false, result, message, isError: false })
      }, 800)
    },

    handleError (errorMessage) {
      setTimeout(() => {
        this.isUploadingInProgress = false
        this.setUploadingResultState({ isLoading: false, result: true, message: errorMessage, isError: true })
      }, 800)
    },

    handleRemove () {
      setTimeout(() => {
        this.fileList = []
      }, 1010)
    },

    setUploadingResultState ({ isLoading, result, message, isError }) {
      function capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }

      message = message.split(', ').map(messageItem => {
        const messageItemArray = messageItem.split(' ')
        messageItemArray[0] = capitalizeFirstLetter(messageItemArray[0])
        return messageItemArray.join(' ')
      })

      this.uploadState.isLoading = isLoading
      this.uploadState.result = result
      this.uploadState.message = message
      this.uploadState.isError = isError
    },

    resetUploadState () {
      this.setUploadingResultState({ isLoading: false, result: false, message: '', isError: false })
      this.fileList = []
    },

    openUploadDialog () {
      this.isUploadDialogVisible = true
    },

    closeUploadDialog () {
      this.isUploadDialogVisible = false
      this.fileList = []
      setTimeout(() => {
        this.resetUploadState()
      }, 500)
    }
  }
}
</script>
<style lang="sass">
  .carantin-registry-upload
    .el-upload
      width: 100%
      .el-upload-dragger
        width: 100%
    .el-upload-list__item
      .el-progress
        display: none

    &.disabled
      .el-upload
        display: none

  .carantin-registry-upload-result
    ul
      margin: 0
    li
      padding: 3px 0
      font-size: 14px
    &.error
      color: red
      background: #fde2e2
</style>
