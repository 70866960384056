import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Layout from '../views/Layout.vue'
import Login from '../views/Login.vue'
import Registry from '../views/Registry.vue'
import Request from '../views/Request.vue'
import Files from '../views/Files.vue'

Vue.use(VueRouter)

function ifAuthenticated (to, from, next) {
  if (store.getters['auth/isAuthenticated']) {
    next()
  } else {
    const query = to.params.pathMatch && to.params.pathMatch.length ? { file: to.params.pathMatch } : {}
    next({ name: 'login', query })
  }
}

function ifNotAuthenticated (to, from, next) {
  if (!store.getters['auth/isAuthenticated']) next()
  else next({ name: 'Registry' })
}

const routes = [
  {
    path: '*',
    redirect: '/registry'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/files/*',
    name: 'Files',
    component: Files,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/',
    name: 'layout',
    component: Layout,
    beforeEnter: ifAuthenticated,
    redirect: { name: 'Registry' },
    children: [
      {
        path: '/registry',
        name: 'Registry',
        component: Registry,
        meta: { translate: 'Список заявок' }
      },
      {
        path: '/request/:id',
        name: 'Request',
        component: Request,
        meta: { translate: 'Заявка' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.name) next({ name: 'Registry' })
  next()
})

export default router
