// import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.file.csvQueue.listenQueue

const checkFileLink = (link, date) => {
  // console.group('Check file link')
  // console.log('link', link)
  // console.log('date', date)
  // console.groupEnd()
  const array = link.split('/')[4].split('_')
  const indexOfFromDate = array.indexOf('from') + 1
  const indexOfToDate = array.indexOf('to') + 1
  const fileDateFromString = array[indexOfFromDate]
  const fileDateToString = array[indexOfToDate]

  const getFileDate = dateString => {
    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6) - 1
    const day = dateString.slice(6, 8)
    const hours = dateString.slice(8, 10)
    const minutes = dateString.slice(10, 12)
    return new Date(year, month, day, hours, minutes, 0)
  }

  const getFilterDate = dateString => {
    const [date, time] = [dateString.split(' ')[0], dateString.split(' ')[1]]
    const year = date.split('.')[2]
    const month = date.split('.')[1] - 1
    const day = date.split('.')[0]
    const hours = time.split(':')[0]
    const minutes = time.split(':')[1]
    return new Date(year, month, day, hours, minutes, 0)
  }

  const fileDateFrom = getFileDate(fileDateFromString).getTime()
  const fileDateTo = getFileDate(fileDateToString).getTime()

  const filterDateFrom = getFilterDate(date[0]).getTime()
  const filterDateTo = getFilterDate(date[1]).getTime()

  const isFromEqual = fileDateFrom === filterDateFrom
  const isToEqual = fileDateTo === filterDateTo

  // console.log('from', getFileDate(fileDateFromString), getFilterDate(date[0]), isFromEqual)
  // console.log('to', getFileDate(fileDateToString), getFilterDate(date[1]), isToEqual)

  return isFromEqual && isToEqual
}

const csvQueue = {
  eventSource: {},
  createConnection ({ date, isCsvFirstQueue }) {
    this.eventSource = new EventSource(url)
    return new Promise((resolve, reject) => {
      this.eventSource.onmessage = (event) => {
      // this.eventSource.onmessage = (event) => {
        // console.group('Csv queue message')
        // console.log('Queue', new Date())
        // console.log('Data', event.data)
        // console.groupEnd()
        const data = JSON.parse(event.data)
        // const data = { message: 'http://cc-dev.c-i-p.ru/downloads/202006101709_DZM_cao_from_202006101609_to_202006101709_a46c2e11253102422bbd2fad80e2d566_3min.zip' }
        if (data.message && checkFileLink(data.message, date)) {
          resolve({ link: data.message })
          this.closeConnection()
        }
      }

      this.eventSource.onopen = (event) => {
        // console.group('Csv queue is open')
        // console.log('Open', new Date())
        // console.log('Data', event.data)
        // console.groupEnd()
      }

      this.eventSource.onerror = (error) => {
        if (isCsvFirstQueue) return
        reject(error)
        this.closeConnection()
      }
    })
  },
  closeConnection () {
    this.eventSource.close && this.eventSource.close()
    this.eventSource = {}
  }
}

export default csvQueue
