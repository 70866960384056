<template lang='pug'>
  box-card.services-box-card
    template(slot="header")
      span Услуги
      div(v-if='isEditServicesAvailable')
        div(v-show="!editingMode")
          el-button.el-button--text_icon(id="services-edit-mode-button" type='text' v-show="!editingMode" @click='changeEditingMode')
            font-awesome-icon(icon="pencil-alt")
            span Редактировать

        div(v-show="editingMode"  :key="101")
          el-button.el-button--text_icon.cancel-button(id="services-edit-mode-cancel-button" type='text' @click='cancelEditingMode')
            font-awesome-icon(icon="times")
            span Отмена
          el-button.el-button--text_icon.accept-button(
            id="services-save-button"
            type='text'
            :loading="isServicesSaveLoading"
            @click="saveServicesOnSaveButtonClick"
          )
            font-awesome-icon(icon="check")
            span Сохранить

    template(slot="content")
      el-form.custom-form
          div.parent-service-item( v-for="service in computedServicesTypesOptions" :key="service.id" v-if="!service.serviceTypeParentId")
            font-awesome-icon(icon="check")
            span {{service.name}}
            el-checkbox-group.custom-checkbox-group(id="child-services-checkbox-group" style="margin: 18px" v-model="selectedServiceIds" :disabled="!editingMode")
              el-checkbox.custom-checkbox(v-for="child in filterChildrenByParentId(service)" :key="child.id" :disabled="!!serviceTypesRowSelected(child.id)" :label="child.id" :id="`id-${child.id}`") {{ child.name }}

</template>

<script>
import BoxCard from '@/components/BoxCard.vue'
import fetchServiceTypes from '@/api/apiCalls/references/fetchServiceTypes'
import findServicesByApplicationId from '@/api/apiCalls/application/findServicesByApplicationId'
import createServiceInApplication from '@/api/apiCalls/application/createServiceInApplication'
import { mapState } from 'vuex'

export default {
  name: 'RequestServices',
  components: {
    BoxCard
  },
  props: {
    services: {
      type: Array,
      default: () => []
    },
    applicationId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      editingMode: false,
      selectedServiceIds: [],
      serviceTypesOptions: [],
      isServicesSaveLoading: false
    }
  },
  mounted () {
    this.selectedServiceIds = this.services.filter(e => e.serviceTypeParentId).map(e => e.serviceTypeId)
  },
  computed: {

    ...mapState('auth', {
      userAbility: state => state.userAbility
    }),

    /*
      Список услуг
     */
    computedServicesTypesOptions () {
      return this.services
    },

    // computedServiceChildOptions () {
    //   return (
    //     this.serviceTypesOptions
    //       .filter((n) =>
    //         this.computedServicesTypesOptions
    //           .some((n2) => n2.serviceTypeId === n.parentId))
    //   )
    // },

    /**
     * Можно ли редактирвать раздел в зависимости от прав
     * @return {*}
     */
    isEditServicesAvailable () {
      return this.userAbility.can('edit', 'application')
    }
  },

  watch: {
    'selectedServiceIds' (value) {
      // console.log(value)
    }
  },

  created () {
    this.fetchServiceTypes()
  },
  methods: {

    serviceTypesRowSelected  (id) {
      return !!this.services.find(item => item.serviceTypeId === id)
    },

    async fetchServiceTypes () {
      this.serviceTypesOptions = await fetchServiceTypes()
    },

    filterChildrenByParentId (item) {
      return this.serviceTypesOptions.filter((n) => n.parentId === item.serviceTypeId)
    },

    changeEditingMode () {
      this.editingMode = !this.editingMode
    },

    cancelEditingMode () {
      this.editingMode = !this.editingMode
      findServicesByApplicationId(this.applicationId).then((r) => {
        this.services = r.content
      })
    },

    saveServicesOnSaveButtonClick () {
      const requests = []
      const selectedServices =
        this.serviceTypesOptions
          .filter((e) => this.selectedServiceIds
            .some((e1) => e.id === e1))

      selectedServices.forEach((e) => {
        requests.push(createServiceInApplication({
          applicationId: this.applicationId,
          serviceStatusId: 0,
          serviceTypeId: e.id,
          serviceTypeParentId: e.parentId
        }))
      })

      Promise.all(requests).finally((res) => {
        this.cancelEditingMode()
        findServicesByApplicationId(this.applicationId).then((r) => {
          this.services = r.content
        })
      })
    }
  }
}
</script>
<style lang="sass">
  .parent-service-item
    font-size: 14px
    color: #606266
    svg
      font-size: 12px
      margin-right: 8px
  .services-box-card
    .custom-checkbox-group
      .el-checkbox.custom-checkbox
        margin-bottom: 15px
      .el-checkbox.custom-checkbox, .el-checkbox__label
        display: flex
        flex-wrap: nowrap
      .el-checkbox__input
        margin-top: 3px
      .el-input
        margin-top: 5px
        width: 100%
      .el-checkbox__label
        white-space: break-spaces
        min-width: inherit
</style>
