import storageConfig from '@/config/storageConfig'
import fetchApplicationStatuses from '@/api/apiCalls/references/fetchApplicationStatuses'
import fetchApplicationStatusesByUserRole from '@/api/apiCalls/references/fetchApplicationStatusesByUserRole'
import fetchApplicationTypes from '@/api/apiCalls/references/fetchApplicationTypes'
import fetchRefAreas from '@/api/apiCalls/references/fetchRefAreas'
import fetchRefDistricts from '@/api/apiCalls/references/fetchRefDistricts'
import fetchApplicationNextStatuses from '@/api/apiCalls/references/fetchApplicationNextStatuses'

const applicationStatusesStorageName = 'application-statuses-options'
const applicationStatusesByUserRoleStorageName = 'application-statuses-options-by-user-role'
const applicationTypesStorageName = 'application-types-options'
const refDistrictsStorageName = 'ref-districts'
const refAreasStorageName = 'ref-areas'

const state = {
  applicationStatuses: JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${applicationStatusesStorageName}`)) || [],
  applicationStatusesByUserRole: JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${applicationStatusesByUserRoleStorageName}`)) || [],
  applicationTypes: JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${applicationTypesStorageName}`)) || [],
  refAreas: JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${refAreasStorageName}`)) || [],
  refDistricts: JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${refDistrictsStorageName}`)) || [],
  applicationNextStatuses: []
}

const mutations = {
  setApplicationStatuses: (state, value) => {
    state.applicationStatuses = value
  },
  setApplicationStatusesByUserRole: (state, value) => {
    state.applicationStatusesByUserRole = value
  },
  setApplicationTypes: (state, value) => {
    state.applicationTypes = value
  },
  setRefAreas: (state, value) => {
    state.refAreas = value
  },
  setRefDistricts: (state, value) => {
    state.refDistricts = value
  },
  setApplicationNextStatuses: (state, value) => {
    state.applicationNextStatuses = value
  }
}

const actions = {
  async fetchApplicationStatuses ({ commit }) {
    const data = await fetchApplicationStatuses()
    commit('setApplicationStatuses', data)
  },
  async fetchApplicationStatusesByUserRole ({ commit }) {
    const data = await fetchApplicationStatusesByUserRole()
    commit('setApplicationStatusesByUserRole', data)
  },
  async fetchApplicationTypes ({ commit }) {
    const data = await fetchApplicationTypes()
    commit('setApplicationTypes', data)
  },
  async fetchRefAreas ({ commit }) {
    const data = await fetchRefAreas()
    commit('setRefAreas', data)
  },
  async fetchRefDistricts ({ commit }) {
    const data = await fetchRefDistricts()
    commit('setRefDistricts', data)
  },
  async fetchApplicationNextStatuses ({ commit }, applicationId) {
    const data = await fetchApplicationNextStatuses(applicationId)
    commit('setApplicationNextStatuses', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
  // getters
}
