import { AbilityBuilder, Ability } from '@casl/ability'
import rules from './rules/userRules'

const defineRules = (userRulesArray) => {
  const { can, cannot, rules } = AbilityBuilder.extract()

  userRulesArray && Object.keys(userRulesArray).forEach((key) => {
    if (key === 'can') {
      userRulesArray[key].forEach((rule) => {
        can(rule.action, rule.subject)
      })
    } else if (key === 'cannot') {
      userRulesArray[key].forEach((rule) => {
        cannot(rule.action, rule.subject)
      })
    }
  })
  return new Ability(rules)
}

const getUserAbility = (currentUserRole) => {
  const ability = defineRules(rules[currentUserRole])
  return ability
}

export default getUserAbility
