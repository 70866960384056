<template lang="pug">
  el-dialog.history-dialog(
    :visible.sync='dialogIsVisible'
    @closed='$emit("historyDialogWasClosed")'
    @open='dialogWasOpened'
    title='История изменения заявки'
  )

    el-checkbox-group.mb-10(v-model='filterData' size='medium')
      el-checkbox-button(label='application' @change='disableAllFilter') Изменения данных по заявке
      el-checkbox-button(label='citizen' @change='disableAllFilter') Изменения данных по получателю
      el-checkbox-button(label='application.applicationStatusId' @change='disableAllFilter') Изменения статусов заявки

    el-checkbox-group.mb-10(v-model='filterData' size='small')
      el-checkbox-button.mb-10(label='all' size='small' @change='enableAllFilter') Все изменения

    div.mb-20

    el-pagination.mb-10.flex.justify-start(
      layout="prev, pager, next, slot"
      :page-size='paginationData.size'
      :current-page.sync='paginationData.currentPage'
      :total="paginationData.totalItems"
      @current-change='changeTablePage'
    )
      span(style='font-weight: 500') Найдено изменений: {{ paginationData.totalItems }}

    el-table.mb-10(
      :data='tableData'
    )
      el-table-column(
        width='140'
        prop='changeTs'
        label='Дата изменения'
      )
      el-table-column(
        width='230'
        prop='columnName'
        label='Поле'
      )
      el-table-column(
        prop='oldValue'
        label='Старое значение'
      )
      el-table-column(
        prop='newValue'
        label='Новое значение'
      )
      el-table-column(
        prop='roleOrg'
        label='Роль и организация'
      )
      el-table-column(
        prop='userName'
        label='Пользователь'
      )

</template>
<script>
// import { mapState, mapMutations, mapActions } from 'vuex'
import fetchHistory from '@/api/apiCalls/application/fetchHistory'

// const filterDataOptions = ['application', 'citizen']

export default {
  name: 'HistoryDialog',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    applicationId: {
      type: Number,
      default: 0
    },
    recieverId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tableData: [],
      filterData: [],
      moreFilterData: [],
      paginationData: {
        totalItems: 0,
        currentPage: 1,
        size: 10
      }
    }
  },
  created () {},
  computed: {
    dialogIsVisible: {
      get () {
        return this.isVisible
      },
      set (value) {
        this.$emit('update:is-visible', value)
      }
    },
    filterParams () {
      const params = {
        columnNames: []
      }

      const filterData = this.filterData
      const applicationColumnNames = [
        'application.userExecutorId',
        'application.isCarantin',
        'application.comment',
        'application.applicationStatusComment',
        'application.execDate'
      ]

      const citizenColumnNames = [
        'citizen.surname',
        'citizen.name',
        'citizen.patronymic',
        'citizen.birthday',
        'citizen.phone',
        'citizen.address',
        'citizen.districtId',
        'citizen.areaId',
        'citizen.residenceAddress',
        'citizen.residenceDistrictId',
        'citizen.residenceAreaId',
        'citizen.isMoscowRegion',
        'citizen.passportSeries',
        'citizen.passportNumber',
        'citizen.passportDate',
        'citizen.isCarantin',
        'citizen.carantinStatusId'
      ]

      if (filterData.includes('all')) {
        params.applicationId = this.applicationId
        params.receiverCitizenId = this.recieverId
        params.columnNames = [...citizenColumnNames, ...applicationColumnNames, 'application.applicationStatusId']
      } else {
        if (filterData.includes('application')) {
          params.applicationId = this.applicationId
          params.columnNames = [...params.columnNames, ...applicationColumnNames]
        }
        if (filterData.includes('citizen')) {
          params.receiverCitizenId = this.recieverId
          params.columnNames = [...params.columnNames, ...citizenColumnNames]
        }

        if (filterData.includes('application.applicationStatusId')) {
          params.applicationId = this.applicationId
          params.columnNames = [...params.columnNames, 'application.applicationStatusId']
        }
      }

      return params
    }
  },
  methods: {
    async fetchHistory (params, paginationParams) {
      const { content, totalElements } = await fetchHistory(params, paginationParams)
      this.tableData = content
      this.paginationData.totalItems = totalElements
    },
    dialogWasOpened () {
      this.enableAllFilter(true)
    },
    changeTablePage (page) {
      this.paginationData.currentPage = page

      this.fetchHistory(this.filterParams, this.paginationData)
    },
    enableAllFilter (value) {
      if (value) this.filterData = ['all']
      else this.filterData = []

      this.changeTablePage(1)
    },
    enableMoreFilter (value) {
      this.filterData = this.filterData.filter(item => item !== 'all' && item !== 'application')
      this.changeTablePage(1)
    },
    disableAllFilter (value) {
      if (value) this.filterData = this.filterData.filter(item => item !== 'all')
      else this.moreFilterData = []
      this.changeTablePage(1)
    }
  }
}
</script>
<style lang="sass">
.el-dialog
  min-width: 1200px

  .el-table__header, .el-table__body
    font-size: 13px
    white-space: pre-wrap !important
</style>
