import router from '@/router'
import fetchUsersRoles from '@/api/apiCalls/user/fetchUsersRoles'
import fetchUserDataProfile from '@/api/apiCalls/user/fetchUserDataProfile'
import getUserAbility from '@/config/userRoles/getUserAbility'
import logout from '@/api/apiCalls/auth/logout'

function getBasicToken ({ login, password }) {
  return `Basic ${btoa(`${login}:${password}`)}`
}

const state = {
  user: JSON.parse(localStorage.getItem('user')) || {},
  accessToken: localStorage.getItem('accessToken') || '',
  rolesList: [],
  userAbility: null
}

const getters = {
  isAuthenticated: state => !!state.accessToken,
  currentUserRole: state => {
    return state.user.role &&
    state.rolesList.length &&
    state.rolesList.find(role => role.id === state.user.role).name
  }
}

const mutations = {
  setBasicToken (state, basicToken) {
    state.accessToken = basicToken
    localStorage.setItem('accessToken', basicToken)
  },
  removeBasicToken (state) {
    state.accessToken = ''
    localStorage.removeItem('accessToken')
  },
  setUser (state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  removeUser (state) {
    state.user = ''
    localStorage.removeItem('user')
  },
  removeUserAbility (state) {
    state.userAbility = null
    localStorage.removeItem('userAbility')
  },
  setUsersRolesList (state, rolesList) {
    state.rolesList = rolesList
  },
  setUserAbility (state, userAbility) {
    state.userAbility = userAbility
  }
}

const actions = {
  async authLogin ({ commit, dispatch }, { login, password }) {
    commit('setBasicToken', getBasicToken({ login, password }))
    try {
      const user = await fetchUserDataProfile()
      await dispatch('fetchUsersRoles')
      await dispatch('authSuccess', user)
      dispatch('setUserAbility')
      return true
      // router.replace({ name: 'Registry' })
    } catch (error) {
      dispatch('authLogout', false)
      throw error
    }
  },

  async fetchUsersRoles ({ commit, dispatch }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const rolesList = await fetchUsersRoles()
      commit('setUsersRolesList', rolesList)
    } catch (error) {
      throw error
    }
  },

  async authSuccess ({ commit }, user) {
    commit('setUser', user)
  },

  authLogout ({ commit, dispatch, rootState }, isRedirect = true) {
    commit('removeBasicToken')
    commit('removeUser')
    commit('removeUserAbility')
    commit('csvQueues/setCsvFirstQueue', true, { root: true })
    dispatch('clearSessionStorage')
    if (isRedirect) router.replace({ name: 'login' })
  },

  authLogoutOnServer () {
    logout()
  },

  setUserAbility ({ commit, getters }) {
    const userAbility = getUserAbility(getters.currentUserRole)
    commit('setUserAbility', userAbility)
  },

  clearSessionStorage () {
    sessionStorage.clear()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
