import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.file.csvQueue.makeQueue

const makeCsvQueue = async ({ dateRangeArray }, columns) => {
  if (dateRangeArray.length < 2) return

  const data = {
    endDate: dateRangeArray[1].split(' ').join(' '),
    startDate: dateRangeArray[0].split(' ').join(' '),
    columns
  }

  return await axios({
    method: 'POST',
    url,
    data
  })
}

export default makeCsvQueue
