<template lang='pug'>
  box-card

    template(slot="header")
      span Информация о получателе
      div(v-if='isEditRecieverAvailable')
        div(v-show="!editingMode")
          el-button.el-button--text_icon(id="reciever-edit-mode-button" type='text' v-show="!editingMode" @click='changeEditingMode')
            font-awesome-icon(icon="pencil-alt")
            span Редактировать

        div(v-show="editingMode"  :key="101")
          el-button.el-button--text_icon.cancel-button(id="reciever-edit-mode-cancel-button" type='text' @click='cancelEditingMode')
            font-awesome-icon(icon="times")
            span Отмена
          el-button.el-button--text_icon.accept-button(
            id="reciever-save-button"
            type='text'
            :loading="isRecieverSaveLoading"
            @click="saveRecieverOnSaveButtonClick"
          )
            font-awesome-icon(icon="check")
            span Сохранить

    template(slot="content")
      el-form.custom-form.reciever-form(
        :disabled='!editingMode'
        :model="currentReciever"
        :rules="formValidationRules"
        ref="reciever-form"
      )
        el-row(:gutter='20')
          el-col(:span='12')
            el-form-item(label='Фамилия' prop="surname")
              el-input(id="reciever-surname-input" v-model='computedRecieverSurname')
          el-col(:span="12")
            el-form-item(label='Имя' prop="name")
              el-input(id="reciever-name-input" v-model='computedRecieverName')

        el-row(:gutter='20')
          el-col(:span='12')
            el-form-item(label='Отчество' prop="patronymic")
              el-input(id="reciever-patronymic-input" v-model='computedRecieverPatronymic')
          el-col(:span='12')
            el-form-item(label='Мобильный телефон' prop="phone")
              el-input(id="reciever-phone-input" v-model="currentReciever.phone" v-mask="phoneMask" ref="phone-field")
          el-col
            el-form-item(label='')
              el-checkbox(id="reciever-is-dead-checkbox" v-model='computedRecieverRecieverIsDead') Получатель умер
        el-row(:gutter='20')
          el-col(:span='12')
            el-form-item(label='Дата рождения' )
              el-date-picker(
                id="reciever-birthday-input"
                popper-class="reciever-birthday-picker"
                v-model="currentRecieverLocal.birthday"
                type="date"
                format="dd.MM.yyyy"
                value-format="dd.MM.yyyy"
                v-mask='"99.99.9999"'
              )
          el-col(:span='12')
            el-form-item(label='Возраст' )
              el-input(id="reciever-age-input" v-model='age' disabled)

        el-row(:gutter="20")
          el-col(:span="addressSelectManualMode ? 24 : 20")
            address-picker(
              id="registered-address-picker"
              :label="computedAddressLabel"
              :address-text.sync="currentRecieverLocal.address"
              @selectAddress="selectAddress"
              :searchDisabled="addressSelectManualMode"
              validate-prop="address"
              :only-moscow='true'
            )

          el-col(:span="4" v-show="!addressSelectManualMode")
            el-form-item(label="Квартира")
              el-input.apartment-input(
                id="residence-apartment-input"
                v-model="currentRecieverLocal.apartment"
              )

        el-row.flex(:gutter="20" style="align-items: center;")
          el-col(:span="8")
            el-form-item(label="Округ регистрации" prop="districtId")
              el-select(
                id="registered-district-select"
                v-model='computedDistrictId'
                :disabled="!addressSelectManualMode"
                placeholder="Выберите округ"
                filterable
                clearable
              )
                el-option(
                  v-for="district in computedFoundDistricts(false)"
                  :key="district.id"
                  :label="district.name"
                  :value="district.id"
                ) {{ district.name }}
          el-col(:span='12')
            el-form-item(label='Район регистрации' prop='areaId')
              el-select(
                id="registered-area-select"
                v-model='currentRecieverLocal.areaId'
                :disabled="!addressSelectManualMode"
                filterable
                clearable
              )
                el-option(
                  v-for="area in computedAreasOptions"
                  :key="area.id"
                  :label="area.name"
                  :value="area.id"
                  ) {{ area.name }}
          el-col.manual-picker-checkbox(:span="4")
           el-form-item(style="padding-top: 40px;")
            el-checkbox(id="registerd-address-edit-mode-checkbox" v-model='addressSelectManualMode') Вручную

        el-row(:gutter='20')
          el-col(:span='8')
            el-form-item(label="Есть в реестре")
              el-select(id="carantin-status-select" v-model='currentRecieverLocal.isCarantin' disabled)
                el-option(
                  v-for="option in booleanOptions"
                  :key="option.name"
                  :label="option.name"
                  :value="option.id"
                  ) {{ option.name }}
                    el-col(:span='12')
        el-row(:gutter='20')
          el-col(:span="residenceAddressSelectManualMode ? 24 : 20")
            address-picker(
              id="residence-address-picker"
              :label="computedAddressLabel"
              :address-text.sync="currentRecieverLocal.residenceAddress"
              @selectAddress="selectResidenceAddress"
              :searchDisabled="residenceAddressSelectManualMode"
              validate-prop="residenceAddress"
            )

          el-col(:span="4" v-show="!residenceAddressSelectManualMode")
            el-form-item(label="Квартира")
              el-input.apartment-input(
                id="residence-apartment-input"
                v-model="currentRecieverLocal.residenceApartment"
              )

        el-row.flex(:gutter="20" style="align-items: center;")
          el-col(:span='8')
            el-form-item(label='Округ проживания' prop='residenceDistrictId')
              el-select(
                id="residence-district-select"
                v-model='computedResidenceDistrictId'
                :disabled="isResidenceDistrictDisabled || !residenceAddressSelectManualMode"
                filterable
                clearable
              )
                el-option(
                  v-for="district in computedFoundDistricts(true)"
                  :key="district.id"
                  :label="district.name"
                  :value="district.id"
                  ) {{ district.name }}
          el-col(:span='12')
            el-form-item(label='Район проживания' prop='residenceAreaId')
              el-select(
                id="residence-area-select"
                v-model='currentRecieverLocal.residenceAreaId'
                :disabled="isResidenceAreaDisabled || !residenceAddressSelectManualMode"
                filterable
                clearable
              )
                el-option(
                  v-for="area in computedResidenceAreasOptions"
                  :key="area.id"
                  :label="area.name"
                  :value="area.id"
                  ) {{ area.name }}
          el-col.manual-picker-checkbox(:span="4")
            el-form-item(style="padding-top: 40px;")
              el-checkbox(id="residence-address-edit-mode-checkbox" v-model='residenceAddressSelectManualMode') Вручную

        el-row(:gutter='20')
          el-col(:span='8')
            el-form-item(label='Серия паспорта' )
              el-input(id="passport-series-input" v-model='currentRecieverLocal.passportSeries' v-mask="'99 99'")
          el-col(:span='8')
            el-form-item(label='Номер паспорта' )
              el-input(id="passport-number-input" v-model='currentRecieverLocal.passportNumber' v-mask="'999999'")
          el-col(:span='8')
            el-form-item(label='Дата выдачи' )
              el-date-picker.passport-date(
                id="passport-number-input"
                popper-class="passport-number-picker"
                v-model="currentRecieverLocal.passportDate"
                type="date"
                format="dd.MM.yyyy"
                value-format="dd.MM.yyyy"
                v-mask='"99.99.9999"'
              )

</template>

<script>
import BoxCard from '@/components/BoxCard'
import addressPicker from '@/components/addressPicker'
import Inputmask from 'inputmask'
import getAge from '@/helpers/getAge'
import { mapState, mapActions, mapMutations } from 'vuex'
import applicationStatusesConstants from '@/config/applicationStatuses/applicationStatusesConstants.js'
// import applicationTypesConstants from '@/config/applicationTypes/applicationTypesConstants.js'

const phoneMask = '+9 (999) 999-99-99'

export default {
  name: 'RequestRecieverInfo',
  components: {
    BoxCard,
    addressPicker
  },
  props: {
    application: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      editingMode: false,
      currentRecieverLocal: {},
      booleanOptions: [{ id: true, name: 'Да' }, { id: false, name: 'Нет' }],
      isRecieverSaveLoading: false,
      addressSelectManualMode: false,
      residenceAddressSelectManualMode: false
    }
  },
  watch: {
    currentRecieverLocal: {
      handler (value) {
        this.setReciever(Object.assign(value, {
          name: this.computedRecieverName,
          surname: this.computedRecieverSurname,
          patronymic: this.computedRecieverPatronymic,
          isDead: this.computedRecieverRecieverIsDead
        }))
      },
      deep: true
    },
    currentReciever: {
      handler (value) {
        this.currentRecieverLocal = value
      },
      deep: true
    },
    addressSelectManualMode (value) {
      const apartmentMatchExpression = ', кв.'

      if (value) {
        if (!this.currentRecieverLocal.address.includes(apartmentMatchExpression) &&
        this.currentRecieverLocal.apartment &&
          this.currentRecieverLocal.apartment.length) {
          this.currentRecieverLocal.address += `${apartmentMatchExpression} ${this.currentRecieverLocal.apartment}`
        }
        this.currentRecieverLocal.fiasGuid = ''
        this.currentRecieverLocal.apartment = ''
      } else {
        this.currentRecieverLocal.address = ''
        this.currentRecieverLocal.areaId = ''
        this.currentRecieverLocal.districtId = ''
      }
    },
    residenceAddressSelectManualMode (value) {
      const apartmentMatchExpression = ', кв.'

      if (value) {
        if (!this.currentRecieverLocal.residenceAddress.includes(apartmentMatchExpression) &&
          this.currentRecieverLocal.residenceApartment &&
          this.currentRecieverLocal.residenceApartment.length) {
          this.currentRecieverLocal.residenceAddress += `${apartmentMatchExpression} ${this.currentRecieverLocal.residenceApartment}`
        }
        this.currentRecieverLocal.residenceFiasGuid = ''
        this.currentRecieverLocal.residenceApartment = ''
      } else {
        this.currentRecieverLocal.residenceAddress = ''
        this.currentRecieverLocal.residenceAreaId = ''
        this.currentRecieverLocal.residenceDistrictId = ''
      }
    }
  },
  computed: {

    ...mapState('reciever', {
      currentReciever: state => state.currentReciever,
      defaultReciever: state => JSON.parse(state.defaultReciever)
    }),

    ...mapState('auth', {
      userAbility: state => state.userAbility
    }),

    ...mapState('references', {
      areasOptions: state => state.refAreas.sort((prev, next) => prev.name > next.name),
      districtsOptions: state => state.refDistricts,
      applicationStatuses: state => state.applicationStatuses
    }),

    computedFoundDistricts () {
      return (residence = false) => {
        if (!residence) {
          return this.districtsOptions.filter(i => i.name !== 'МО')
        }
        return this.districtsOptions
      }
    },

    computedRecieverName: {
      set (value) {
        this.currentRecieverLocal.name = value
      },
      get () {
        let name = this.currentRecieverLocal.name

        if (name) name = this.nameBuilder(name)
        return this.editingMode ? name : this.currentRecieverLocal.name
      }
    },

    computedRecieverSurname: {
      set (value) {
        this.currentRecieverLocal.surname = value
      },
      get () {
        let surname = this.currentRecieverLocal.surname

        if (surname) surname = this.nameBuilder(surname)
        return this.editingMode ? surname : this.currentRecieverLocal.surname
      }
    },

    computedRecieverPatronymic: {
      set (value) {
        this.currentRecieverLocal.patronymic = value
      },
      get () {
        let patronymic = this.currentRecieverLocal.patronymic

        if (patronymic) patronymic = this.nameBuilder(patronymic)
        return this.editingMode ? patronymic : this.currentRecieverLocal.patronymic
      }
    },

    computedRecieverRecieverIsDead: {
      set (value) {
        this.currentRecieverLocal.isDead = value
      },
      get () {
        const isDead = this.currentRecieverLocal.isDead

        return this.editingMode ? isDead : this.currentRecieverLocal.isDead
      }
    },

    isEditRecieverAvailable () {
      // Проверяем, получен ли объект получателя
      if (Object.keys(this.currentReciever).length) {
        // // Если тип заявки "ОСО" и признак "Есть в реестре" в положении "Да"
        // if (this.application.applicationTypeId === applicationTypesConstants.OSO && this.application.isCarantin) {
        //   return (
        //     this.userAbility.can('edit', 'osoTypeReciever') &&
        //     this.userAbility.can('edit', 'onCarantinReciever')
        //   )
        // } else {
        //   // Любой другой тип заявки и статус завки "Направлена в ЦСО"
        //   if (this.application.applicationStatusId === applicationStatusesConstants.DIRECTED_TO_CSO) {
        //     return this.userAbility.can('editOnDirectedToCso', 'reciever')
        //   } else {
        //     // Любой тип заявки и любой статус заявки
        //     return this.userAbility.can('edit', 'reciever')
        //   }
        // }

        return this.userAbility.can('edit', 'application')
      } else {
        return false
      }

      // // Проверяем, получен ли объект получателя
      // return process.env.NODE_ENV === 'development' ? true : Object.keys(this.currentReciever).length
      //   // Если тип заявки "ОСО" и признак "Есть в реестре" в положении "Да"
      //   ? this.application.applicationTypeId === applicationTypesConstants.OSO && this.application.isCarantin
      //     ? this.userAbility.can('edit', 'osoTypeReciever') &&
      //       this.userAbility.can('edit', 'onCarantinReciever')
      //     // Любой другой тип заявки и статус завки "Направлена в ЦСО"
      //     : this.application.applicationStatusId === applicationStatusesConstants.DIRECTED_TO_CSO
      //       ? this.userAbility.can('editOnDirectedToCso', 'reciever')
      //       // Любой тип заявки и любой статус заявки
      //       : this.userAbility.can('edit', 'reciever')
      //   : false
    },

    phoneMask () {
      return phoneMask
    },

    age () {
      return this.currentRecieverLocal.birthday ? getAge(this.currentRecieverLocal.birthday) : 0
    },

    computedAreasOptions () {
      return this.areasOptions.filter(area => area.districtId === this.currentRecieverLocal.districtId)
    },

    computedResidenceAreasOptions () {
      return this.areasOptions.filter(area => area.districtId === this.currentRecieverLocal.residenceDistrictId)
    },

    computedDistrictId: {
      get () {
        return this.currentRecieverLocal.districtId
      },
      set (value) {
        this.currentRecieverLocal.districtId = value
        this.currentRecieverLocal.areaId = null
      }
    },

    computedResidenceDistrictId: {
      get () {
        return this.currentRecieverLocal.residenceDistrictId
      },
      set (value) {
        this.currentRecieverLocal.residenceDistrictId = value
        this.currentRecieverLocal.residenceAreaId = null
      }
    },

    computedAddressLabel () {
      if (this.addressSelectManualMode) return 'Адрес регистрации (Москва, улица, дом, корпус/строение, квартира)'
      else return 'Адрес регистрации (Москва, улица, дом, корпус/строение)'
    },

    formValidationRules () {
      return {
        name: [
          { required: true, message: 'Имя не может быть пустым', trigger: ['blur', 'change'] },
          { validator: this.checkSymbols, message: 'Имя не может содержать специальные символы', trigger: ['blur', 'change'] },
          { validator: this.onlySpaces, message: 'Имя не может содержать только пробелы', trigger: ['blur', 'change'] },
          { validator: this.onlyCirillic, message: 'Имя не может содержать латинские символы', trigger: ['blur', 'change'] }
        ],
        surname: [
          { message: 'Фамилия не может быть пустой', trigger: ['blur', 'change'] },
          { validator: this.checkSymbols, message: 'Фамилия не может содержать специальные символы', trigger: ['blur', 'change'] },
          { validator: this.onlySpaces, message: 'Фамилия не может содержать только пробелы', trigger: ['blur', 'change'] },
          { validator: this.onlyCirillic, message: 'Имя не может содержать латинские символы', trigger: ['blur', 'change'] }
        ],
        patronymic: [
          { validator: this.checkSymbols, message: 'Отчество не может содержать специальные символы', trigger: ['blur', 'change'] },
          { validator: this.onlyCirillic, message: 'Имя не может содержать латинские символы', trigger: ['blur', 'change'] }
        ],
        // phone: [
        //   { message: 'Телеофон не может быть пустым', trigger: ['blur', 'change'] },
        //   { validator: this.checkPhone, message: 'Телефон не может быть короче одиннацати (11) цифр', trigger: ['blur', 'change'] }
        // ],
        // areaId: { validator: this.areaValidator, trigger: ['blur', 'change'] },
        // residenceAreaId: { validator: this.residenceAreaValidator, trigger: ['blur', 'change'] },
        // districtId: { validator: this.districtValidator, trigger: ['blur', 'change'] },
        // residenceDistrictId: { validator: this.residenceDistrictValidator, trigger: ['blur', 'change'] },
        address: [
          { message: 'Адрес должен быть заполнен', trigger: ['blur', 'change'] },
          { validator: this.onlySpaces, trigger: ['blur', 'change'] }
        ],
        residenceAddress: [
          { message: 'Адрес должен быть заполнен', trigger: ['blur', 'change'] },
          { validator: this.onlySpaces, trigger: ['blur', 'change'] }
        ]
      }
    },

    isResidenceDistrictDisabled () {
      // Изменили условия выключения поля. Теперь это не зависит от роли
      // return this.userAbility.can('edit', 'residenceDistrict') ? false : this.currentRecieverLocal.isMoscowRegion
      return this.currentRecieverLocal.isMoscowRegion
    },

    isResidenceAreaDisabled () {
      // Изменили условия выключения поля. Теперь это не зависит от роли
      // return this.userAbility.can('edit', 'residenceArea') ? false : this.currentRecieverLocal.isMoscowRegion
      return this.currentRecieverLocal.isMoscowRegion
    }
  },
  created () {
    if (this.application.citizenRecieverId) this.fetchReciever()
    this.fetchRefDistricts()
    this.fetchRefAreas()
    this.fetchApplicationStatuses()
  },
  methods: {
    ...mapActions('reciever', {
      fetchRecieverAction: 'fetchReciever',
      saveRecieverApiCall: 'saveRecieverApiCall',
      loadDefaultRecieverAction: 'loadDefaultReciever',
      checkAndChangeRoutingAction: 'checkAndChangeRouting',
      checkRecieverAction: 'checkReciever'
    }),

    ...mapActions('references', {
      fetchRefAreas: 'fetchRefAreas',
      fetchRefDistricts: 'fetchRefDistricts',
      fetchApplicationStatuses: 'fetchApplicationStatuses',
      fetchApplicationNextStatuses: 'fetchApplicationNextStatuses'
    }),

    ...mapMutations('reciever', {
      setReciever: 'setReciever'
    }),

    nameBuilder (name) {
      // const name = this[reference][prop]
      const upperCase = str => str && str[0].toUpperCase() + str.slice(1).toLowerCase()
      return name.split('-').map(str => upperCase(str).split(' ').map(str => upperCase(str)).join(' ')).join('-')
    },

    changeEditingMode () {
      this.editingMode = !this.editingMode

      if (!this.editingMode) {
        // Сброс в исходное состояние.
        this.currentRecieverLocal = Object.assign({}, { ...this.defaultReciever })
      }
    },

    cancelEditingMode () {
      this.editingMode = false
      this.clearValidate()
      this.loadDefaultRecieverAction()
    },

    selectAddress (value) {
      const { address, areaId, districtId, fiasGuid } = value
      this.computedDistrictId = districtId
      this.currentRecieverLocal.fiasGuid = fiasGuid
      this.currentRecieverLocal.address = address
      this.currentRecieverLocal.areaId = areaId
    },

    selectResidenceAddress (value) {
      const { address, areaId, districtId, fiasGuid } = value
      this.computedResidenceDistrictId = districtId
      this.currentRecieverLocal.residenceFiasGuid = fiasGuid
      this.currentRecieverLocal.residenceAddress = address
      this.currentRecieverLocal.residenceAreaId = areaId
    },

    async fetchReciever () {
      await this.fetchRecieverAction(this.application.citizenRecieverId)
      this.currentRecieverLocal = JSON.parse(JSON.stringify(this.currentReciever))
      this.setInitialAddressSelectManualMode()
      this.setInitialAddressState()
      this.setInitialResidenceAddressSelectManualMode()
      this.setInitialResidenceAddressState()
      this.fetchApplicationNextStatuses(this.application.id)
    },

    setInitialAddressState () {
      const apartmentMatchExpression = ', кв.'
      const { address } = this.currentRecieverLocal

      this.currentRecieverLocal.address = !this.addressSelectManualMode
        ? address.includes(apartmentMatchExpression)
          ? address.split(apartmentMatchExpression)[0]
          : address
        : address
    },

    // Вклеиваем в адресную строк address номер кваритиры
    setNewAddressState () {
      const apartmentMatchExpression = ', кв.'
      if (this.addressSelectManualMode || !this.currentRecieverLocal.apartment || !this.currentRecieverLocal.apartment.length) return

      if (this.currentRecieverLocal.address.includes(apartmentMatchExpression)) {
        this.currentRecieverLocal.address =
          this.currentRecieverLocal.address.split(apartmentMatchExpression)[0] +
          `${apartmentMatchExpression} ${this.currentRecieverLocal.apartment}`
      } else {
        this.currentRecieverLocal.address += `${apartmentMatchExpression} ${this.currentRecieverLocal.apartment}`
      }
    },

    setInitialResidenceAddressState () {
      const apartmentMatchExpression = ', кв.'
      const { residenceAddress } = this.currentRecieverLocal

      this.currentRecieverLocal.residenceAddress = !this.residenceAddressSelectManualMode
        ? residenceAddress.includes(apartmentMatchExpression)
          ? residenceAddress.split(apartmentMatchExpression)[0]
          : residenceAddress
        : residenceAddress
    },

    setNewResidenceAddressState () {
      const apartmentMatchExpression = ', кв.'
      if (this.residenceAddressSelectManualMode || !this.currentRecieverLocal.residenceApartment.length) return

      if (this.currentRecieverLocal.residenceAddress.includes(apartmentMatchExpression)) {
        this.currentRecieverLocal.residenceAddress =
          this.currentRecieverLocal.residenceAddress.split(apartmentMatchExpression)[0] +
          `${apartmentMatchExpression} ${this.currentRecieverLocal.residenceApartment}`
      } else {
        this.currentRecieverLocal.residenceAddress += `${apartmentMatchExpression} ${this.currentRecieverLocal.residenceApartment}`
      }
    },

    async saveRecieverOnSaveButtonClick () {
      const isFormValid = await this.validateForm()
      if (!isFormValid) return

      const hasRecieverEssentialDataChange = this.checkRecieverEssentialDataChange()
      await this.checkReciever({ hasRecieverEssentialDataChange })
      await this.saveReciever()
      this.setInitialAddressSelectManualMode()
      this.setInitialAddressState()
      this.setInitialResidenceAddressSelectManualMode()
      this.setInitialResidenceAddressState()
    },

    async saveReciever () {
      this.setRecieverSaveLoadingState(true)

      this.setReciever(Object.assign(this.currentRecieverLocal, {
        name: this.computedRecieverName,
        surname: this.computedRecieverSurname,
        patronymic: this.computedRecieverPatronymic,
        isDead: this.computedRecieverRecieverIsDead
      }))

      try {
        await this.saveRecieverApiCall()
        const messageText = 'Получатель успешно сохранен'
        this.showSuccessMessage(messageText)
        this.checkAndChangeRouting()
      } catch (error) {
        let messageText = 'При сохранении получателя возникла ошибка'
        if (error.response.data && error.response.data.message) messageText += error.response.data.message
        this.showErrorMessage(messageText)
      } finally {
        setTimeout(() => {
          this.setRecieverSaveLoadingState(false)
          this.cancelEditingMode()
        }, 300)
      }
    },

    checkRecieverEssentialDataChange () {
      return this.currentRecieverLocal.surname !== this.defaultReciever.surname ||
        this.currentRecieverLocal.name !== this.defaultReciever.name ||
        this.currentRecieverLocal.patronymic !== this.defaultReciever.patronymic ||
        this.currentRecieverLocal.birthday !== this.defaultReciever.birthday ||
        this.currentRecieverLocal.isDead !== this.defaultReciever.isDead
    },

    async checkReciever ({ hasRecieverEssentialDataChange }) {
      try {
        const { isCarantin } = await this.checkRecieverAction({ hasRecieverEssentialDataChange })
        const messageText = isCarantin ? 'Получатель в реестр НАЙДЕН' : 'Получатель в реестре НЕ найден'
        isCarantin ? this.showWarningMessage(messageText) : this.showSuccessMessage(messageText)
      } catch {
        const messageText = 'При проверке возникла ошибка'
        this.showErrorMessage(messageText)
      }
    },

    async checkAndChangeRouting () {
      try {
        const hasRouteChanged = await this.checkAndChangeRoutingAction({
          applicationId: this.application.id,
          applicationStatusId: this.application.applicationStatusId
        })

        if (hasRouteChanged) {
          // Если hasRouteChanged === true маршрутизация прошла и надо проеврить что делать с заявкой
          const messageText = 'Заявка передана по маршруту другому исполнителю'
          this.showSuccessMessage(messageText)
          // Проверяем роли, в которых заявка не должна закрываться, а должна обновиться по GET
          if (!this.userAbility.can('readAfterRouting', 'application')) {
            this.$router.replace({ name: 'Registry' })
            return
          }
        }

        this.fetchReciever()
        this.$emit('fetchApplication')
      } catch (message) {
        this.showErrorMessage(message)
      }
    },

    resetResidenceDistrictAndArea () {
      this.currentRecieverLocal.residenceDistrictId = null
      this.currentRecieverLocal.residenceAreaId = null
      this.currentRecieverLocal.residenceAddress = ''
      this.currentRecieverLocal.residenceApartment = ''
    },

    updateLocalApplicationStatus () {
      // Если статус заявки один НЕ из перечисленных меняем исполнителя
      // Выполнена, Отклонена

      if (this.checkIfAppllicationHasEndStatus()) return

      const nextStatus = this.applicationStatuses
        .find((status) => status.id === applicationStatusesConstants.NEW)
      this.$emit('updateLocalApplicationStatus', nextStatus)
    },

    setInitialAddressSelectManualMode () {
      this.addressSelectManualMode = !this.currentRecieverLocal.fiasGuid || !this.currentRecieverLocal.fiasGuid.length
    },

    setInitialResidenceAddressSelectManualMode () {
      this.residenceAddressSelectManualMode = !this.currentRecieverLocal.residenceFiasGuid || !this.currentRecieverLocal.residenceFiasGuid.length
    },

    //  Валидация формы получателя
    async validateForm () {
      return new Promise((resolve) => {
        this.$refs['reciever-form'].validate((valid) => {
          valid ? this.clearValidate() : this.showWarningMessage('Заполните поля формы')
          resolve(valid)
        })
      })
    },

    clearValidate () {
      this.$refs['reciever-form'].clearValidate()
    },

    areaValidator (rule, value, callback) {
      const validStatuses = [applicationStatusesConstants.REJECTED, applicationStatusesConstants.DRAFT]

      if (!value &&
        (!validStatuses.includes(this.application.applicationStatusId))) return callback(new Error('Обязательное поле'))
      else callback()
    },

    residenceAreaValidator (rule, value, callback) {
      const validStatuses = [applicationStatusesConstants.REJECTED, applicationStatusesConstants.DRAFT]

      if (!value &&
        (!validStatuses.includes(this.application.applicationStatusId) &&
          !this.currentReciever.isMoscowRegion)) return callback(new Error('Обязательное поле'))
      else callback()
    },

    districtValidator (rule, value, callback) {
      const validStatuses = [applicationStatusesConstants.REJECTED, applicationStatusesConstants.DRAFT]

      if (!value &&
        (!validStatuses.includes(this.application.applicationStatusId))) return callback(new Error('Обязательное поле'))
      else callback()
    },

    residenceDistrictValidator (rule, value, callback) {
      const validStatuses = [applicationStatusesConstants.REJECTED, applicationStatusesConstants.DRAFT]

      if (!value &&
        (!validStatuses.includes(this.application.applicationStatusId) &&
          !this.currentReciever.isMoscowRegion)) return callback(new Error('Обязательное поле'))
      else callback()
    },

    setRecieverSaveLoadingState (value) {
      this.isRecieverSaveLoading = value
    },

    showSuccessMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'success'
      })
    },

    showErrorMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'error',
        showClose: true,
        duration: 5000
      })
    },

    showWarningMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'warning'
      })
    },

    checkSymbols (rule, value, callback) {
      // const numbers = /[-0-9]+/
      // const specialSumbols = /[(,),!,",№,;,%,:,?,*,_,~,`,@,#,^,&,_,+,=,.,|]+/
      const valueIsValid = /^[A-ZА-ЯЁ\s\d-]+$/i.test(value)
      if (value && value.length && !valueIsValid) callback(new Error())
      else callback()
    },

    onlySpaces (rule, value, callback) {
      const onlySpaces = /^\s+$/.test(value)

      if (onlySpaces) return callback(new Error('Не может содержать только пробелы'))
      else return callback()
    },

    onlyCirillic (rule, value, callback) {
      const cirillic = /[a-z]/gi.test(value)

      if (cirillic) return callback(new Error('Не может содержать латинские символы'))
      else return callback()
    },

    checkPhone (rule, value, callback) {
      function checkIsValueMasked (value) {
        const symbols = ['(', '-', ')']
        const splittedValue = value.toString().split('')
        return symbols.map(symbol => {
          return splittedValue.includes(symbol)
        }).includes(true)
      }

      const isValueMasked = checkIsValueMasked(value)

      const phone = isValueMasked ? Inputmask.unmask(value, '99999999999') : value

      return phone.length < 11 ? callback(new Error()) : callback()
    },

    checkIfAppllicationHasEndStatus () {
      const statusesList = [
        applicationStatusesConstants.REJECTED,
        applicationStatusesConstants.DONE
      ]
      return statusesList.includes(this.application.applicationStatusId)
    }

  }
}
</script>
<style lang="sass">

  .reciever-form
    .el-row:last-child
      .el-form-item
        margin: 0

</style>
