<template lang='pug'>
  el-dialog.create-app-dialog-container(
    title="Новая заявка"
    v-loading.fullscreen.lock='applicationFormIsLoading'
    :visible.sync="dialogIsVisible"
    :close-on-click-modal='false'
    :close-on-press-escape='false'
    :show-close='false'
    @close='resetFields'
    @open='resetScroll'
    destroy-on-close
  )
    div.flex.align-center.justify-between(slot='title')
      span(style='font-size: 18px') Новая заявка

      // Это типа крестик
      el-button(
        type='text'
        icon='el-icon-close'
        @click='closeDialogIsVisible = true'
      )
    //- resetFields
    el-dialog(
      :visible.sync="closeDialogIsVisible"
      append-to-body
      title="Внимание"
    )
      div При закрытии окна, все не сохранённые данные будут утеряны.
      span(slot='footer')
        el-button(
          type='primary'
          @click='resetFields'
        ) Принять
        el-button(@click='closeDialogIsVisible = false') Отмена

    el-form.create-form(
      :model='declarerCitizenInfo'
      :rules='declarerRules'
      label-position='top'
      ref='declarerForm'
      size='small'
    )
      el-row.flex.justify-center
        el-col
          // Заявитель
          el-row.mb-20(:gutter='20')
            el-col(:span='20')
              el-divider(content-position='left')
                span.section-title Информация о заявителе
            el-col(:span='11')
              el-form-item(label='Фамилия' prop='surname')
                el-input(id="declarer-surname-input" :value='declarerCitizenInfo.surname' :maxlength='50' disabled)
            el-col(:span='11')
              el-form-item(label='Имя' prop='name')
                el-input(id="declarer-name-input" :value='declarerCitizenInfo.name' :maxlength='50' disabled)
            el-col(:span='11')
              el-form-item(label='Отчество' prop='patronymic')
                el-input(id="declarer-patronymic-input" :value='declarerCitizenInfo.patronymic' :maxlength='50' disabled)
            el-col(:span='11')
              el-form-item(label='Мобильный телефон' prop='phone')
                el-input(id="declarer-phone-input" :value='declarerCitizenInfo.phone' disabled type="text")
            el-col(:span="11" v-if="currentUserRole !== 'SC'")
              el-form-item(label='Медицинская организация' prop="medicalOrganizationId")
                el-select(
                  id="medical-organization-filter-select"
                  v-model="medicalOrganizationId"
                  placeholder=""
                  clearable
                )
                  el-option(
                    v-for="option in medicalOrganizationOptionsList"
                    :key="option.id"
                    :label="option.description"
                    :value="option.id"
                  )
    el-form.create-form(
      :model='recieverCitizenInfo'
      :rules='recieverRules'
      label-position='top'
      ref='recieverForm'
      size='small'
    )
      el-row.flex.justify-center
        el-col
          // Получатель
          el-row(:gutter='20')
            el-col(:span='20')
              el-divider(content-position='left')
                span.section-title Информация о получателе
            el-col(:span='8')
              el-form-item(label='Фамилия' prop='surname')
                el-input(id="reciever-surname-input" v-model='computedRecieverSurname' :disabled='coincidesApplicant' :maxlength='50')
            el-col(:span='6')
              el-form-item(label='Имя' prop='name')
                el-input(id="reciever-name-input" v-model='computedRecieverName' :disabled='coincidesApplicant' :maxlength='50')
            el-col(:span='8')
              el-form-item(label='Отчество' prop='patronymic')
                el-input(id="reciever-patronymic-input" v-model='computedRecieverPatronymic' :disabled='coincidesApplicant' :maxlength='50')
          el-row.mb-10(:gutter='20')
            el-col(:span='8')
              el-form-item(label='Дата рождения' prop='birthday')
                el-date-picker(
                  id="reciever-birthday-input"
                  popper-class="reciever-birthday-picker"
                  v-model='recieverCitizenInfo.birthday'
                  format='dd.MM.yyyy'
                  value-format='dd.MM.yyyy'
                  v-mask='"99.99.9999"'
                  :picker-options='pickerOptions'
                )
            el-col(:span='5')
              el-form-item(label='Возраст')
                el-input(id="reciever-age-input" v-model='recieverCitizenInfo.age' disabled)
            el-col.mb-10(:span='9')
              el-form-item(label='Мобильный телефон' prop='phone')
                el-input(
                  id="reciever-phone-input"
                  v-model='recieverCitizenInfo.phone'
                  v-mask="'+7(999) 999-99-99'"
                  :disabled='coincidesApplicant'
                  autocomplete="yandex-huita"
                  clearable
                )
            el-col
              el-form-item(label='')
                el-checkbox(id="reciever-is-dead-checkbox" v-model='recieverCitizenInfo.isDead') Получатель умер
            //el-col.flex(:span='14' v-loading='registryCheckedLoading')
            //  el-button(
            //    id="reciever-check-button"
            //    type='primary'
            //    size='small'
            //    @click='checkRegistryCarantin'
            //  ) Проверить
            //
            //  el-tag(id="reciever-check-status-tag" :type='registryCheckedTagType' style='margin-left: 10px') {{ registryCheckedStatus }}
            //  span.carantin-alerts(v-show='registryChecked' style='margin-left: 15px;')
            //    //-   div.reciever-not-checked(
            //    //-     v-show="reciever.carantinStatusId === null"
            //    //-     id="application-carantin-confirmed"
            //    //-   )
            //    //-     span Признак карантинности получателя не определен. Проверьте получателя.
            //
            //    div.reciever-not-contagious(
            //      v-show="carantinStatusId === carantinStatusesConstants.notContagious || carantinStatusId === carantinStatusesConstants.recovered"
            //      id="application-carantin-confirmed"
            //    )
            //      el-popover(
            //        placement="top-start"
            //        trigger="hover"
            //        :content="carantinStatusId === carantinStatusesConstants.notContagious ? 'Получатель не заразный' : 'Получатель выздоровел'"
            //      )
            //        font-awesome-icon.user-icon(icon="walking" slot="reference")
            //
            //    div.reciever-carantin(
            //      id="application-carantin-confirmed"
            //      v-show="carantinStatusId === carantinStatusesConstants.inCarantin"
            //    )
            //      el-popover(
            //        placement="top-start"
            //        trigger="hover"
            //        content="Получатель на карантине"
            //      )
            //        font-awesome-icon.user-icon(icon="procedures" slot="reference")
            //
            //    div.reciever-passed-away(
            //      id="application-carantin-confirmed"
            //      v-show="carantinStatusId === carantinStatusesConstants.passedAway"
            //    )
            //      el-popover(
            //        placement="top-start"
            //        trigger="hover"
            //        content="Получатель умер"
            //      )
            //        font-awesome-icon.user-icon(icon="heart-broken" slot="reference")
            //  el-dialog(
            //    id="reciever-check-validation-dialog"
            //    :visible.sync="dialogCarantinCheckedIsVisible"
            //    width='30%'
            //    append-to-body
            //  )
            //    div Вы не проверили наличие получателя в реестре.
            //    template.flex.justify-center(slot='footer')
            //      el-button(id="reciever-check-validation-button" @click='dialogCarantinCheckedIsVisible = false') Вернуться к созданию заявки
          el-row(:gutter='20')
            el-col(:span='24')
              h5 Адрес регистрации

            el-col(:span='!manually ? 18 : 22')
              fias-address(
                id="registered-address-picker"
                :label='fiasAddressLabel'
                :placeholder='fiasAddressPlaceholder'
                :address-info.sync='recieverCitizenInfo.registerAddressInfo'
                :searchDisabled='manually'
                validate-prop='registerAddressInfo.address'
                :only-moscow='true'
              )
                //- @address-selected='onAddressSelected'
                //- @address-cleared='onAddressSelected(null)'

            el-col(:span='4' v-show='!manually')
              el-form-item(label='Квартира')
                el-input(
                  id="registered-apartment-input"
                  v-model='recieverCitizenInfo.registerAddressInfo.apartment'
                  autocomplete="yandex-huita"
                  clearable
                )

          el-row.mb-30(:gutter='20' v-loading='areaAndDistrictIsLoading' clearable )
            el-col(:span='4')
              el-form-item(
                label='Округ'
                prop='registerAddressInfo.districtId'
              )
                el-select(id="registered-district-select" v-model='recieverCitizenInfo.registerAddressInfo.districtId' :disabled='!manually' filterable clearable)
                  el-option(
                    v-for='(item, index) in computedFoundDistricts(false)'
                    :value='item.id'
                    :label='item.name'
                    :key='item.id'
                  )
            el-col(:span='8')
              el-form-item(
                label='Район'
                prop='registerAddressInfo.areaId'
                :error='(recieverCitizenInfo.registerAddressInfo.districtId && !recieverCitizenInfo.registerAddressInfo.areaId) ? "Обязательное поле" : ""'
              )
                el-select(
                  id="registered-area-select"
                  v-model='recieverCitizenInfo.registerAddressInfo.areaId'
                  :disabled='!manually'
                  no-data-text='Выберите округ'
                  filterable
                  clearable
                )
                  el-option(
                    v-for='(item, index) in computedFoundAreas(false)'
                    :value='item.id'
                    :label='item.name'
                    :key='index'
                  )
            el-col
              el-checkbox(id="registerd-address-edit-mode-checkbox" v-model='manually') Вручную
          el-row(:gutter='20')
            el-col(:span='24')
              h5 Адрес проживания
            el-col.mb-10
              el-checkbox(id="residence-address-coinside-checkbox" v-model='registerAddressCopy') Совпадает с адресом регистрации
            el-col.mb-10
            el-col(:span='!residenceManually ? 18 : 22')
              fias-address(
                id="residence-address-picker"
                :label='residenceFiasAddressLabel'
                :placeholder='residenceFiasAddressPlaceholder'
                :address-info.sync='recieverCitizenInfo.residenceAddressInfo'
                :disabled='registerAddressCopy'
                :searchDisabled='residenceManually'
                validate-prop='residenceAddressInfo.address'
              )
                //- @address-selected='onAddressSelected'
                //- @address-cleared='onAddressSelected(null)'

            el-col(:span='4')
              el-form-item(label='Квартира' v-show='!residenceManually')
                el-input(
                  id="residence-aparment-input"
                  v-model='recieverCitizenInfo.residenceAddressInfo.apartment'
                  autocomplete="yandex-huita"
                  :disabled='registerAddressCopy'
                  clearable
                )
          el-row.mb-30(:gutter='20' v-loading='residenceAreaAndDistrictIsLoading')
            el-col(:span='4')
              el-form-item(label='Округ' prop='residenceAddressInfo.districtId')
                el-select(id="residence-apartment-district" v-model='recieverCitizenInfo.residenceAddressInfo.districtId' :disabled='registerAddressCopy || !residenceManually' filterable clearable)
                  el-option(
                    v-for='(item, index) in computedFoundDistricts(true)'
                    :value='item.id'
                    :label='item.name'
                    :key='item.id'
                  )
            el-col(:span='8')
              el-form-item(
                label='Район'
                prop='residenceAddressInfo.areaId'
                :error='!registerAddressCopy && (recieverCitizenInfo.residenceAddressInfo.districtId && !recieverCitizenInfo.residenceAddressInfo.areaId) ? "Обязательное поле" : ""'
              )
                el-select(
                  v-model='recieverCitizenInfo.residenceAddressInfo.areaId'
                  no-data-text='Выберите округ'
                  :disabled='registerAddressCopy || !residenceManually'
                  filterable
                  clearable
                )
                  el-option(
                    v-for='(item, index) in computedFoundAreas(true)'
                    :value='item.id'
                    :label='item.name'
                    :key='index'
                  )

            el-col
              el-checkbox(id="residence-address-edit-mode-checkbox" v-model='residenceManually' :disabled='registerAddressCopy') Вручную
          el-row(:gutter='20')
            el-col(:span='24')
              h5 Паспортные данные
            el-col(:span='8')
              el-form-item(label='Серия паспорта' prop='passportSeries' :show-message='passportShowMessage')
                el-input(id="passport-series-input" v-model='recieverCitizenInfo.passportSeries' v-mask="'9999'")
            el-col(:span='8')
              el-form-item(label='Номер паспорта' prop='passportNumber' :show-message='passportShowMessage')
                el-input(id="passport-number-input" v-model='recieverCitizenInfo.passportNumber' v-mask="'999999'")
            el-col(:span='8')
              el-form-item(label='Дата выдачи' prop='passportDate' :show-message='passportShowMessage')
                el-date-picker(
                  id="passport-date-input"
                  popper-class="passport-date-picker"
                  v-model='recieverCitizenInfo.passportDate'
                  format='dd.MM.yyyy'
                  value-format='dd.MM.yyyy'
                  v-mask='"99.99.9999"'
                  :picker-options='pickerOptions'
                )
          el-row
            el-col(:span='20')
              el-divider(content-position='left')
                span.section-title Категории
          el-row(:gutter='20')
            el-col(:span='22')
              el-table(
                id="category-checkbox-group"
                :data='questions'
                ref='questionsTable'
                @selection-change="handleQuestionsSelect"
                @row-click='handleQuestionsCurrentChange'
                :row-class-name='quesitionsRowAgeDisabledClass'
              )
                el-table-column(type='selection' :selectable='quesitionsRowAgeCanBeSelected')
                el-table-column(label='Наименование категории' prop='name')
                  template(slot-scope="scope")
                    div {{ scope.row.name }}
                    el-input(v-if='scope.row.customizable' v-model='scope.row.cutomizableValue' :disabled='!questionsRowSelected(scope.row.id)' size='small')
      el-row.mb-20
        el-col(:span='20')
           el-divider(content-position='left')
            span.section-title Краткое описание ситуации/проблемы
      el-row
        el-col(:span='22')
          el-form-item(label='')
            el-input(
              id="request-create-situationDescription-input"
              v-model='situationDescription'
              :maxlength='2000'
              show-word-limit
              type='textarea'
              placeholder='Введите ваш комментарий...'
              )
      el-row
        el-col(:span='20')
          el-divider(content-position='left')
            span.section-title Нуждаемость в обслуживании не подтверждена
      el-row.mb-20
        el-table(
          id="not-confirmed-checkbox-group"
          :data='notConfirmedOptionsList'
          ref='notConfirmedTable'
          @selection-change="handleNotConfirmedSelect"
          @row-click='handleNotConfirmedRowClick'
        )
          el-table-column(type='selection')
          el-table-column(label='Наименование категории' prop='name')
            template(slot-scope="scope")
              el-col(:span='12') {{ scope.row.name }}
              el-col(:span='6')
                el-date-picker(
                  size='small'
                  v-model="scope.row.date"
                  format='dd.MM.yyyy'
                  value-format='dd.MM.yyyy'
                  v-mask='"99.99.9999"'
                  :disabled="!notConfirmed.find(el => el.id === scope.row.id)"
                  :picker-options='pickerOptions'
                )
      el-row.mb-20
        el-upload(
          multiple
          :action="''"
          :file-list='notConfirmedFiles'
          :on-change="handleUploadNotConfirmedFiles"
          :on-remove="handleRemoveNotConfirmedFile"
          :auto-upload="false"
          :disabled="!notConfirmed.length"
        )
          el-button( type="primary" :disabled="!notConfirmed.length")
            i.el-icon-upload
            span Загрузить файлы
      el-row
        el-col(:span='20')
          el-divider(content-position='left')
            span.section-title Экспресс функциональная диагностика
      el-row
        el-col(:span='8')
          el-form-item(label='')
            el-checkbox(id="is-express-fd-checkbox" v-model='isExpressFD' :disabled="isRejectFromExpressFD") Проведена экспресс ФД
        el-col(:span='8')
          el-form-item(label='' prop="expressFDDate")
            el-date-picker(
              v-model='expressFDDate'
              format='dd.MM.yyyy'
              value-format='dd.MM.yyyy'
              :disabled="!isExpressFD"
              v-mask='"99.99.9999"'
              :picker-options='pickerOptions'
            )
      el-row
        el-form-item(label='')
          el-checkbox(id="is-express-fd-rejected-checkbox" v-model='isRejectByResultExpressFD' :disabled="!isExpressFD") Отказано в обслуживании по результатам ФД
      el-row
        el-form-item(label='')
          el-checkbox(id="is-express-fd-rejected-checkbox" v-model='isRejectFromExpressFD') Отказ от проведения ФД
      el-row.mb-20
        el-upload(
          multiple
          :action="''"
          :file-list='expressFDFiles'
          :on-change="handleUploadExpressFDFiles"
          :on-remove="handleRemoveExpressFDFile"
          :auto-upload="false"
          :disabled="!isExpressFD"
        )
          el-button( type="primary" :disabled="!isExpressFD && !isRejectByResultExpressFD && !isRejectFromExpressFD")
            i.el-icon-upload
            span Загрузить файлы

      el-row
        el-col(:span='20')
          el-divider(content-position='left')
            span.section-title Услуги
      el-row.mb-20(:gutter='20')
        el-col(:span='22')

          div(v-for="service in filteredServiceTypes" :key="service.id")
            label
              input.checkbox-group-styled(
                type="checkbox"
                :disabled="!!serviceTypeisDisabled(service.id)"
                v-model="selectedServiceTypes"
                :value="service"
              )
              span {{service.name}}

          //el-checkbox-group.custom-checkbox-group(
          //  v-model="selectedServiceTypes"
          //  v-for="service in filteredServiceTypes"
          //  id="services-checkbox-group")
          //  el-checkbox.custom-checkbox(
          //    :key="service.id"
          //    :value="service"
          //    :id="`id-${service.id}`")
          //    p {{ service.name }}

          //el-table(
          //  id="services-checkbox-group"
          //  :data='filteredServiceTypes'
          //  ref='serviceTable'
          //  :row-class-name="servicesTableRowClassName"
          //  @selection-change="handleServiceSelectionChange"
          //  @row-click="handleServiceRowClick"
          //  @select="handleServiceSelect"
          //)
          //  el-table-column(type='selection')
          //  el-table-column(label='Тип услуги' prop='name')
          el-row.mb-20
            el-col(:span='20')
              el-divider(content-position='left')
                span.section-title Комментарий
              el-form-item(label='')
                el-input(id="request-create-comment-input" v-model='comment' :maxlength='2000' show-word-limit type='textarea' placeholder='Введите ваш комментарий...')
          el-row(:gutter='20')
            el-col
              el-button(id="request-create-send-button" type='primary' icon='el-icon-document-checked' @click='createApplication') Отправить

</template>

<script>

/*
TODO:
  Сделать выгрузку реестра
*/

// @ is an alias to /src
import { mapActions, mapGetters, mapState } from 'vuex'
import createCitizen from '@/api/apiCalls/application/createCitizen'
import createApplication from '@/api/apiCalls/application/createApplication'
import fetchServiceTypes from '@/api/apiCalls/references/fetchServiceTypes'
import fetchQuestions from '@/api/apiCalls/references/fetchQuestions'
import fiasAddress from '@/components/fiasAddress'
import fetchCitizen from '@/api/apiCalls/citizen/fetchCitizen'
import uploadApplicationFiles from '@/api/apiCalls/file/uploadApplicationFiles'
import fetchNotConfirmedTypes from '@/api/apiCalls/application/fetchNotConfirmedTypes'
import fetchMedicalOrganizations from '@/api/apiCalls/application/fetchMedicalOrganizations'
import _ from 'lodash'

const referencesModuleName = 'references'
export default {
  name: 'CreateRequestDialog',
  components: {
    fiasAddress
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    // const validatePhoneNumber = (rule, value, callback) => {
    //   // Без маски, только цифры
    //   const clearValue = value.replace(/[^+\d]/g, '')
    //
    //   if (clearValue.length < 11) callback(new Error('Обязательное поле'))
    //   else callback()
    // }

    // const validateRegisterDistrict = (rule, value, callback) => {
    //   if (this.manually && !value) callback(new Error('Обязательное поле'))
    //   else callback()
    // }
    //
    // const validateRegisterArea = (rule, value, callback) => {
    //   // Без маски, только цифры
    //   if ((this.manually || this.recieverCitizenInfo.registerAddressInfo.districtId) && !value) callback(new Error('Обязательное поле'))
    //   else callback()
    // }
    //
    // const validateResidenceArea = (rule, value, callback) => {
    //   if ((this.residenceManually || this.recieverCitizenInfo.residenceAddressInfo.districtId) && !value) callback(new Error('Обязательное поле'))
    //   else callback()
    // }

    const validateMedicalOrganizationId = (rule, value, callback) => {
      if (!this.medicalOrganizationId && this.currentUserRole !== 'SC') callback(new Error('Обязательное поле'))
      else callback()
    }

    // const validateResidenceDistrict = (rule, value, callback) => {
    //   if (this.residenceManually && !value) callback(new Error('Обязательное поле'))
    //   else callback()
    // }

    const withoutOnlySpaces = (rule, value, callback) => {
      const onlySpaces = /^\s+$/.test(value)
      const clearValue = value && value.split(' ').join('')
      const cirillic = /[a-z]/gi.test(value)
      // const specialSumbols = /[(,),!,",№,;,%,:,?,*,_,~,`,@,/,{,},#,^,$,&,_,+,=,.,|,[,\]]+/.test(value)
      const valueIsValid = /^[A-ZА-ЯЁ\s\d-]+$/i.test(value)

      if (onlySpaces) callback(new Error('Не может содержать только пробелы'))
      else if (clearValue && clearValue.length < 1) callback(new Error('Не менее 1 символа'))
      else if (cirillic) callback(new Error('Не может содержать латинские символы'))
      else if (value.length && !valueIsValid) callback(new Error('Не может содержать специальные символы'))
      else callback()
    }

    const validatePassportSeries = (rule, value, callback) => {
      const haveSpaces = value && value.includes('_')

      const { passportSeries, passportNumber, passportDate } = this.recieverCitizenInfo
      const a = (passportSeries || passportNumber || passportDate) && (!passportSeries)

      if (this.passportShowMessage) {
        if (haveSpaces) callback(new Error('Необходимо ввести полное значение'))
        else if (a) callback(new Error('Необходимо заполнить все данные паспорта'))
        else callback()
      } else {
        callback()
      }
    }

    const validatePassportNumber = (rule, value, callback) => {
      const haveSpaces = value && value.includes('_')

      const { passportSeries, passportNumber, passportDate } = this.recieverCitizenInfo
      const a = (passportSeries || passportNumber || passportDate) && (!passportNumber)

      if (this.passportShowMessage) {
        if (haveSpaces) callback(new Error('Необходимо ввести полное значение'))
        else if (a) callback(new Error('Необходимо заполнить все данные паспорта'))
        else callback()
      } else {
        callback()
      }
    }

    const validatePassportDate = (rule, value, callback) => {
      const haveSpaces = value && value.includes('_')

      const { passportSeries, passportNumber, passportDate } = this.recieverCitizenInfo
      const a = (passportSeries || passportNumber || passportDate) && (!passportDate)

      if (this.passportShowMessage) {
        if (haveSpaces) callback(new Error('Необходимо ввести полное значение'))
        else if (a) callback(new Error('Необходимо заполнить все данные паспорта'))
        else callback()
      } else {
        callback()
      }
    }

    return {
      application: {},
      declarerCitizenInfoClearCopy: null,
      recieverCitizenInfoClearCopy: null,

      declarerCitizenInfo: {
        name: '',
        surname: '',
        patronymic: '',
        phone: ''
      },

      recieverCitizenInfo: {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        birthday: null,
        age: '',
        isDead: false,
        isCarantin: null,
        passportDate: '',
        passportNumber: '',
        passportSeries: '',
        // Адрес регистрации
        registerAddressInfo: {
          address: '',
          fiasGuid: '',
          districtId: '',
          areaId: '',
          apartment: ''
        },
        // Адрес проживания
        residenceAddressInfo: {
          address: '',
          fiasGuid: '',
          districtId: '',
          areaId: '',
          apartment: ''
        }
      },
      coincidesApplicant: false,
      applicationFormIsLoading: false,
      registerAddressCopy: false,
      declarerRules: {
        medicalOrganizationId: [
          { validator: validateMedicalOrganizationId, message: 'Обязательное поле', trigger: ['blur', 'change'] }
        ]
      },
      recieverRules: {
        name: [
          { required: true, message: 'Обязательное поле', trigger: ['blur', 'change'] },
          { validator: withoutOnlySpaces, trigger: ['blur', 'change'] }
        ],
        patronymic: [
          { validator: withoutOnlySpaces, trigger: ['blur', 'change'] }
        ],
        surname: [
          { message: 'Обязательное поле', trigger: ['blur', 'change'] },
          { validator: withoutOnlySpaces, trigger: ['blur', 'change'] }
        ],
        // phone: [
        //   { validator: validatePhoneNumber, trigger: ['blur', 'change'] }
        // ],
        birthday: [
          { message: 'Обязательное поле', trigger: ['blur', 'change'] }
        ],
        passportSeries: [
          { validator: validatePassportSeries, trigger: ['blur', 'change'] }
        ],
        passportNumber: [
          { validator: validatePassportNumber, trigger: ['blur', 'change'] }
        ],
        passportDate: [
          { validator: validatePassportDate, trigger: ['blur', 'change'] }
        ],
        registerAddressInfo: {
          address: [
            { message: 'Обязательное поле', trigger: ['blur', 'change'] }
          ]
          // districtId: [
          //   { validator: validateRegisterDistrict, message: 'Обязательное поле', trigger: ['blur', 'change'] }
          // ],
          // areaId: [
          //   { validator: validateRegisterArea, message: 'Обязательное поле', trigger: ['blur', 'change'] }
          // ]
        },
        residenceAddressInfo: {
          address: [
            { message: 'Обязательное поле', trigger: ['blur', 'change'] }
          ]
          // districtId: [
          //   { validator: validateResidenceDistrict, message: 'Обязательное поле', trigger: ['blur', 'change'] }
          // ],
          // areaId: [
          //   { validator: validateResidenceArea, message: 'Обязательное поле', trigger: ['blur', 'change'] }
          // ]
        }
      },
      pickerOptions: {
        disabledDate (time) {
          const future = time.getTime() > Date.now()
          const past = time.getTime() < new Date('01.01.1900').getTime()
          if (past || future) return true
        }
      },

      registryChecked: false, // Была ли нажата кнопка проверить (карантин)
      carantinStatusId: null,
      registryCheckedStatus: 'Ожидает проверки', // Текст статуса проверки (карантин)
      registryCheckedTagType: 'info',
      dialogCarantinCheckedIsVisible: false, // Подсказка, если кнопка проверки карантина не была нажата
      registryCheckedLoading: false,

      passportShowMessage: false,
      otherServicesErrorMessage: '',
      purchaseMedicinesErrorMessage: '',

      foundAreas: [],
      foundDistricts: [],
      manually: false,
      areaAndDistrictIsLoading: false,

      residenceFoundAreas: [],
      residenceFoundDistricts: [],
      residenceManually: false,
      residenceAreaAndDistrictIsLoading: false,

      questions: [],
      selectedQuestions: [],

      serviceTypes: [],
      selectedServiceTypes: [],
      currentService: null,

      situationDescription: '',
      comment: '',

      input: '',
      closeDialogIsVisible: false,

      usingAppCopy: false,
      sourceApplicationId: null,

      foundDistrictsNames: [],
      foundAreasNames: [],

      isExpressFD: false,
      expressFDFiles: [],
      expressFDDate: null,
      isRejectByResultExpressFD: false,
      isRejectFromExpressFD: false,

      notConfirmedOptionsList: [],
      notConfirmed: [],
      notConfirmedFiles: [],

      medicalOrganizationId: null,
      medicalOrganizationOptionsList: []

    }
  },
  async created () {
    this.fetchRefAreas()
    this.fetchRefDistricts()
    this.fetchNotConfirmedTypes()

    this.declarerCitizenInfoClearCopy = Object.assign({}, this.declarerCitizenInfo)
    this.recieverCitizenInfoClearCopy = Object.assign({}, this.recieverCitizenInfo)

    const serviceRsql = {
      rsql: 'group!=0',
      size: 20,
      sort: 'name'
    }
    const questionsRsql = {
      rsql: 'userType==2',
      size: 20,
      sort: 'name'
    }

    try {
      this.serviceTypes = await fetchServiceTypes(serviceRsql)
      this.questions = await fetchQuestions(questionsRsql)
    } catch (error) {
      this.serviceTypes = []
      this.questions = []
    }
  },
  mounted () {
    const applicationCopy = JSON.parse(sessionStorage.getItem('applicationCopy'))
    sessionStorage.setItem('applicationCopy', null)

    if (applicationCopy) {
      this.loadAppCopy(applicationCopy)
    }

    this.declarerCitizenInfo = {
      name: this.user.name,
      surname: this.user.surname,
      patronymic: this.user.patronymic,
      phone: this.user.phone
    }

    if (this.currentUserRole === 'SC') {
      this.medicalOrganizationId = this.user.medicalOrganizationId
    } else {
      this.fetchMedicalOrganizations()
    }
  },

  computed: {
    ...mapState(referencesModuleName, {
      refAreas: state => state.refAreas,
      refDistricts: state => state.refDistricts
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapGetters('auth', ['currentUserRole']),
    computedRecieverName: {
      set (value) {
        this.recieverCitizenInfo.name = value
      },
      get () {
        let name = this.recieverCitizenInfo.name

        if (name) name = this.nameBuilder(name)
        return name
      }
    },
    computedRecieverSurname: {
      set (value) {
        this.recieverCitizenInfo.surname = value
      },
      get () {
        let surname = this.recieverCitizenInfo.surname

        if (surname) surname = this.nameBuilder(surname)
        return surname
      }
    },
    computedRecieverPatronymic: {
      set (value) {
        this.recieverCitizenInfo.patronymic = value
      },
      get () {
        let patronymic = this.recieverCitizenInfo.patronymic

        if (patronymic) patronymic = this.nameBuilder(patronymic)
        return patronymic
      }
    },
    dialogIsVisible: {
      get () {
        return this.isVisible
      },
      set (value) {
        this.$emit('update:is-visible', value)
      }
    },
    computedAddress () {
      let result

      if (this.manually || !(this.recieverCitizenInfo.registerAddressInfo.apartment && this.recieverCitizenInfo.registerAddressInfo.apartment.length)) {
        result = `${this.recieverCitizenInfo.registerAddressInfo.address}`
      } else {
        result = `${this.recieverCitizenInfo.registerAddressInfo.address}, кв. ${this.recieverCitizenInfo.registerAddressInfo.apartment}`
      }

      return result
    },
    computedResidenceAddress () {
      let result

      if (this.residenceManually || !(this.recieverCitizenInfo.residenceAddressInfo.apartment && this.recieverCitizenInfo.residenceAddressInfo.apartment.length)) {
        result = `${this.recieverCitizenInfo.residenceAddressInfo.address}`
      } else {
        result = `${this.recieverCitizenInfo.residenceAddressInfo.address}, кв. ${this.recieverCitizenInfo.residenceAddressInfo.apartment}`
      }

      return result
    },
    computedFoundAreas () {
      return (residence = false) => {
        const currentDistrictId = residence ? this.recieverCitizenInfo.residenceAddressInfo.districtId : this.recieverCitizenInfo.registerAddressInfo.districtId
        return this.refAreas.filter(area => area.districtId === currentDistrictId)
      }
    },
    computedFoundDistricts () {
      return (residence = false) => {
        if (!residence) {
          return this.refDistricts.filter(i => i.name !== 'МО')
        }
        return this.refDistricts
      }
    },
    filteredServiceTypes () {
      if (Array.isArray(this.serviceTypes)) return this.serviceTypes.filter(item => !item.disabled && !item.parentId)
      else return []
    },
    serviceTypesRowSelected () {
      return (id) => {
        return !!this.selectedServiceTypes.find(item => item.id === id)
      }
    },
    questionsRowSelected () {
      return (id) => {
        return !!this.selectedQuestions.find(item => item.id === id)
      }
    },
    quesitionsRowAgeCanBeSelected () {
      return (row) => {
        return !row.name.includes('6')
      }
    },

    fiasAddressLabel () {
      if (this.manually) {
        return 'Москва, улица, дом, корпус/строение, квартира'
      } else {
        return 'Москва, улица, дом, корпус/строение'
      }
    },
    residenceFiasAddressLabel () {
      if (this.residenceManually) {
        return 'Город проживания, улица, дом, корпус/строение, квартира'
      } else {
        return 'Город проживания, улица, дом, корпус/строение'
      }
    },
    fiasAddressPlaceholder () {
      if (this.manually) {
        return 'Например: "Юности, д. 111, стр. 1, кв. 1"'
      } else {
        return 'Например: "Юности, д. 111, стр. 1"'
      }
    },
    residenceFiasAddressPlaceholder () {
      if (this.residenceManually) {
        return "Например: 'Юности, д. 111, стр. 1, кв. 1'"
      } else {
        return "Например: 'Юности, д. 111, стр. 1'"
      }
    }
  },
  watch: {
    'recieverCitizenInfo.registerAddressInfo.districtId' (value) {
      const currentDistrictHaveCurrentArea = this.computedFoundAreas(false).find(
        item => item.id === this.recieverCitizenInfo.registerAddressInfo.areaId
      )

      if (!value) this.recieverCitizenInfo.registerAddressInfo.areaId = null
      else if (!currentDistrictHaveCurrentArea) this.recieverCitizenInfo.registerAddressInfo.areaId = null
    },
    'recieverCitizenInfo.residenceAddressInfo.districtId' (value) {
      if (!this.registerAddressCopy) {
        const currentDistrictHaveCurrentArea = this.computedFoundAreas(true).find(
          item => item.id === this.recieverCitizenInfo.residenceAddressInfo.areaId
        )

        if (!value) this.recieverCitizenInfo.residenceAddressInfo.areaId = null
        else if (!currentDistrictHaveCurrentArea) this.recieverCitizenInfo.residenceAddressInfo.areaId = null
      }
    },
    'recieverCitizenInfo.age' (value) {
      if (value < 65) {
        const row = this.serviceTypes.find(item => item.id && item.id === 18)
        this.$refs.serviceTable.toggleRowSelection(row, false)
      }
    },
    coincidesApplicant (value) {
      if (value) {
        this.$set(this.recieverCitizenInfo, 'phone', this.declarerCitizenInfo.phone)
        this.$refs.recieverForm.clearValidate()
      } else {
        this.$set(this.recieverCitizenInfo, 'name', '')
        this.$set(this.recieverCitizenInfo, 'surname', '')
        this.$set(this.recieverCitizenInfo, 'patronymic', '')
        this.$set(this.recieverCitizenInfo, 'phone', '')
      }
    },
    declarerCitizenInfo: {
      handler: function (value) {
        if (this.coincidesApplicant) {
          this.$set(this.recieverCitizenInfo, 'phone', value.phone)
        }
      },
      deep: true
    },
    manually (value) {
      if (this.registerAddressCopy) {
        this.residenceManually = value
      }

      if (value) {
        if (this.recieverCitizenInfo.registerAddressInfo.apartment.length) {
          this.recieverCitizenInfo.registerAddressInfo.address = `${this.recieverCitizenInfo.registerAddressInfo.address}, кв. ${this.recieverCitizenInfo.registerAddressInfo.apartment}`
        }

        this.recieverCitizenInfo.registerAddressInfo.apartment = ''
      } else {
        this.recieverCitizenInfo.registerAddressInfo.address = ''
        this.recieverCitizenInfo.registerAddressInfo.apartment = ''
      }
    },
    residenceManually (value) {
      if (value) {
        if (this.recieverCitizenInfo.residenceAddressInfo.apartment.length) {
          this.recieverCitizenInfo.residenceAddressInfo.address = `${this.recieverCitizenInfo.residenceAddressInfo.address}, кв. ${this.recieverCitizenInfo.residenceAddressInfo.apartment}`
        }

        this.recieverCitizenInfo.residenceAddressInfo.apartment = ''
      } else {
        this.recieverCitizenInfo.residenceAddressInfo.address = ''
        this.recieverCitizenInfo.residenceAddressInfo.apartment = ''
      }
    },
    registerAddressCopy (value) {
      if (value) {
        this.recieverCitizenInfo.residenceAddressInfo = this.recieverCitizenInfo.registerAddressInfo
        this.$refs.recieverForm.clearValidate()

        if (this.manually) this.residenceManually = this.manually
      } else {
        this.recieverCitizenInfo.residenceAddressInfo = {
          address: '',
          fiasGuid: '',
          districtId: '',
          areaId: '',
          apartment: ''
        }

        this.residenceManually = false
      }
    },
    'recieverCitizenInfo.birthday' (value) {
      this.registryChecked = false
      this.registryCheckedTagType = 'info'
      this.registryCheckedStatus = 'Ожидает проверки'

      if (value) {
        const parsedValue = value.split('.')
        const birthDate = new Date(`${parsedValue[2]}-${parsedValue[1]}-${parsedValue[0]}`)
        const now = new Date()
        const age = now.getFullYear() - birthDate.getFullYear()
        this.recieverCitizenInfo.age = now.setFullYear(1972) < birthDate.setFullYear(1972) ? Number(age) - 1 : age
      }
    },
    'recieverCitizenInfo.name' (value) {
      this.registryChecked = false
      this.registryCheckedTagType = 'info'
      this.registryCheckedStatus = 'Ожидает проверки'
    },
    'recieverCitizenInfo.surname' (value) {
      this.registryChecked = false
      this.registryCheckedTagType = 'info'
      this.registryCheckedStatus = 'Ожидает проверки'
    },
    'recieverCitizenInfo.patronymic' (value) {
      this.registryChecked = false
      this.registryCheckedTagType = 'info'
      this.registryCheckedStatus = 'Ожидает проверки'
    },
    'isExpressFD' (value) {
      if (!value) {
        this.expressFDDate = null
        this.isRejectByResultExpressFD = false
      }
    },
    'isRejectFromExpressFD' (value) {
      if (value) this.isExpressFD = false
    }
  },
  methods: {
    ...mapActions(referencesModuleName, {
      fetchRefAreas: 'fetchRefAreas',
      fetchRefDistricts: 'fetchRefDistricts'
    }),
    nameBuilder (name) {
      // const name = this[reference][prop]
      const upperCase = str => str && str[0].toUpperCase() + str.slice(1).toLowerCase()
      return name.split('-').map(str => upperCase(str).split(' ').map(str => upperCase(str)).join(' ')).join('-')
    },
    async loadAppCopy (appCopy) {
      this.usingAppCopy = true
      this.sourceApplicationId = appCopy.id

      const reciever = await fetchCitizen(appCopy.citizenRecieverId)
      const declarer = await fetchCitizen(appCopy.citizenDeclarerId)

      this.declarerCitizenInfo = {
        name: declarer.name || '',
        surname: declarer.surname || '',
        patronymic: declarer.patronymic || '',
        phone: `${declarer.phone}` || ''
      }

      if (
        reciever.name === declarer.name &&
        reciever.surname === declarer.surname &&
        reciever.phone === declarer.phone
      ) {
        this.coincidesApplicant = true
      } else {
        this.$set(this.recieverCitizenInfo, 'name', reciever.name)
        this.$set(this.recieverCitizenInfo, 'surname', reciever.surname)
        this.$set(this.recieverCitizenInfo, 'phone', `${reciever.phone}`)
        this.$set(this.recieverCitizenInfo, 'patronymic', reciever.patronymic)
      }

      this.recieverCitizenInfo.birthday = reciever.birthday

      if (reciever.address.includes(', кв.')) {
        this.recieverCitizenInfo.registerAddressInfo.address = reciever.address.substring(0, reciever.address.indexOf(', кв'))
      } else {
        this.recieverCitizenInfo.registerAddressInfo.address = reciever.address
      }

      this.recieverCitizenInfo.registerAddressInfo.fiasGuid = reciever.fiasGuid
      this.recieverCitizenInfo.registerAddressInfo.districtId = reciever.districtId
      this.recieverCitizenInfo.registerAddressInfo.areaId = reciever.areaId
      this.recieverCitizenInfo.registerAddressInfo.apartment = reciever.apartment

      this.recieverCitizenInfo.passportDate = reciever.passportDate
      this.recieverCitizenInfo.passportNumber = reciever.passportNumber
      this.recieverCitizenInfo.passportSeries = reciever.passportSeries

      const questions = appCopy.answers.map(item => {
        return this.questions.find(question => question.name === item.name)
      })

      questions.map(row => {
        this.$refs.questionsTable.toggleRowSelection(row)
      })

      const requiredServiceItem = this.serviceTypes.find(item => item.name.includes('1.1'))
      this.$refs.serviceTable.toggleRowSelection(requiredServiceItem)

      setTimeout(() => {
        if (reciever.residenceAddress.includes(', кв.')) {
          this.recieverCitizenInfo.residenceAddressInfo.address = reciever.residenceAddress.substring(0, reciever.residenceAddress.indexOf(', кв'))
        } else {
          this.recieverCitizenInfo.residenceAddressInfo.address = reciever.residenceAddress
        }

        this.recieverCitizenInfo.residenceAddressInfo.address = reciever.residenceAddress
        this.recieverCitizenInfo.residenceAddressInfo.fiasGuid = reciever.residenceFiasGuid
        this.recieverCitizenInfo.residenceAddressInfo.districtId = reciever.residenceDistrictId
        this.recieverCitizenInfo.residenceAddressInfo.areaId = reciever.residenceAreaId
        this.recieverCitizenInfo.residenceAddressInfo.apartment = reciever.residenceApartment

        this.$refs.declarerForm.clearValidate()
        this.$refs.recieverForm.clearValidate()
        this.$refs.declarerForm.validate()
        this.$refs.recieverForm.validate()
      }, 0)
    },
    async fetchNotConfirmedTypes () {
      const data = await fetchNotConfirmedTypes()
      this.notConfirmedOptionsList = data.content
    },
    async fetchMedicalOrganizations () {
      const data = await fetchMedicalOrganizations()
      this.medicalOrganizationOptionsList = data.content
    },
    resetScroll () {
      const block = document.querySelector('.el-dialog__wrapper')

      setTimeout(() => {
        block.scrollTop = 0
      }, 100)
    },
    async createApplication () {
      if (!this.validateAppBeforeSave()) return false

      try {
        this.applicationFormIsLoading = true

        const declarerCitizen = await this.saveDeclarerCitizen()
        const recieverCitizen = await this.saveRecieverCitizen()
        const expressFDFiles = await this.saveExpressFDFiles()
        const notConfirmedFiles = await this.saveNotConfirmedFiles()

        const services = this.selectedServiceTypes.map(item => {
          return {
            comment: item.description,
            name: item.name,
            disabled: item.disabled,
            customizable: item.customizable,
            group: item.group,
            serviceTypeId: item.id
          }
        })

        const answers = this.selectedQuestions.map(item => {
          return {
            questionId: item?.id,
            name: item?.name,
            value: true,
            customizable: item?.customizable,
            cutomizableValue: item?.cutomizableValue,
            comment: null
          }
        })

        const notConfirmed = this.notConfirmed.map((e) => ({ ...{ notConfirmedTypeId: e?.id }, ...e }))

        let appStatusId = 1 // Статус "Новая"

        if (this.isRejectFromExpressFD || this.isRejectByResultExpressFD) {
          appStatusId = 5 // Статус "Отказано Ф/Д"
        }

        const appData = {
          applicationStatusId: appStatusId,
          citizenDeclarerId: declarerCitizen.id,
          citizenRecieverId: recieverCitizen.id,
          comment: this.comment,
          situationDescription: this.situationDescription,
          userAuthorId: this.user.id,
          medicalOrganizationId: this.medicalOrganizationId,
          answers,
          services,
          isExpressFD: this.isExpressFD,
          expressFDDate: this.expressFDDate,
          expressFDFiles,
          isRejectFromExpressFD: this.isRejectFromExpressFD,
          isRejectByResultExpressFD: this.isRejectByResultExpressFD,

          notConfirmed,
          notConfirmedFiles
        }

        if (this.usingAppCopy) appData.sourceApplicationId = this.sourceApplicationId

        await createApplication(appData)

        this.$emit('requestWasCreated')

        this.$message.success({
          message: 'Заявка успешно создана.',
          customClass: 'request-create-success-message'
        })

        this.dialogIsVisible = false
      } catch (e) {
        this.$message.error({
          message: 'При создании заявки произошла ошибка.' + e,
          customClass: 'request-create-error-message'
        })

        throw e
      }

      this.applicationFormIsLoading = false
    },
    async saveDeclarerCitizen () {
      return await createCitizen(this.declarerCitizenInfo)
    },
    async saveRecieverCitizen () {
      this.packAddressInCitizenInfo()
      const citizen = Object.assign({}, this.recieverCitizenInfo)
      citizen.phone = Number(citizen.phone.replace(/[^+\d]/g, ''))
      citizen.carantinStatusId = this.carantinStatusId

      citizen.name = this.computedRecieverName.trim()
      citizen.surname = this.computedRecieverSurname.trim()
      citizen.patronymic = this.computedRecieverPatronymic.trim()

      if (!citizen.passportDate) citizen.passportDate = null

      return await createCitizen(citizen)
    },
    validateAppBeforeSave () {
      let recieverFormValid = false
      let declarerFormValid = false

      let allFormIsValid = true

      const errors = []

      this.passportShowMessage = true

      this.$refs.recieverForm.validate((valid, object) => {
        if (valid) recieverFormValid = true
        else recieverFormValid = false
      })

      this.$refs.declarerForm.validate((valid, object) => {
        if (valid) declarerFormValid = true
        else declarerFormValid = false
      })

      if (!recieverFormValid || !declarerFormValid) {
        errors.push('Заполните все обязательные поля формы.')
        allFormIsValid = false
      }

      if (!this.selectedQuestions.length) {
        errors.push('Необходимо выбрать хотя бы одну категорию.')
        allFormIsValid = false
      }

      if (!this.selectedServiceTypes.length) {
        errors.push('Необходимо выбрать хотя бы одну услугу.')
        allFormIsValid = false
      }

      if (!allFormIsValid) {
        const message = errors.map(item => `<div> - ${item}</div>`).join(' ')
        if (errors.length) this.$notify.error({ message, dangerouslyUseHTMLString: true })

        return false
      }

      return true
    },
    quesitionsRowAgeDisabledClass ({ row }) {
      return row.name.includes('6') ? `id-${row.id} row-disabled` : `id-${row.id}`
    },

    serviceTypeisDisabled (serviceId) {
      return (serviceId === 5 && !this.recieverCitizenInfo?.residenceAddressInfo?.areaId && !this.recieverCitizenInfo?.residenceAddressInfo.districtId)
    },

    handleServiceSelectionChange (selection) {
      this.selectedServiceTypes = selection
    },
    handleServiceRowClick (row, col, e) {
      this.currentService = row

      if (e.target.tagName === 'DIV') this.$refs.serviceTable.toggleRowSelection(row)

      if (this.usingAppCopy) {
        const disabledRow = this.serviceTypes.find(item => item.name && item.name.includes('1.2'))
        this.$refs.serviceTable.toggleRowSelection(disabledRow, false)

        const requiredRow = this.serviceTypes.find(item => item.name && item.name.includes('1.1'))
        this.$refs.serviceTable.toggleRowSelection(requiredRow, true)
      }

      if (!this.recieverCitizenInfo.age || this.recieverCitizenInfo.age < 65) {
        const row18 = this.serviceTypes.find(item => item.id === 18)
        this.$refs.serviceTable.toggleRowSelection(row18, false)
      }
    },
    handleServiceSelect (selection, row) {
      this.currentService = row
    },
    handleQuestionsSelect (value) {
      this.selectedQuestions = value
    },
    handleQuestionsCurrentChange (row, col, e) {
      if (e.target.tagName === 'DIV') this.$refs.questionsTable.toggleRowSelection(row)
    },
    resetFields (done) {
      this.recieverCitizenInfo = Object.assign({}, this.recieverCitizenInfoClearCopy)

      this.selectedServiceTypes.forEach(item => {
        item.description = ''
      })

      this.selectedServiceTypes = []
      this.selectedQuestions = []

      this.foundAreas = []
      this.foundDistricts = []
      this.residenceFoundAreas = []
      this.residenceFoundDistricts = []

      this.registryCheckedStatus = 'Ожидает проверки'
      this.registryCheckedTagType = 'info'
      this.registryChecked = false
      this.purchaseMedicinesErrorMessage = ''

      this.comment = ''
      this.situationDescription = ''

      this.isExpressFD = false
      this.isRejectFromExpressFD = false
      this.isRejectByResultExpressFD = false
      this.expressFDDate = null
      this.expressFDFiles = []

      this.recieverCitizenInfo.registerAddressInfo = {
        address: '',
        fiasGuid: '',
        districtId: '',
        areaId: '',
        apartment: ''
      }

      this.recieverCitizenInfo.residenceAddressInfo = {
        address: '',
        fiasGuid: '',
        districtId: '',
        areaId: '',
        apartment: ''
      }

      this.coincidesApplicant = false
      this.registerAddressCopy = false
      this.manually = false
      this.residenceManually = false
      this.passportShowMessage = false

      this.notConfirmed = []
      this.notConfirmedFiles = []

      this.medicalOrganizationId = null

      this.$refs.recieverForm.resetFields()
      this.$refs.declarerForm.resetFields()

      this.closeDialogIsVisible = false

      this.$emit('close')
    },
    packAddressInCitizenInfo () {
      this.recieverCitizenInfo.address = this.computedAddress

      if (!this.manually) {
        this.recieverCitizenInfo.fiasGuid = this.recieverCitizenInfo.registerAddressInfo.fiasGuid
        this.recieverCitizenInfo.apartment = this.recieverCitizenInfo.registerAddressInfo.apartment
      }

      if (!this.residenceManually) {
        this.recieverCitizenInfo.residenceFiasGuid = this.recieverCitizenInfo.residenceAddressInfo.fiasGuid
        this.recieverCitizenInfo.residenceApartment = this.recieverCitizenInfo.residenceAddressInfo.apartment
      }

      if (this.registerAddressCopy) this.recieverCitizenInfo.residenceAddress = this.computedAddress
      else this.recieverCitizenInfo.residenceAddress = this.computedResidenceAddress

      if (this.recieverCitizenInfo.registerAddressInfo.districtId) {
        this.recieverCitizenInfo.districtId = this.recieverCitizenInfo.registerAddressInfo.districtId
      }

      if (this.recieverCitizenInfo.registerAddressInfo.areaId) {
        this.recieverCitizenInfo.areaId = this.recieverCitizenInfo.registerAddressInfo.areaId
      }

      if (this.recieverCitizenInfo.residenceAddressInfo.districtId) {
        this.recieverCitizenInfo.residenceDistrictId = this.recieverCitizenInfo.residenceAddressInfo.districtId
      }

      if (this.recieverCitizenInfo.residenceAddressInfo.areaId) {
        this.recieverCitizenInfo.residenceAreaId = this.recieverCitizenInfo.residenceAddressInfo.areaId
      }

      if (this.registerAddressCopy) {
        if (this.recieverCitizenInfo.registerAddressInfo.districtId) this.recieverCitizenInfo.residenceDistrictId = this.recieverCitizenInfo.registerAddressInfo.districtId
        if (this.recieverCitizenInfo.registerAddressInfo.areaId) this.recieverCitizenInfo.residenceAreaId = this.recieverCitizenInfo.registerAddressInfo.areaId

        if (!this.residenceManually) {
          this.recieverCitizenInfo.residenceFiasGuid = this.recieverCitizenInfo.registerAddressInfo.fiasGuid
          this.recieverCitizenInfo.residenceApartment = this.recieverCitizenInfo.registerAddressInfo.apartment
        }
      }
    },
    servicesTableRowClassName ({ row }) {
      return `id-${row.id}`
    },

    async saveExpressFDFiles () {
      const requests = []

      this.expressFDFiles.forEach(({ raw, uid }) => {
        requests.push(uploadApplicationFiles(raw, uid))
      })

      return Promise.all(requests).then((res) => res.map(r => r.data.uri))
    },

    handleUploadExpressFDFiles (file) {
      this.expressFDFiles.push(file)
    },

    handleRemoveExpressFDFile ({ uid }) {
      this.expressFDFiles = this.expressFDFiles.filter((e) => e.uid !== uid)
    },

    handleNotConfirmedSelect (value) {
      const diffIds = _.differenceBy(this.notConfirmedOptionsList, value, 'date').map((e) => e.id)

      if (diffIds.length) {
        this.notConfirmedOptionsList.forEach((el) => {
          if (diffIds.includes(el.id)) delete el.date
        })
      }

      this.notConfirmed = value
    },

    handleNotConfirmedRowClick (row, _col, e) {
      if (e.target.tagName === 'DIV') this.$refs.notConfirmedTable.toggleRowSelection(row)
    },

    async saveNotConfirmedFiles () {
      const requests = []

      this.notConfirmedFiles.forEach(({ raw, uid }) => {
        requests.push(uploadApplicationFiles(raw, uid))
      })

      return Promise.all(requests).then((res) => res.map(r => r.data.uri))
    },

    handleUploadNotConfirmedFiles (file) {
      this.notConfirmedFiles.push(file)
    },

    handleRemoveNotConfirmedFile ({ uid }) {
      this.notConfirmedFiles = this.notConfirmedFiles.filter((e) => e.uid !== uid)
    }
  }
}
</script>

<style lang="sass">
.checkbox-group-styled
  margin-right: 10px

.create-app-dialog-container
  .el-divider__text
    color: rgb(0, 153, 255)

  .el-divider
    background-color: #41b3ff

  .el-dialog__title
    padding-left: 20px
  // .section-title
  //   font-size: 16px;
  .el-date-editor.el-input, .el-date-editor.el-input__inner
    width: 80% !important

  .el-dialog
    min-width: 850px

  .el-table__row
    cursor: pointer

  .row-disabled
    background-color: #F5F7FA

  .el-table__row
    &:hover
      background-color: #F2F6FC !important

  .el-tag.el-tag--danger.el-tag--light
    border-color: #fef0f0

  .el-tag.el-tag--info.el-tag--light
    border-color: #f4f4f5

  .el-tag.el-tag--success.el-tag--light
    border-color: #f0f9eb

  .is-leaf
    display: none
</style>
