/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.applications.changeStatus

export default async (applicationData) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: url,
      data: applicationData
    })

    return data
  } catch (error) {
    throw error
  }
}
