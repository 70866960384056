/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'
import storageConfig from '@/config/storageConfig'

const serviceTypesStorageName = 'service-types'
const url = endpoints.references.serviceTypes.find

export default async () => {
  try {
    const storedServiceTypes = JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${serviceTypesStorageName}`))

    if (storedServiceTypes && storedServiceTypes.length) return storedServiceTypes

    const { data: { content } } = await axios({
      method: 'GET',
      url,
      params: {
        rsql: 'group!=0',
        size: 100,
        sort: 'name'
      }
    })

    sessionStorage.setItem(`${storageConfig.storagePrefix}${serviceTypesStorageName}`, JSON.stringify(content))
    return content
  } catch (error) {
    throw error
  }
}
