/* eslint-disable quote-props */
import axios from 'axios'
import endpoints from '@/api/endpoints'
import qs from 'qs'

const url = endpoints.file.downloadExcel

const downloadExcelFile = async ({ dateRangeArray }, columns) => {
  if (dateRangeArray.length < 2) return new Error('Date prop must have 2 string dates')

  const params = {
    'date_from': dateRangeArray[0].split(' ').join('T'),
    'date_to': dateRangeArray[1].split(' ').join('T'),
    columns
  }

  try {
    const response = await axios({
      method: 'GET',
      url,
      responseType: 'blob',
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    })

    return response
  } catch (error) {
    const errorObject = await getJsonFromBlob(error.response.data)
    throw errorObject
  }
}

function getJsonFromBlob (blob) {
  const fr = new FileReader()
  return new Promise((resolve, reject) => {
    fr.onerror = () => {
      fr.abort()
      reject(new Error())
    }

    fr.onload = () => {
      resolve(JSON.parse(fr.result))
    }
    fr.readAsText(blob)
  })
}

export default downloadExcelFile
