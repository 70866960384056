<template lang="pug">
  #app
    router-view
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'

export default {
  components: {
    TheHeader
  }
}
</script>

<style lang="sass">
@font-face
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/roboto/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;

@font-face
  font-family: 'Roboto-Medium';
  src: url('./assets/fonts/roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;

html
  height: 100%;

body
  height: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

a
  color: rgb(30, 136, 229)

#app
  color: #2c3e50;
  height: 100%;

.el-button--text_icon
  vertical-align: middle;
  .icon, svg
    margin-right: 6px;
  &.is-loading
    .icon, svg
      display: none;

.custom-checkbox-group
  label.el-checkbox:not(:last-child)
    display: block;
    margin-bottom: 6px;

label.custom-checkbox.el-checkbox.is-disabled
  cursor: default;
  .el-checkbox__input
    .el-checkbox__inner
      &::after
        border-color: #303133 !important;
      background: #fff;
  .el-checkbox__label
    cursor: inherit;
    color: #606266;

.custom-form, .create-app-dialog-container
  .el-form-item__label
    color: #606266;
  .el-input.is-disabled, .el-textarea.is-disabled
    .el-input__inner, .el-textarea__inner
      border: 1px solid transparent !important;
      background-color: #F2F6FC;
      color: #303133;
      cursor: default !important;
      transition: all 0.1s ease-in-out;

  .el-textarea
    .el-textarea__inner
      font-family: 'Tahoma', sans-serif;

  .el-select, .el-date-editor.el-input
    width: 100%;

.el-button.cancel-button
    color: #909399;

.el-button.accept-button
    color: #67C23A;
    &:hover, &.is-loading
      color: #67C23A;

.custom-button
  font-size: 16px;
  svg
    margin-right: 5px;
</style>
