/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.applications.byId

export default async (applicationId) => {
  if (isNaN(applicationId) || applicationId === null || !applicationId) return

  try {
    const { data } = await axios({
      method: 'GET',
      url: url.replace('{id}', applicationId)
    })

    return data
  } catch (error) {
    throw error
  }
}
