import axios from 'axios'
import endpoints from '@/api/endpoints'
import storageConfig from '@/config/storageConfig'

const refDistrictsStorageName = 'ref-districts'
const url = endpoints.references.dictionaryDistricts.find

const fetchRefDistricts = async (params = { size: 200 }) => {
  try {
    const refDistricts = JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${refDistrictsStorageName}`))

    if (refDistricts && refDistricts.length) return refDistricts

    const { data: { content } } = await axios({
      method: 'GET',
      url,
      params
    })

    sessionStorage.setItem(`${storageConfig.storagePrefix}${refDistrictsStorageName}`, JSON.stringify(content))
    return content
  } catch (error) {
    return error
  }
}

export default fetchRefDistricts
