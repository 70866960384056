<template lang="pug">
  div.request-page(v-if="isApplicationCalled")
    el-row.flex.justify-center.error-block(v-if="error.isError")
      div
        div.message {{ error.message }}
        el-button(
          type="primary"
          @click="$router.push({ name: 'Registry' })"
        ) Вернуться к списку заявок

    el-row.flex.justify-center(v-else)
      el-col

        div.flex.align-center.justify-between.request-navigation-panel
          el-breadcrumb.request-page-breadcrumbs.align-center(separator-class="el-icon-arrow-right")
            el-breadcrumb-item Контакт центр "Социальная защита"
            el-breadcrumb-item(:to="{ name: 'Registry' }") Список заявок
            el-breadcrumb-item Заявка

        el-row.mb-20
          el-col
            request-main-info.request-page-main-info(
              :application="currentApplication"
              :sourceApplicationId="currentApplication.sourceApplicationId"
              :userExecutor="userExecutor"
              :services="currentApplicationServices"
              @createNewRequest='createNewRequest'
              @updateLocalApplicationStatus="updateLocalApplicationStatus"
              @updateLocalApplicationExecutor="updateLocalApplicationExecutor"
              @saveReciever="saveReciever"
              @openHistoryDialog='isHistoryDialogVisible = true'
            )

        el-row(:gutter="20").mb-20
          el-col(:span="12")
            request-reciever.request-page-reciever.mb-20(
              v-if="currentApplication.citizenRecieverId"
              ref="request-reciever"
              :application="currentApplication"
              @updateLocalApplicationExecutor="updateLocalApplicationExecutor"
              @updateLocalApplicationStatus="updateLocalApplicationStatus"
              @fetchApplication="fetchApplicationHandler"
            )
            request-express-f-d.request-page-express-fd.mb-20(
              :application="currentApplication"
            )
            request-not-confirmed.mb-20(
              v-if="currentApplication.notConfirmed.length"
              :application="currentApplication"
            )
            request-services.request-page-services(
              :services="currentApplicationServices"
              :applicationId="currentApplication.id"
            )
          el-col(:span="12").mb-20
            request-declarer.request-page-declarer(
              v-if="currentApplication.citizenDeclarerId"
              :citizenDeclarerId="currentApplication.citizenDeclarerId"
            )
          el-col(:span="12").mb-20
            request-categories.request-page-main-categories(
              :answers="currentApplicationAnswers"
            )
          el-col(:span="12").mb-20
            request-situation-description(
              :application="currentApplication"
            )
          el-col(:span="12").mb-20
            request-extra-data.request-page-comments(
              :isApplicationDataSaving="isApplicationDataSaving"
              :application="currentApplication"
              :execDate.sync="currentApplication.execDate"
              :comment.sync="currentApplication.comment"
              @saveApplication="saveApplication"
            )

        history-dialog(
          :is-visible.sync='isHistoryDialogVisible'
          :application-id='currentApplication.id'
          :reciever-id='currentApplication.citizenRecieverId'
        )
</template>
<script>
import RequestMainInfo from '@/components/Request/RequestMainInfo'
import RequestReciever from '@/components/Request/RequestReciever'
import RequestDeclarer from '@/components/Request/RequestDeclarer'
import RequestExtraData from '@/components/Request/RequestExtraData'
import RequestServices from '@/components/Request/RequestServices'
import RequestCategories from '@/components/Request/RequestCategories'
import RequestExpressFD from '@/components/Request/RequestExpressFD'
import RequestNotConfirmed from '@/components/Request/RequestNotConfirmed'
import RequestSituationDescription from '@/components/Request/RequestSituationDescription'
import HistoryDialog from '@/components/Request/HistoryDialog'
import fetchApplication from '@/api/apiCalls/application/fetchApplication'
import saveApplication from '@/api/apiCalls/application/saveApplication'
import fetchUserDataById from '@/api/apiCalls/user/fetchUserDataById'

export default {
  name: 'RequestPage',
  components: {
    RequestReciever,
    RequestDeclarer,
    RequestMainInfo,
    RequestExtraData,
    RequestServices,
    RequestCategories,
    RequestExpressFD,
    RequestNotConfirmed,
    HistoryDialog,
    RequestSituationDescription
  },
  data () {
    return {
      error: {
        isError: false,
        message: ''
      },
      isApplicationCalled: false,
      isApplicationDataSaving: false,
      isHistoryDialogVisible: false,
      currentApplication: {},
      currentApplicationAnswers: [],
      currentApplicationServices: [],
      currentReciever: {},
      userExecutor: {
        id: null,
        organization: ''
      }
    }
  },
  computed: {
    currentApplicationId () {
      return this.$route.params.id
    }
  },
  created () {
    this.fetchApplicationHandler()
  },
  methods: {
    createNewRequest () {
      sessionStorage.setItem('applicationCopy', JSON.stringify(this.currentApplication))
      this.$router.push('/registry')
    },
    async fetchApplicationHandler () {
      await this.fetchApplication()
      this.fetchApplicationExecutorDataById()
    },
    async fetchApplication () {
      if (isNaN(this.currentApplicationId)) {
        const messageText = `Заявки с id ${this.currentApplicationId} нет в базе`
        this.showErrorBlock(messageText)
        this.isApplicationCalled = true
        return
      }

      try {
        this.currentApplication = await fetchApplication(this.currentApplicationId)
        this.setAnswers()
        this.setServices()
      } catch (error) {
        const status = error.response ? error.response.status : null
        if (status === 404) {
          const messageText = `Заявки с id ${this.currentApplicationId} нет в базе`
          this.showErrorBlock(messageText)
        } else {
          const messageText = `При запросе заявки с id ${this.currentApplicationId} возникла ошибка`
          this.showErrorMessage(messageText)
        }
      } finally {
        this.isApplicationCalled = true
      }
    },

    async saveApplication (message = {
      success: 'Заявка успешно сохранена',
      error: 'При сохранении заявки возникла ошибка'
    }) {
      try {
        this.isApplicationDataSaving = true
        this.currentApplication = await saveApplication(this.currentApplication)
        this.setAnswers()
        this.setServices()
        this.showSuccessMessage(message.success)
      } catch (error) {
        this.showErrorMessage(message.error)
      } finally {
        setTimeout(() => {
          this.isApplicationDataSaving = false
        }, 500)
      }
    },

    async fetchApplicationExecutorDataById () {
      if (!this.currentApplication.userExecutorId || isNaN(this.currentApplication.userExecutorId)) {
        this.userExecutor = { id: null, organization: '' }
        return
      }

      try {
        const data = await fetchUserDataById(this.currentApplication.userExecutorId)
        this.userExecutor = data
      } catch (error) {
        const status = error.response ? error.response.status : null
        if (status === 404) {
          this.userExecutor.organization = `Пользователя с id ${this.userExecutorId} нет в базе`
        }
      }
    },

    saveReciever () {
      this.$refs['request-reciever'].saveReciever()
    },

    updateLocalApplicationStatus ({ id, name }) {
      this.currentApplication.applicationStatusId = id
      this.currentApplication.applicationStatusName = name
    },

    updateLocalApplicationExecutor (userExecutor) {
      this.currentApplication.userExecutorId = userExecutor.id === -1 ? null : userExecutor.id
      this.userExecutor = userExecutor
    },

    setRecieverData (currentReciever) {
      this.currentReciever = currentReciever
    },

    showErrorBlock (messageText) {
      this.error.isError = true
      this.error.message = messageText
    },

    showSuccessMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'success'
      })
    },

    showErrorMessage (messageText) {
      this.$message({
        message: messageText,
        type: 'error'
      })
    },

    setAnswers () {
      if (this.currentApplication.answers) this.currentApplicationAnswers = [...this.currentApplication.answers]
    },

    setServices () {
      if (this.currentApplication.services) this.currentApplicationServices = [...this.currentApplication.services]
    }
  }
}
</script>
<style lang="sass">
.request-page
  padding : 20px;
  width: 75%;
  min-width: 1152px;
  box-sizing: border-box;

  .error-block
    height: calc(100vh - 120px);
    min-heigh: 200px;
    align-items: center;
    div
      text-align: center;
      .message
        font-size: 20px;
      button
        margin-top: 30px;

  div.request-navigation-panel
    margin-bottom: 20px;
    font-size: 16px;

// Стили для печати заявки
@media print
  .request-page
    padding: 10px 85px 10px 10px;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    .request-navigation-panel
      display: none;
    button
      display: none;

    .request-page-main-info,
      font-size: 18px;
      .tag
        font-size: 16px;

    .request-page-reciever,
    .request-page-declarer
      .el-form-item__label
        font-size: 14px;
        line-height: 20px;
      .el-form-item__content
        .el-input
          font-size: 16px;
        .passport-date.el-input
          font-size: 16px;
      .apartment-input
        input
          padding: 0 0 0 10px;
          font-size: 14px;

    .request-page-declarer
      .el-form-item__content
        .el-input
          border: 1px solid #f1f1f1;

    .request-page-main-categories,
    .request-page-services
      .custom-checkbox-group
        .custom-checkbox
          .el-checkbox__label
            font-size: 20px;
          .el-checkbox__inner
            width: 18px;
            height: 18px;
            &:after
              height: 12px;
              left: 6px;

    .services-box-card
      .custom-checkbox-group
        .el-checkbox__input
          margin-top: 0;

    .request-page-comments
      textarea
        font-size: 20px;
</style>
