<template lang="pug">
  el-card.box-card(shadow="never")
    slot(slot="header" name="header")
    slot(name="content")
</template>
<script>
export default {
  name: 'Card'
}
</script>
<style lang="sass">
  .box-card
    .el-card__header
      color: #606266;
      font-weight: 500;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      line-height: 23px;
      .el-button--text
        padding: 3px 0;

</style>
