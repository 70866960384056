<template lang="pug">
  el-form.registry-table-filter-form(:model="filterOptions")
    .flex
      el-form-item.registry-table-filter-form_id-filter
        el-input(
          id="id-filter-input"
          :value="filterOptions.idAsText"
          @input="$emit('idAsTextFilterChange', $event.trim())"
          placeholder="id"
          clearable
        )
      el-form-item.registry-table-filter-form_fio-filter
        el-input(
          id="fio-filter-input"
          :value="filterOptions.receiver_fio"
          @input="$emit('recieverFioFilterChange', $event)"
          placeholder="ФИО получателя"
          clearable
        )
      el-form-item.registry-table-filter-form_birthday-filter
        el-date-picker(
          id="birthday-filter-input"
          popper-class="reciever-birthday-picker"
          format='dd.MM.yyyy'
          value-format='dd.MM.yyyy'
          :value="filterOptions.citizenRecieverBirthday"
          @input="$emit('recieverBirthdayFilterChange', $event)"
          v-mask='"99.99.9999"'
          placeholder="Дата рождения получателя"

        )
      el-form-item
        el-checkbox(
          id="registerd-address-edit-mode-checkbox"
          @input="$emit('recieverIsDeadFilterChange', $event)"
          :value="filterOptions.citizenRecieverIsDead"
        ) Получатель умер
    .flex
      el-form-item.registry-table-filter-form_phone-filter
        el-input(
          id="phone-filter-input"
          :value="filterOptions.receiver_phone"
          @input="recieverPhoneFilterChange"
          placeholder="Телефон получателя"
          clearable
          v-mask="'+7 (999) 999-99-99'"
        )
      el-form-item
        el-select(
          id="status-filter-select"
          v-show="!userAbility.can('readOnly', 'draftApplications')"
          :value="filterOptions.applicationStatusId"
          @change="$emit('applicationStatusIdFilterChange', $event)"
          placeholder="Все статусы"
          clearable
        )
          el-option(
            v-for="option in applicationStatusesOptionsByUserRole"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          )
      el-form-item
        el-select(
          id="type-filter-select"
          :value="filterOptions.applicationTypeId"
          @change="$emit('applicationTypeIdFilterChange', $event)"
          placeholder="Все исполнители"
          clearable
        )
          el-option(
            v-for="option in applicationTypes"
            :key="option.id"
            :label="option.prefix"
            :value="option.id"
          )
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Inputmask from 'inputmask'

export default {
  name: 'RegistryTableFilter',
  props: {
    filterOptions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      localFilterOprions: {}
    }
  },
  computed: {
    ...mapState('auth', {
      userAbility: state => state.userAbility
    }),

    ...mapState('references', {
      applicationStatusesOptionsByUserRole: state => state.applicationStatusesByUserRole,
      applicationTypes: state => state.applicationTypes
    })
  },
  created () {
    this.fetchApplicationStatusesOptionsByUserRole()
  },
  methods: {
    ...mapActions('references', {
      fetchApplicationStatusesOptionsByUserRole: 'fetchApplicationStatusesByUserRole'
    }),

    recieverPhoneFilterChange (value) {
      const phone = Inputmask.unmask(value, 99999999999)
      this.$emit('recieverPhoneFilterChange', phone.substr(1))
    }
  }
}
</script>
<style lang="sass">
  .registry-table-filter-form
    width: 100%
    .el-form-item
      margin-bottom: 10px
      margin-right: 10px
      flex: 1
      &:last-child
        margin-right: 0
      .el-select
        width: 100%

    .test
      border: 1px solid black
      height: 100px

    .registry-table-filter-form_id-filter
      max-width: 120px
    .registry-table-filter-form_fio-filter
      min-width: 280px
    .registry-table-filter-form_phone-filter
      min-width: 160px
    .registry-table-filter-form_birthday-filter
      min-width: 160px
      .el-date-editor.el-input
        width: 100%

</style>
