import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faDoorOpen,
  faQuestionCircle,
  faUserCircle,
  faFileExcel,
  faFileAlt,
  faEdit,
  faPencilAlt,
  faTimes,
  faCheck,
  faProcedures,
  faPlusSquare,
  faWalking,
  faHeartBroken,
  faVirus,
  faVirusSlash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faDoorOpen,
  faQuestionCircle,
  faUserCircle,
  faFileExcel,
  faFileAlt,
  faEdit,
  faPencilAlt,
  faTimes,
  faCheck,
  faProcedures,
  faPlusSquare,
  faWalking,
  faHeartBroken,
  faVirus,
  faVirusSlash
)

export default FontAwesomeIcon
