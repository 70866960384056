/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.auth.logout

export default async () => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: url
    })

    return data
  } catch (error) {
    throw error
  }
}
