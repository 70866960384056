/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.citizens.byId

export default async (citizenId) => {
  if (isNaN(citizenId) || citizenId === null || !citizenId) return

  try {
    const { data } = await axios({
      method: 'GET',
      url: url.replace('{id}', citizenId)
    })

    return data
  } catch (error) {
    throw error
  }
}
