/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'
// import storageConfig from '@/config/storageConfig'

// const applicationStatusesByUserRoleStorageName = 'application-statuses-options-by-user-role'
const url = endpoints.references.applicationStatuses.byUserRole

export default async () => {
  try {
    // const storedApplicationStatusesByUserRoleOptions = JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${applicationStatusesByUserRoleStorageName}`))

    // console.log(storedApplicationStatusesByUserRoleOptions)
    // if (storedApplicationStatusesByUserRoleOptions && storedApplicationStatusesByUserRoleOptions.length) return storedApplicationStatusesByUserRoleOptions

    const { data } = await axios({
      method: 'GET',
      url
    })

    // sessionStorage.setItem(`${storageConfig.storagePrefix}${applicationStatusesByUserRoleStorageName}`, JSON.stringify(data))
    return data
  } catch (error) {
    throw error
  }
}
