<template lang='pug'>
  box-card
    template(slot="header") Информация о заявителе
    template(slot="content")
      el-form.declarer-edit-form(disabled)
        el-row(:gutter='20')
          el-col(:span='12')
            el-form-item(label='Фамилия' )
              el-input(id="declarer-surname-input" v-model='currentDeclarer.surname')
          el-col(:span='12')
            el-form-item(label='Имя' )
              el-input(id="declarer-name-input" v-model='currentDeclarer.name')
        el-row(:gutter='20')
          el-col(:span="12")
            el-form-item(label='Отчество' )
              el-input(id="declarer-patronymic-input" v-model='currentDeclarer.patronymic')
          el-col(:span="12")
            el-form-item(label='Мобильный телефон' )
              el-input(id="declarer-phone-input" v-model='currentDeclarer.phone' v-mask="phoneMask")

</template>

<script>
import BoxCard from '@/components/BoxCard'
import fetchCitizen from '@/api/apiCalls/citizen/fetchCitizen'

const phoneMask = '+9 (999) 999-99-99'

export default {
  name: 'RequestDeclarer',
  components: {
    BoxCard
  },
  props: {
    citizenDeclarerId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      currentDeclarer: {
        surname: '',
        name: '',
        patronymic: '',
        phone: ''
      }
    }
  },
  computed: {
    phoneMask () {
      return phoneMask
    }
  },
  created () {
    if (this.citizenDeclarerId) this.fetchDeclarer()
  },
  methods: {
    async fetchDeclarer () {
      this.currentDeclarer = await fetchCitizen(this.citizenDeclarerId)
    }
  }
}
</script>
<style lang="sass">
  .declarer-edit-form
    .el-form-item__label
      color: #606266;
    .el-input.is-disabled
      .el-input__inner
        border: 1px solid transparent !important;
        // background-color: #F2F6FC;
        background-color: transparent;
        color: #303133;
        cursor: default !important;
</style>
