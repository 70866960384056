<template lang="pug">
  box-card
    template(slot="header") Категории
    template(slot="content")
      el-form(disabled)
        el-checkbox-group.custom-checkbox-group(id="categories-checkbox-group" :value="computedAnswers")
          el-checkbox.custom-checkbox(v-for="category in computedCategoriesOptions" :key="category.id" :label="category.id" :id="`id-${category.id}`") {{ category.name }}

</template>
<script>
import BoxCard from '@/components/BoxCard.vue'
import fetchQuestions from '@/api/apiCalls/references/fetchQuestions'
import userType from '@/config/userType'

export default {
  name: 'RequestCategories',
  components: {
    BoxCard
  },
  props: {
    answers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      categoriesOptions: [],
      checkList: []
    }
  },
  computed: {
    computedAnswers () {
      return this.answers.map(answer => answer.value ? answer.questionId : false)
    },
    computedCategoriesOptions () {
      return this.categoriesOptions && this.categoriesOptions.filter(option => {
        return option.userType === userType
      })
    }
  },
  created () {
    this.fetchCategories()
  },
  methods: {
    async fetchCategories () {
      this.categoriesOptions = await fetchQuestions()
    }
  }
}
</script>
<style lang="sass"></style>
