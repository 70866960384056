<template lang='pug'>
  div.layout
    the-header
    transition(name='el-fade-in' mode='out-in' appear)
      router-view(
        v-if="isAppReady"
        @showCsvQueueNotification="showCsvQueueNotification($event)"
        @fetchQueuedCsvFileLinkHandler="fetchQueuedCsvFileLinkHandler"
        @downloadCsvFileHandler="downloadCsvFileHandler"
        @closeCsvQueueConnection="closeCsvQueueConnectionHandler"
      )
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TheHeader from '@/components/TheHeader.vue'

export default {
  name: 'Layout',
  components: {
    TheHeader
  },
  data () {
    return {
      isAppReady: false,
      csvFileDownloadStarted: false
    }
  },
  computed: {
    ...mapState('auth', {
      isRolesSetUp: state => state.rolesList.length,
      userAbility: state => state.userAbility
    }),

    ...mapState('systemParams', {
      systemParams: state => state.systemParams
    }),

    ...mapState('csvQueues', {
      activeQueueParams: state => state.activeQueueParams,
      csvFileLink: state => state.csvFileLink,
      fileLiveDuration: state => state.fileLiveDuration,
      queueLiveDuration: state => state.queueLiveDuration
    }),

    ...mapGetters('csvQueues', {
      isCsvQueueActive: 'isCsvQueueActive'
    }),

    unload () {
      return this.closeCsvQueueConnection(false)
    }
  },
  async created () {
    this.addUnloadEventListener()
    await this.fetchUsersRoles()
    await this.setUserAbility()
    if (!Object.keys(this.systemParams).length) await this.fetchSystemParams()
    this.isAppReady = true
    // check if any csv queue is active
    this.checkCsvQueueHandler()
  },
  beforeDestroy () {
    this.closeCsvQueueConnectionHandler(false)
  },
  methods: {
    ...mapActions('auth', {
      fetchUsersRoles: 'fetchUsersRoles',
      setUserAbility: 'setUserAbility'
    }),

    ...mapActions('systemParams', {
      fetchSystemParams: 'fetchSystemParams'
    }),

    ...mapActions('csvQueues', {
      downloadCsvFile: 'downloadCsvFile',
      checkCsvQueue: 'checkCsvQueue',
      fetchQueuedCsvFileLink: 'fetchQueuedCsvFileLink',
      closeCsvQueueConnection: 'closeCsvQueueConnection'
    }),

    async downloadCsvFileHandler (link) {
      // console.group('Download file handler')
      // console.log('link', link)
      // console.groupEnd()
      if (this.csvFileDownloadStarted) return
      this.csvFileDownloadStarted = true
      this.showCsvQueueNotification('downloading')
      try {
        await this.downloadCsvFile(link)
        clearTimeout(this.fileTimer)
      } catch (error) {
        const message = error.message || ''
        this.showMessage({ type: 'error', showClose: false, message, duration: 2000 })
      } finally {
        setTimeout(() => {
          this.closeCsvQueueConnection()
          this.hideCsvQueueNotification()
          this.csvFileDownloadStarted = false
        }, 1000)
      }
    },

    async checkCsvQueueHandler () {
      // check if csv file link available
      if (this.csvFileLink.length) {
        // console.log(this.csvFileLink)
        this.showCsvQueueNotification('success', this.csvFileLink)
        return
      }

      // check if any csv queue is active
      if (!this.isCsvQueueActive) return
      // check if active csv queue is alive
      const isQueueLiveTimeExpired = await this.checkCsvQueue()
      if (isQueueLiveTimeExpired) {
        this.showCsvQueueNotification('expired')
        await this.closeCsvQueueConnection()
        setTimeout(() => {
          this.hideCsvQueueNotification()
        }, 2000)
      } else {
        this.fetchQueuedCsvFileLinkHandler()
      }
    },

    async fetchQueuedCsvFileLinkHandler () {
      this.showCsvQueueNotification('inProgress')

      try {
        const queueTimer = setTimeout(() => {
          // console.log('set queue timer')
          this.closeCsvQueueConnection()
          this.showCsvQueueNotification('expired')
        }, this.queueLiveDuration)

        await this.fetchQueuedCsvFileLink()
        this.showCsvQueueNotification('success', this.csvFileLink)

        clearTimeout(queueTimer)

        this.fileTimer = setTimeout(() => {
          // console.log('set file timer')
          this.closeCsvQueueConnection()
          this.showCsvQueueNotification('deadLink')
        }, this.fileLiveDuration)
      } catch (error) {
        // console.dir(error)
        this.showCsvQueueNotification('error')
        setTimeout(() => {
          this.closeCsvQueueConnection()
          this.hideCsvQueueNotification()
        }, 4000)
      }
    },

    async closeCsvQueueConnectionHandler (showMessage = true) {
      clearTimeout(this.fileTimer)
      // console.log(this.fileTimer)
      await this.closeCsvQueueConnection()
      if (showMessage) {
        this.showCsvQueueNotification('cancelled')
      } else {
        this.hideCsvQueueNotification()
      }
    },

    showCsvQueueNotification (name = 'inProgress', link = '') {
      // console.group('Show notification')
      // console.log('name', name)
      // console.log('name', link)
      // console.groupEnd()
      this.$notify.closeAll()
      const title = 'Выгрузка CSV'
      const notifications = [
        {
          name: 'inProgress',
          customClass: 'pointer',
          message: '<i class="fas fa-spinner spinner" style="color: #409EFF";></i> Идет формирование файла  <br/><br/><a class="pseudo-link">Отменить выгрузку</a>',
          onClick: this.closeCsvQueueConnectionHandler
        },
        {
          name: 'error',
          type: 'error',
          message: 'При формировании файла возникла ошибка. Попробуйте еще раз.'
        },
        {
          name: 'success',
          customClass: 'pointer',
          type: 'success',
          message: 'Файл успешно сформирован <br/><a>Скачать</a>',
          onClick: () => { this.downloadCsvFileHandler(link) }
        },
        {
          name: 'expired',
          type: 'info',
          message: 'Время ожидания формирования файла истекло.</br></br>Попробуйте еще раз.',
          showClose: true,
          duration: 0
        },
        {
          name: 'deadLink',
          type: 'info',
          message: 'Ссылка на запрошенный файл устарела.</br></br>Попробуйте еще раз.',
          showClose: true,
          duration: 0
        },
        {
          name: 'cancelled',
          type: 'info',
          message: 'Выгрузка файла отменена',
          showClose: true,
          duration: 3000
        },
        {
          name: 'downloading',
          type: 'info',
          message: '<i class="fas fa-spinner spinner" style="color: #409EFF";></i> Идет скачивание файла',
          showClose: false,
          duration: 0
        }
      ]

      const currentNotification = notifications.find(notification => notification.name === name)

      this.$notify({
        title,
        position: 'bottom-left',
        showClose: false,
        customClass: 'csv-notification',
        dangerouslyUseHTMLString: true,
        duration: 0,
        ...currentNotification
      })
    },

    hideCsvQueueNotification () {
      this.$notify.closeAll()
    },

    showMessage ({ type = '', showClose = true, message, dangerouslyUseHTMLString = false, duration }) {
      this.$message({
        type,
        showClose,
        message,
        dangerouslyUseHTMLString,
        duration
      })
    },

    addUnloadEventListener () {
      // console.log('add event listener')
      // window.addEventListener('unload', this.unload)
      window.onbeforeunload = () => { this.closeCsvQueueConnection(false) }
    }

    // removeUnloadEventListener () {
    //   window.removeEventListener('unload', this.unload)
    // }
  }
}
</script>

<style lang="sass">
  .layout
    display: flex
    justify-content: center
    flex-wrap: wrap
    min-width: 1152px

  .csv-notification
    .el-notification__content
      text-align: left

  .pointer
    cursor: pointer

  .spinner
    animation: spin 2s linear infinite
    margin-right: 5px
  .pseudo-link
    &:hover
      text-decoration: underline

  @keyframes spin
    0%
      transform: rotate(0deg)
    100%
      transform: rotate(360deg)
</style>
