import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.file.uploadApplicationFile

export default (file, uid) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('guid', uid)

  return axios({
    method: 'POST',
    url,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
