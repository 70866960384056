export default {
  // DRAFT: 1,
  // REJECTED: 2,
  // ASSIGNED_TO_OSO: 4,
  // PHONED: 6,
  // WITH_CARD: 8,
  // DIRECTED_TO_CSO: 9,
  // STATUS_VOLUNTEER: 11
  NEW: 1,
  IG_PROGRESS: 2,
  DONE: 3,
  REJECT_EXECUTOR: 4,
  REJECT_FD: 5
}
