import axios from 'axios'
import store from '@/store'
import httpErrors from './config/httpErrors'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = process.env.VUE_APP_TEST_API_URL
}

axios.interceptors.request.use(config => {
  const accessToken = store.state.auth.accessToken

  if (accessToken) {
    config.headers.Authorization = accessToken
    // config.headers['X-Requested-With'] = 'XMLHttpRequest'
  }

  return config
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  const authCallContext = 'profile-full'
  const status = error.response ? error.response.status : null
  const message = error.response ? error.response.data.error : ''
  const url = error.config.url

  function checkIsUserUnauthorized () {
    return status === httpErrors.unauthorized.status && message === httpErrors.unauthorized.message
  }

  function checkIsAuthCall () {
    return url.split('/').includes(authCallContext)
  }

  if (checkIsUserUnauthorized() && !checkIsAuthCall()) {
    store.dispatch('auth/authLogout')
  } else {
    throw error
  }
})

export default axios
