// export default {
//   OPERATOR: 'OPERATOR',
//   CONTROllER: 'CONTROllER',
//   COUNTYDISPATCHER: 'COUNTYDISPATCHER',
//   AREAMANAGER: 'AREAMANAGER',
//   OSO: 'OSO',
//   DTSZN: 'DTSZN',
//   DJKH: 'DJKH',
//   OSZN: 'OSZN',
//   DZM: 'DZM',
//   SBERBANK: 'SBERBANK',
//   DZMDISPATCHER: 'DZMDISPATCHER',
//   INCREASEDSECURITY: 'INCREASEDSECURITY',
//   ADMIN_CC: 'ADMIN_CC',
//   VOLUNTEER_DISTRICT: 'VOLUNTEER_DISTRICT',
//   VOLUNTEER_CONTROLLER: 'VOLUNTEER_CONTROLLER'
// }

export default {
  // Администратор
  ADMIN: 'ADMIN',
  // Социальный работник
  SC: 'SC',
  // Сектор "Мобильная социальная служба"
  SMSS: 'SMSS',
  // Департамент труда и социальной защиты населения
  DTSZN: 'DTSZN',
  // Управление реабилитации ДТСЗН
  DTSZN_REHABILITATION: 'DTSZN_REHABILITATION',
  // Управление по работе с несовершенолетними ДТСЗН
  DTSZN_MINORS: 'DTSZN_MINORS',
  // Управление по работе с ветеранами ДТСЗН
  DTSZN_VETERANS: 'DTSZN_VETERANS',
  // Управление стационарного социального обслуживания ДТСЗН
  DTSZN_SSS: 'DTSZN_SSS',
  // ГКУ Центр Социальной Адаптации имени Е.П. Глинки
  CSA: 'CSA',
  // Управление социальной защиты населения по административному округу
  USZN: 'USZN',
  // Московская служба психологической помощи населения
  MSPPN: 'MSPPN'
}
