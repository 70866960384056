import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import FontAwesomeIcon from './plugins/font-awesome'
import '@/assets/main.scss'
import { abilitiesPlugin } from '@casl/vue'
import Inputmask from 'inputmask'
import Maska from 'maska'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(abilitiesPlugin)
Vue.use(ElementUI, { locale })
Vue.use(Maska)

Vue.directive('mask', {
  bind: function (el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0])
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
