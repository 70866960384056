const applicationModel = () => ({
  applicationStatusComment: '',
  applicationStatusId: null,
  applicationStatusName: '',
  applicationTypeId: null,
  applicationTypePrefix: '',
  citizenDeclarerFIO: '',
  citizenDeclarerId: null,
  citizenRecieverAreaName: '',
  citizenRecieverFIO: '',
  citizenRecieverId: null,
  comment: '',
  createdAt: '',
  id: null,
  userAuthorId: null,
  userExecutorId: null
})

const applicationModelTranslate = {
  model: {
    id: '#',
    createdAt: 'Дата создания',
    citizenDeclarerFIO: 'Заявитель',
    applicationTypePrefix: 'Исполнитель',
    citizenRecieverFIO: 'Получатель',
    citizenRecieverBirthday: 'Дата рождения получателя',
    citizenRecieverPhone: 'Телефон получателя',
    applicationStatusName: 'Статус'
  },

  get translate () {
    return this.model
  },

  set translate ({ key, value }) {
    this.model[key] = value
  }
}

export { applicationModel, applicationModelTranslate }
