// Низкоуровневые правила создаются в формате
// названиеПравила: {
//   subject: 'Название субъекта правила' - это сущность (заявка, сотрудники, документы, бизнес функции и т.д.)
//   action: 'Название действия, совершаемого на субъектом' - это действие (прочитать, записать, сохранить, удалить, скачать и т.д.)
// }
// названиеПравила = action + Subject

export default {
  // Право совершать выгрузки
  downloadExcel: {
    subject: 'excel',
    action: 'download'
  },
  // Право совершать выгрузки
  downloadCsv: {
    subject: 'csv',
    action: 'download'
  },
  // Право создавать заявку *
  createApplication: {
    subject: 'application',
    action: 'create'
  },

  // Право редактировать заявку *
  editApplication: {
    subject: 'application',
    action: 'edit'
  },

  // Право менять статус *
  changeStatus: {
    subject: 'status',
    action: 'change'
  },

  // Право читать только заявки в статусе "Черновик"
  readOnlyDraftApplications: {
    subject: 'draftApplications',
    action: 'readOnly'
  },
  // Право возвращать заявку в район
  returnToArea: {
    subject: 'area',
    action: 'returnTo'
  },
  // Право назначать исполнителя
  changeExecutor: {
    subject: 'executor',
    action: 'change'
  },
  // Право назначать исполнителя в статусе заявки "ТТ"
  changeTtTypeExecutor: {
    subject: 'ttTypeExecutor',
    action: 'change'
  },
  // Право переводить заявку в статус "Новая"
  changeToNewStatus: {
    subject: 'newStatus',
    action: 'changeTo'
  },
  // Право переводить заявку в статус "Выполнена"
  changeToDoneStatus: {
    subject: 'doneStatus',
    action: 'changeTo'
  },
  // Право переводить заявку в статус "Отклонена"
  changeToRejectedStatus: {
    subject: 'rejectedStatus',
    action: 'changeTo'
  },
  // Право редактировать комментарий в заявки и при переводе в статусы "Выполнена" и "Отклонена"
  editComment: {
    subject: 'comment',
    action: 'edit'
  },
  // Право редактировать получателя
  editReciever: {
    subject: 'reciever',
    action: 'edit'
  },
  // Право проводить проверку получателя
  checkReciever: {
    subject: 'reciever',
    action: 'check'
  },
  // Право при любых прочих условиях проводить назначение исполнителя
  alwaysAssignApplication: {
    subject: 'application',
    action: 'alwaysAssign'
  },
  // Правое редактировать округ проживания получателя
  editResidenceDistrict: {
    subject: 'residenceDistrict',
    action: 'edit'
  },
  // Правое редактировать район проживания получателя
  editResidenceArea: {
    subject: 'residenceArea',
    action: 'edit'
  },
  // Право редактировать получателя в статусе "Направлена в ЦСО"
  editOnDirectedToCsoReciever: {
    subject: 'reciever',
    action: 'editOnDirectedToCso'
  },
  // Право редактировать комментарий в статусе "Направлена в ЦСО"
  editOnDirectedToCsoComment: {
    subject: 'comment',
    action: 'editOnDirectedToCso'
  },
  // Право читать заявление после маршрутизации
  readAfterRoutingApplication: {
    subject: 'application',
    action: 'readAfterRouting'
  },
  // Право читать заявления в статусе "Черновик"
  readDraftApplications: {
    subject: 'draftApplications',
    action: 'read'
  },
  // Право видеть столбец "Район" в списке заявок
  readAreaInRegistry: {
    subject: 'areaInRegistry',
    action: 'read'
  },
  // Право видеть столбец "Заявителя" в списке заявок
  readDeclarerInRegistry: {
    subject: 'declarerInRegistry',
    action: 'read'
  },
  // Право видеть столбец "Адрес получателя" в списке заявок
  readResidenceAddressInRegistry: {
    subject: 'residenceAddressInRegistry',
    action: 'read'
  },
  // Право видеть столбец "Телефон получателя" в списке заявок
  readRecieverPhoneInRegistry: {
    subject: 'recieverPhoneInRegistry',
    action: 'read'
  },
  // Право видеть столбец "Дата исполнения" в списке заявок
  readExecDateInRegistry: {
    subject: 'execDateInRegistry',
    action: 'read'
  },
  // Право видеть столбец "Исполнителя" в списке заявок
  readApplicationExecutorInRegistry: {
    subject: 'applicationExecutorInRegistry',
    action: 'read'
  },
  // Право читать заявления только с признаком "Есть в реестре"
  readOnlyIsCarantinApplications: {
    subject: 'isCarantinApplications',
    action: 'readOnly'
  },
  // Право редактировать получателя типа ОСО
  editOsoTypeReciever: {
    subject: 'osoTypeReciever',
    action: 'edit'
  },
  // Право редактировать получателя с признаком "Есть в реестре"
  editOnCarantinReciever: {
    subject: 'onCarantinReciever',
    action: 'edit'
  },
  // Право редактировать комментарии типа ОСО
  editOsoTypeComment: {
    subject: 'osoTypeComment',
    action: 'edit'
  },
  // Право редактировать комментарии с признаком "Есть в реестре"
  editOnCarantinComment: {
    subject: 'onCarantinComment',
    action: 'edit'
  },
  // Право загружать файл с карантинным реестром
  uploadCarantinRegistryFile: {
    subject: 'carantinRegistryFile',
    action: 'upload'
  },
  // Право редактировать поле "Дата исполнения"
  editExecDate: {
    subject: 'execDate',
    action: 'edit'
  }
}
