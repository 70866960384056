/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.applications.findOso

export default async (areaId) => {
  if (isNaN(areaId) || areaId === null || !areaId) return

  try {
    const { data: { content } } = await axios({
      method: 'GET',
      url,
      params: {
        areaId,
        sort: 'id',
        size: 100
      }
    })

    return content
  } catch (error) {
    throw error
  }
}
