<template lang="pug">
  box-card
    template(slot="header") Краткое описание ситуации/проблемы
    template(slot="content")
      el-form.custom-form.situation-description-form(disabled)
        el-row
          el-col
            el-form-item(label="" style="margin-bottom: 0")
              el-input(
                id="comment-input"
                v-model="application.situationDescription"
                type="textarea"
                maxlength="2000"
                show-word-limit
              )
</template>

<script>
import BoxCard from '@/components/BoxCard.vue'

export default {
  name: 'RequestSituationDescription',
  components: {
    BoxCard
  },
  props: {
    application: {
      type: Object,
      default: () => {}
    }
  }
}

</script>

<style lang="sass">
  .custom-form.situation-description-form
    .el-textarea__inner
      height: 150px

</style>
