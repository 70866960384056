import http from '@/api'
import endpoints from '@/api/endpoints'

const url = endpoints.fias.dictionary

const fetchAddress = async (mask, onlyMoscow) => {
  try {
    const params = {
      size: 50,
      mask: mask,
      onlyMoscow
    }

    const { data: { content } } = await http({
      method: 'GET',
      url,
      params
    })

    return content
  } catch (error) {
    return error
  }
}

export default fetchAddress
