import axios from 'axios'
import endpoints from '@/api/endpoints'
import storageConfig from '@/config/storageConfig'

const applicationStatusesStorageName = 'application-statuses-options'
const url = endpoints.references.applicationStatuses.find

const fetchApplicationStatuses = async () => {
  try {
    const storedApplicationStatusesOptions = JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${applicationStatusesStorageName}`))

    if (storedApplicationStatusesOptions && storedApplicationStatusesOptions.length) return storedApplicationStatusesOptions

    const { data: { content } } = await axios({
      method: 'GET',
      url
    })

    sessionStorage.setItem(`${storageConfig.storagePrefix}${applicationStatusesStorageName}`, JSON.stringify(content))
    return content
  } catch (error) {
    return error
  }
}

export default fetchApplicationStatuses
