/* eslint-disable key-spacing */
<template lang="pug">
  el-popover.registry-excel-popover(
    ref="popover"
    placement="bottom-end"
    width="310"
    @show='fetchUnloadParamsInfo'
    @hide="resetState"
  )
    el-date-picker.registry-excel-popover-datepicker(
      v-model="date"
      type="datetimerange"
      :picker-options="datePickerOptions"
      format="dd.MM.yyyy HH:mm"
      range-separator="-"
      :default-time="['00:00:00', '23:59:59']"
      value-format='dd.MM.yyyy HH:mm:ss'
      start-placeholder="Начальная дата"
      end-placeholder="Конечная дата"
      popper-class="registry-excel-datepicker"
    )
    div.registry-excel-popover-buttons
      el-button(
        id="registry-excel-send-button"
        @click="downloadFileHandler"
        :loading='fileDownloadLoading'
      ) Выгрузить xlsx файл
    el-button.custom-button(
      id="excel-download-button"
      slot="reference"
      type="success"
      title="Выгрузить заявки в excel"
    )
      font-awesome-icon.icon(icon="file-excel")
      span Выгрузить

</template>
<script>
import downloadExcelFile from '@/api/apiCalls/file/downloadFile'
import { saveAs } from 'file-saver'

export default {
  name: 'RegistryExcel',
  data () {
    return {
      date: [],
      fixedCsvFilesLinks: [],
      fileDownloadLoading: false,
      datePickerOptions: {
        firstDayOfWeek: 1,
        disabledDate (date) {
          const today = new Date()
          const hours = today.getHours()
          const minutes = today.getMinutes()
          const seconds = today.getSeconds()

          // Чтобы сегодняшний день до 23:59:59 был доступен для выбора, вычисляем оставшееся до 24 часов количество милисекунд
          const timeInMs = hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000 + 1000
          const dayInMs = 24 * 60 * 60 * 1000

          return date.getTime() > (today.getTime() + (dayInMs - timeInMs))
        },
        shortcuts: [
          {
            text: 'За час',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'За сутки',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },

  created () {
    // let currentHour = new Date().getHours()
    // const startDate = new Date(new Date(new Date().setHours(--currentHour)).setSeconds(0)).toLocaleString().split(', ').join(' ')
    // const endDate = new Date(new Date().setSeconds(59)).toLocaleString().split(', ').join(' ')
    // this.date = [startDate, endDate]
  },
  methods: {
    async downloadFileHandler () {
      if (!this.date || this.date.length < 2) {
        return this.showMessage({ type: 'warning', showClose: false, message: 'Выберите дату' })
      }

      try {
        this.fileDownloadLoading = true
        await this.downloadExcelFile()
        this.fileDownloadLoading = false
        this.resetFileDownloadOptions()
      } catch (error) {
        const message = error.message || 'При выгрузке файла призошла ошибка'
        this.showMessage({
          type: 'error',
          showClose: false,
          message
        })
      }
    },

    async downloadExcelFile () {
      try {
        const { data, headers } = await downloadExcelFile({ dateRangeArray: this.date }, this.selectedColumnsXLSX)
        if (data && headers) {
          const resultStringName = headers['content-disposition'].substring(headers['content-disposition'].indexOf('=')).replace('=', '')
          saveAs(data, resultStringName)
        }
      } catch (error) {
        const message = error.message || ''
        this.showMessage({ type: 'error', showClose: false, message })
      }
    },

    resetFileDownloadOptions () {
      this.resetDateRange()
      this.$refs.popover.doClose()
    },

    resetDateRange () {
      let day = new Date().getDate()
      let month = new Date().getMonth() + 1
      let minutes = new Date().getMinutes()
      let hours = new Date().getHours()
      month = month > 9 ? month : `0${month}`
      day = day > 9 ? day : `0${day}`
      minutes = minutes > 9 ? minutes : `0${minutes}`
      hours = hours > 9 ? hours : `0${hours}`
      const beginHour = hours - 1 > 9 ? hours - 1 : `0${hours - 1}`
      const year = new Date().getFullYear()
      const beginDate = `${day}.${month}.${year} ${beginHour}:${minutes}:00`
      const endDate = `${day}.${month}.${year} ${hours}:${minutes}:59`
      this.date = [beginDate, endDate]
    },

    resetState () {
      this.checkUnloadParamsInfo()
    },

    showMessage ({ type = '', showClose = true, message, dangerouslyUseHTMLString = false, duration = 2000 }) {
      this.$message({
        type,
        showClose,
        message,
        dangerouslyUseHTMLString,
        duration
      })
    }
  }
}
</script>
<style lang="sass">
.registry-excel-popover
  button
    margin-left: 10px

  &-buttons
    display: flex
    flex-wrap: wrap

    button.el-button
      margin-left: 0
      flex-basis: 100%
      margin-bottom: 5px

      &:last-child
        margin-bottom: 0

div.registry-excel-popover-datepicker.el-date-editor
  // width: 362px
  width: 100%
  margin-bottom: 5px

  .el-range-separator
    word-break: normal

.registry-excel-datepicker
  .el-picker-panel__footer
    .el-button.el-picker-panel__link-btn
      font-size: 16px

    .el-button.el-picker-panel__link-btn:last-child
      color: #fff
      position: relative
      width: 178px

      &::after
        content: "Подтвердить дату"
        position: absolute
        left: 15px
        font-weight: bold
        color: #67C23A

</style>
