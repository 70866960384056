import axios from 'axios'
import endpoints from '@/api/endpoints'
import storageConfig from '@/config/storageConfig'

const refAreasStorageName = 'ref-areas'
const url = endpoints.references.dictionaryAreas.find

const fetchRefAreas = async (params = { size: 300 }) => {
  try {
    const refAreas = JSON.parse(sessionStorage.getItem(`${storageConfig.storagePrefix}${refAreasStorageName}`))

    if (refAreas && refAreas.length) return refAreas

    const { data: { content } } = await axios({
      method: 'GET',
      url,
      params
    })

    sessionStorage.setItem(`${storageConfig.storagePrefix}${refAreasStorageName}`, JSON.stringify(content))
    return content
  } catch (error) {
    return error
  }
}

export default fetchRefAreas
