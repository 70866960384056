import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.systemParams.find

const fetchReqistryData = async (searchOptions = []) => {
  const params = {}
  const rsqlOptions = []

  Object.keys(searchOptions).forEach((key) => {
    if (searchOptions[key] && searchOptions[key].toString().length) {
      searchOptions.push(`${key}==${searchOptions[key]}`)
    }
  })

  if (rsqlOptions.length) params.rsql = rsqlOptions.join(';')

  params.size = 1000

  return await axios({
    method: 'GET',
    url,
    params
  })
}

export default fetchReqistryData
