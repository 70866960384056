import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.carantines.isCarantine

const checkCitizenInCarantine = async (citizenData) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await axios({
      method: 'POST',
      url,
      data: citizenData
    })

    return data
  } catch (error) {
    throw error
  }
}

export default checkCitizenInCarantine
