import axios from 'axios'

const downloadApplicationFile = async ({ url, auth }) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      auth
    })

    return response
  } catch (error) {
    throw error
  }
}

export default downloadApplicationFile
