import userRolesConstants from '@/config/userRoles/userRolesConstants'
import rules from './lowLevelRules'

// Правила для роли задаются в формате
// [названиеРоли]: { - это название роли согласно справочнику ролей (контроллер /roles/find)
//   допуск: [ - это "может" или "не может" (can or cannot) совершать действие. Всегда массив объектов
//    ссылка на объект низкоуровневой роли - это ссылка на объект из файла lowLeverlRules.js
//   ]
// }

const userRules = {
  [userRolesConstants.ADMIN]: {
    can: [
      rules.createApplication,
      rules.editApplication,
      rules.changeStatus,
      rules.downloadExcel
    ]
  },
  [userRolesConstants.SC]: {
    can: [
      rules.createApplication,
      rules.editApplication,
      rules.changeStatus,
      rules.downloadExcel
    ]
  },
  [userRolesConstants.SMSS]: {
    can: [
      rules.editApplication,
      rules.changeStatus
    ]
  },
  [userRolesConstants.CSA]: {
    can: [
      rules.editApplication,
      rules.changeStatus
    ]
  },
  [userRolesConstants.USZN]: {
    can: [
      rules.editApplication,
      rules.changeStatus
    ]
  },
  [userRolesConstants.MSPPN]: {
    can: [
      rules.editApplication,
      rules.changeStatus
    ]
  },
  [userRolesConstants.DTSZN_REHABILITATION]: {
    can: [
      rules.editApplication,
      rules.changeStatus
    ]
  },
  [userRolesConstants.DTSZN]: {
    can: [
      rules.editApplication,
      rules.changeStatus
    ]
  }

}

// const userRules = {
//   [userRolesConstants.OPERATOR]: {
//     can: [
//       rules.createApplication,
//       rules.editOnDirectedToCsoComment,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ],
//     cannot: [rules.downloadExcel]
//   },
//   [userRolesConstants.CONTROllER]: {
//     can: [
//       rules.readOnlyDraftApplications,
//       rules.changeToNewStatus,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editOnDirectedToCsoComment,
//       rules.readDraftApplications,
//       rules.editOsoTypeReciever,
//       rules.editOnCarantinReciever,
//       rules.editOsoTypeComment,
//       rules.editOnCarantinComment,
//       rules.editOsoTypeReciever,
//       rules.editOnCarantinReciever,
//       rules.editOsoTypeComment,
//       rules.editOnCarantinComment,
//       rules.readDeclarerInRegistry
//     ],
//     cannot: [rules.downloadExcel]
//   },
//   [userRolesConstants.COUNTYDISPATCHER]: {
//     can: [
//       rules.downloadExcel,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readAfterRoutingApplication,
//       rules.readAreaInRegistry,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.AREAMANAGER]: {
//     can: [
//       rules.downloadExcel,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.alwaysAssignApplication,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.changeExecutor,
//       rules.editOnDirectedToCsoComment,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.OSO]: {
//     can: [
//       rules.createApplication,
//       rules.downloadExcel,
//       rules.returnToArea,
//       rules.changeToDoneStatus,
//       rules.changeToRejectedStatus,
//       rules.editComment,
//       rules.checkReciever,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.VOLUNTEER_DISTRICT]: {
//     can: [
//       rules.downloadExcel,
//       rules.returnToArea,
//       rules.changeToDoneStatus,
//       rules.changeToRejectedStatus,
//       rules.editComment,
//       rules.checkReciever,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.VOLUNTEER_CONTROLLER]: {
//     can: [
//       rules.downloadExcel,
//       rules.returnToArea,
//       rules.changeToDoneStatus,
//       rules.changeToRejectedStatus,
//       rules.editComment,
//       rules.checkReciever,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.DTSZN]: {
//     can: [
//       rules.createApplication,
//       rules.downloadExcel,
//       rules.downloadCsv,
//       rules.changeExecutor,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editOsoTypeReciever,
//       rules.editOnCarantinReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readAfterRoutingApplication,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.DJKH]: {
//     can: [
//       rules.downloadExcel,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readDraftApplications,
//       rules.editExecDate,
//       rules.changeTtTypeExecutor,
//       rules.readResidenceAddressInRegistry,
//       rules.readRecieverPhoneInRegistry,
//       rules.readExecDateInRegistry,
//       rules.readApplicationExecutorInRegistry
//     ],
//     cannot: [
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.OSZN]: {
//     can: [
//       rules.downloadExcel,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.DZM]: {
//     can: [
//       rules.downloadExcel,
//       rules.editReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.SBERBANK]: {
//     can: [
//       rules.downloadExcel,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.DZMDISPATCHER]: {
//     can: [
//       rules.editReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.readAfterRoutingApplication,
//       rules.downloadExcel,
//       rules.readAreaInRegistry,
//       rules.readDraftApplications,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.INCREASEDSECURITY]: {
//     can: [
//       rules.downloadExcel,
//       rules.editReciever,
//       rules.editComment,
//       rules.readOnlyIsCarantinApplications,
//       rules.editOsoTypeReciever,
//       rules.editOnCarantinReciever,
//       rules.editOsoTypeComment,
//       rules.editOnCarantinComment,
//       rules.readDeclarerInRegistry
//     ]
//   },
//   [userRolesConstants.ADMIN_CC]: {
//     can: [
//       rules.createApplication,
//       rules.downloadExcel,
//       rules.downloadCsv,
//       rules.changeExecutor,
//       rules.editReciever,
//       rules.editOnDirectedToCsoReciever,
//       rules.editOsoTypeReciever,
//       rules.editOnCarantinReciever,
//       rules.editResidenceDistrict,
//       rules.editResidenceArea,
//       rules.editOnDirectedToCsoComment,
//       rules.readAfterRoutingApplication,
//       rules.readDraftApplications,
//       rules.uploadCarantinRegistryFile,
//       rules.readDeclarerInRegistry
//     ]
//   }
// }

export default userRules
