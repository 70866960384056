<template lang="pug">
  div
    div(v-if='message.body.length' style="padding: 40px")
      h1 {{ message.title }}
      h3 {{ message.body }}
      el-button(
        v-if="isBackToAppButtonVisible"
        type="primary"
        @click="routeToApp"
      ) Вернуться в приложение
    div(v-else-if='loadingProcess' style="padding: 40px")
      h1 Идёт загрузка файла, пожалуйста подождите.

</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Files',
  data () {
    return {
      isBackToAppButtonVisible: false,
      loadingProcess: false,
      message: {
        title: '',
        body: ''
      }
    }
  },
  computed: {
    ...mapState('systemParams', {
      systemParams: state => state.systemParams
    }),

    ...mapState('auth', {
      userAbility: state => state.userAbility
    })
  },
  async created () {
    await this.fetchUsersRoles()
    await this.setUserAbility()
    this.downloadFileFromLink()
  },
  methods: {
    ...mapActions('systemParams', {
      fetchSystemParams: 'fetchSystemParams'
    }),

    ...mapActions('auth', {
      fetchUsersRoles: 'fetchUsersRoles',
      setUserAbility: 'setUserAbility'
    }),

    ...mapActions('csvQueues', {
      downloadCsvFile: 'downloadCsvFile'
    }),

    async downloadFileFromLink () {
      const link = this.$route.params.pathMatch
      if (!link.length) {
        this.showMessage({
          title: 'При скачивании файла возникла ошибка.',
          body: 'Укажите ссылку на скачиваемый файл.'
        })
        this.isBackToAppButtonVisible = true
        return
      }

      if (!this.userAbility.can('download', 'csv')) {
        this.showMessage({
          title: 'При скачивании файла возникла ошибка.',
          body: 'Доступ к скачиванию данного файла ограничен.'
        })

        // this.$router.replace({ name: 'Registry' })
        return
      }

      try {
        this.loadingProcess = true
        if (!this.systemParams.length) await this.fetchSystemParams()
        await this.downloadCsvFile(link)
        this.showMessage({
          title: 'Файл успешно скачан.',
          body: 'Данное окно можно закрыть.'
        })

        this.loadingProcess = false
      } catch (error) {
        if (error.message) {
          this.showMessage({
            title: error.status,
            body: error.message
          })
        }
      }
    },

    routeToApp () {
      this.$router.replace({ name: 'Registry' })
    },

    showMessage ({ title, body }) {
      this.message.title = title
      this.message.body = body
    }
  }
}
</script>
