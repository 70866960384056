import csvQueue from '@/api/apiCalls/file/csvQueue'
import createCsvQueue from '@/api/apiCalls/file/createCsvQueue'
import downloadCsvFile from '@/api/apiCalls/file/downloadCsvFile'
import systemParamsConstants from '@/config/systemParams/systemParamsConstants'
import { saveAs } from 'file-saver'

const state = {
  activeQueueParams: {
    dateParam: JSON.parse(sessionStorage.getItem('activeCsvQueueDateParam')) || {},
    columnsParam: JSON.parse(sessionStorage.getItem('activeCsvQueueColumnsParam')) || []
  },
  isCsvFirstQueue: sessionStorage.getItem('isCsvFirstQueue') || true,
  csvFileLink: sessionStorage.getItem('csvFileLink') || '',
  queueLiveDuration: 1000 * 60 * 60 * 2, // 2 hours
  fileLiveDuration: 1000 * 60 * 5, // 5 minutes,
  // queueLiveDuration: 1000 * 10, // 10 seconds,
  // fileLiveDuration: 1000 * 5 // 5 seconds,
  isQueuePaused: false,
  queueTimeout: 1000 * 5 // 5 seconds

}

const mutations = {
  setActiveQueueParams (state, { date }, columns) {
    const timeStamp = Date.now()
    state.activeQueueParams.dateParam.date = date
    state.activeQueueParams.dateParam.timeStamp = timeStamp
    state.activeQueueParams.columnsParam = columns
    sessionStorage.setItem('activeCsvQueueDateParam', JSON.stringify({ date, timeStamp }))
    sessionStorage.setItem('activeCsvQueueColumnsParam', JSON.stringify(columns))
  },

  setCsvFileLink (state, value) {
    // console.group('Set csv file link')
    state.csvFileLink = value
    // console.log('link', state.csvFileLink)
    sessionStorage.setItem('csvFileLink', value)
    // console.groupEnd()
  },

  resetActiveQueueParams (state) {
    state.activeQueueParams.dateParam = {}
    state.activeQueueParams.columnsParam = []
    sessionStorage.removeItem('activeCsvQueueDateParam')
    sessionStorage.removeItem('activeCsvQueueColumnsParam')
  },

  resetCsvFileLink (state) {
    state.csvFileLink = ''
    sessionStorage.removeItem('csvFileLink')
  },

  setCsvFirstQueue (state, value) {
    state.isCsvFirstQueue = value
    sessionStorage.setItem('isCsvFirstQueue', value)
  },

  setQueuePause (state, value) {
    state.isQueuePaused = value
  }
}

const getters = {
  isCsvQueueActive (state) {
    return !!state.activeQueueParams.dateParam.date
  }
}

const actions = {
  async fetchQueuedCsvFileLink ({ state, commit }) {
    if (state.isCsvFirstQueue === null) commit('setCsvFirstQueue', true)
    // eslint-disable-next-line no-useless-catch
    try {
      const { link } = await csvQueue.createConnection({
        date: state.activeQueueParams.dateParam.date,
        isCsvFirstQueue: state.isCsvFirstQueue
      })
      // console.group('Fetch csv link')
      // console.log(link)
      // console.groupEnd()
      // const { link, message } = {
      //   'http://cc-dev.c-i-p.ru/downloads/202006051727_ADMIN_from_202006051627_to_202006051727_e25b4c9edbb520a22245eec8f1b31f85_3min.zip',
      //   message: ''
      // }
      // if (message.length && !link) {
      //   const error = new Error()
      //   error.response = { data: { message: message } }
      //   throw error
      // }

      commit('setCsvFileLink', link)
      commit('setQueuePause', true)
      commit('setCsvFirstQueue', false)
      setTimeout(() => {
        commit('setQueuePause', false)
      }, state.queueTimeout)
    } catch (error) {
      throw error
    }
  },

  async createCsvQueue ({ dispatch, commit }, { dateRangeArray, columns }) {
    // console.group('Create csv queue')
    // console.groupEnd()
    // eslint-disable-next-line no-useless-catch
    try {
      createCsvQueue({ dateRangeArray }, columns)
      // commit('setActiveQueueParams', { date: dateRangeArray })
      // Поднимаем прослушку SSE до запроса на формирование файла заранее, так как ссылка на файл успевает сфорироваться до
      // первого сообщения по SSE
      // dispatch('fetchQueuedCsvFileLink')
      // setTimeout(() => {
      // }, 5000)
    } catch (error) {
      // console.log(error)
      throw error
    }
  },

  checkCsvQueue ({ state }) {
    // console.group('Check CSV queue')

    const dateParam = state.activeQueueParams.dateParam
    const now = Date.now()
    const isQueueLiveTimeExpired = (now - dateParam.timeStamp) > state.queueLiveDuration

    // console.log('date', dateParam.date)
    // console.log('timeStamp', dateParam.timeStamp)
    // console.log('queue live time', state.queueLiveDuration / 1000 / 60, 'minutes')
    // console.log('queue actual live time', (now - dateParam.timeStamp) / 1000 / 60, 'minutes')

    // console.log('Is queue live time expired', isQueueLiveTimeExpired)
    // console.groupEnd()
    return isQueueLiveTimeExpired
  },

  async closeCsvQueueConnection ({ state, commit }, reset = true) {
    // console.group('Close csv queue')
    await csvQueue.closeConnection()
    // console.log('Csv queue state', csvQueue)
    // console.log('Csv queue params', state.activeQueueParams)
    // console.groupEnd()
    if (!reset) return
    commit('resetActiveQueueParams')
    commit('resetCsvFileLink')
    commit('setQueuePause', true)
    setTimeout(() => {
      commit('setQueuePause', false)
    }, state.queueTimeout)
  },

  async downloadCsvFile ({ rootState: { systemParams: { systemParams } } }, link) {
    const url = `/${link.split('/')[3]}/${link.split('/')[4]}`
    const fileName = link.split('/')[4]
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await downloadCsvFile({
        url,
        auth: {
          username: systemParams[systemParamsConstants.webdavLogin].paramValue,
          password: systemParams[systemParamsConstants.webdavPassword].paramValue
        }
      })
      if (data) {
        saveAs(data, fileName)
      }
    } catch (error) {
      throw error
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
