/* eslint-disable no-useless-catch */
import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.citizens.default

export default async (citizenData) => {
  return axios({
    method: 'PUT',
    url,
    data: citizenData
  })
}
