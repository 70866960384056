/* eslint-disable quote-props */
import axios from 'axios'

const downloadCsvFile = async ({ url, auth }) => {
  try {
    const response = await axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      auth
    })

    return response
  } catch (error) {
    const errorObject = await getJsonFromBlob({ error: error.response.data, status: error.response.status })
    throw errorObject
  }
}

function getJsonFromBlob ({ error, status }) {
  const fr = new FileReader()
  return new Promise((resolve, reject) => {
    fr.onerror = () => {
      fr.abort()
      reject(new Error())
    }

    fr.onload = () => {
      if (fr.result && !typeof fr.resutl === 'object') {
        resolve(JSON.parse(fr.result))
      } else {
        const error = new Error('Csv file download error')
        error.status = status

        if (status === 401) {
          error.message = 'Доступ к файлу запрещен'
        } else if (status === 404) {
          error.message = 'Ссылка на запрашиваемый файл устарела'
        } else {
          error.message = fr.result
        }
        resolve(error)
      }
    }
    fr.readAsText(error)
  })
}

export default downloadCsvFile
