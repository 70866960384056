import axios from 'axios'
import endpoints from '@/api/endpoints'

const url = endpoints.file.uploadFileWebDav

export default (file) => {
  const formData = new FormData()
  formData.append('file', file)

  return axios({
    method: 'POST',
    url,
    data: formData
  })
}
