const endpoints = {
  references: {
    applicationStatuses: {
      find: '/cc-rest/api/v1/application-statuses/find',
      byUserRole: '/cc-rest/api/v1/application-statuses/get-by-user-role'
    },
    applicationTypes: {
      find: '/cc-rest/api/v1/application-types/find'
    },
    dictionaryAreas: {
      find: '/cc-rest/api/v1/dictionary-areas/find'
    },
    dictionaryDistricts: {
      find: '/cc-rest/api/v1/dictionary-districts/find'
    },
    questions: {
      find: '/cc-rest/api/v1/questions/find'
    },
    serviceTypes: {
      find: '/cc-rest/api/v1/service-types/find'
    }
  },
  applications: {
    default: '/cc-rest/api/v1/applications/',
    list: '/cc-rest/api/v1/applications/find',
    byId: '/cc-rest/api/v1/applications/{id}',
    createWithServices: '/cc-rest/api/v1/applications/extra/',
    nextStatuses: '/cc-rest/api/v1/applications/{id}/next-status-list',
    changeStatus: '/cc-rest/api/v1/applications/change-status',
    changeExecutor: '/cc-rest/api/v1/applications/change-executor',
    checkAndChangeRouting: '/cc-rest/api/v1/applications/check-and-change-routing',
    findOsoList: '/cc-rest/api/v1/users/find-oso-list',
    findOso: '/cc-rest/api/v1/users/find-oso',
    findDjkh: '/cc-rest/api/v1/users/find-djkh-list',
    history: '/cc-rest/api/v1/audits/custom-history',
    notConfirmedTypes: '/cc-rest/api/v1/not-confirmed-types/find',
    medicalOrganizations: '/cc-rest/api/v1/medical-organizations/find'
  },
  citizens: {
    default: '/cc-rest/api/v1/citizens/',
    byId: '/cc-rest/api/v1/citizens/{id}'
  },
  service: {
    find: '/cc-rest/api/v1/service-types/find',
    create: '/cc-rest/api/v1/services/',
    findByApplicationId: '/cc-rest/api/v1/services/extra/find-by-application-id/{id}'
  },
  questions: {
    find: '/cc-rest/api/v1/questions/find'
  },
  users: {
    default: '/cc-rest/api/v1/users/',
    byId: '/cc-rest/api/v1/users/{id}',
    profileFull: '/cc-rest/api/v1/users/profile-full',
    profileShort: '/cc-rest/api/v1/users/profile-full',
    getUnloadParamsInfo: '/cc-rest/api/v1/users/get-user-unload-params-info',
    saveUnloadParamsInfo: '/cc-rest/api/v1/users/save-user-unload-params-info'
  },
  file: {
    downloadExcel: '/cc-rest/api/v1/applications/receiver-xlsx',
    generateCsv: '/cc-rest/api/v1/applications/generate-csv',
    uploadFile: '/cc-rest/api/v1/files/upload-file',
    uploadFileWebDav: '/cc-rest/api/v1/files/upload-file-webdav',
    uploadApplicationFile: '/cc-rest/api/v1/files/upload-application-file',
    importOmsInstitutions: '/cc-rest/api/operation/importOmsInstitutions',
    fixedUploadParams: {
      findAccessible: '/cc-rest/api/v1/fixed-unload-params/find-accessible'
    },
    csvQueue: {
      makeQueue: '/cc-rest/api/v1/applications/queue',
      listenQueue: '/cc-rest/api/v1/consumers/queue'
    },
    availableColumns: '/cc-rest/api/v1/applications/available-columns'
  },
  carantines: {
    isCarantine: '/cc-rest/api/v1/carantines/is_carantine'
  },
  tests: {
    default: '/cc-rest/api/v1/tests/'
  },
  roles: {
    find: '/cc-rest/api/v1/roles/find'
  },
  auth: {
    logout: '/cc-rest/api/v1/auth/logout'
  },
  fias: {
    dictionary: '/cc-rest/api/v1/dictionary-fias/address-search'
  },
  systemParams: {
    find: '/cc-rest/api/v1/system-params/find'
  },
  omsInstitution: {
    find: '/cc-rest/api/operation/getOmsInstitution'
  }
}

export default endpoints
